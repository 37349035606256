import React, { Fragment, useRef } from "react";
import Store from "eas-react-store";
import { Tools } from "../../tools/tools";
import ReactToPrint from "react-to-print";
import { PrintLayoutSchedule } from "../print/PrintLayoutSchedule";
import moment from "moment";

class PrintDialog extends React.Component {
    categories = [];
    options = [
        // Should the names of the volunteers be printed or not.
        { id: "opt-1", desc: "Skriv ut namn på bokade volontärer" },
        // Should the notes about the tasks be printed or not.
        { id: "opt-2", desc: "Skriv ut anteckningar för uppgiften" },
    ];

    constructor(props) {
        super(props);
        this.categories = this.props.store.Category.data;
        var checkboxes = {};

        // Initialise default state for the checkboxes.
        this.categories.forEach((cat) => {
            checkboxes[cat.id] = true;
        });
        checkboxes["opt-1"] = true;
        checkboxes["opt-2"] = false;

        // The first day of the selected week, type Date.
        this.firstDayOfWeek = this.props.store.Schedule.currentDay;
        this.selectedWeek = Tools.getWeekNumberForDate(this.firstDayOfWeek);

        this.data = {
            organisation: this.props.store.User.data.organisation,
            categories: this.categories,
            weekTasks: this.props.weekTasks,
            weekDetails: {},
            groupName: "",
        };

        this.state = {
            checkboxes: checkboxes,
        };
    }

    /**
     * Retreives the group associated to a given week.
     * @param {Int} weekNumber The week for which we want to know the associated group
     * @param {Int} year The year of the provided week
     */
    findGroupOfWeek(weekNumber, year) {
        let groupName = "";
        if (this.props.store.Group.data != null) {
            this.props.store.Group.data.forEach((group, index) => {
                group.weeks.forEach((week) => {
                    if (week.year === year && week.week === weekNumber) {
                        groupName = group.name;
                    }
                });
            });
        }
        this.data.groupName = groupName;
        return groupName;
    }

    // MARK: - Event Handlers

    /**
     * Handles the click on one of the checkboxes of the component.
     * @param {Event} e The click event
     */
    handleCheckbox(e) {
        var currentState = this.state.checkboxes[e.target.name];

        this.setState({
            ...this.state,
            checkboxes: {
                ...this.state.checkboxes,
                [e.target.name]: !currentState,
            },
        });
    }

    // MARK: - Rendering Methods

    /**
     * Renders the week information of the selected week at the top
     * of the print slide over dialog: select week, first day and last day.
     * Ex: "V41 • 5 oktober -> 11 oktober"
     */
    renderWeekDetails() {
        let firstDate = moment(this.firstDayOfWeek);
        let dayOfFirstDate = firstDate.format("D");
        let monthOfFirstDate = firstDate.format("MMMM");

        let secondDate = moment(this.firstDayOfWeek).add(6, "days");
        let dayOfSecondDate = secondDate.format("D");
        let monthOfSecondDate = secondDate.format("MMMM");

        let renderString =
            "V" +
            this.selectedWeek +
            " • " +
            dayOfFirstDate +
            " " +
            monthOfFirstDate +
            " -> " +
            dayOfSecondDate +
            " " +
            monthOfSecondDate;

        this.data.weekDetails = renderString;

        return renderString;
    }

    render() {
        return (
            <div
                className={`slide-overview ${this.props.isActive ? "active" : ""
                    }`}>
                <section>
                    <h1>Skriv ut schemat</h1>

                    {/* This should be the group affected to the selected week */}
                    <h3 className="text-vol-blue mt-3">
                        {this.findGroupOfWeek(this.selectedWeek, 2020)}
                    </h3>

                    {/* This are the details of the selected week */}
                    <span className="text-vol-blue">
                        {this.renderWeekDetails()}
                    </span>
                </section>

                <section>
                    <h3>Kategorier</h3>
                    {this.categories.map((cat) => {
                        return (
                            <label
                                key={cat.id}
                                htmlFor={cat.id}
                                className={`custom-check-wrapper mt-3 ${this.state.checkboxes[cat.id]
                                    ? "checked"
                                    : ""
                                    }`}>
                                <span className="custom-check"></span>
                                <input
                                    type="checkbox"
                                    name={cat.id}
                                    id={cat.id}
                                    onChange={(e) => this.handleCheckbox(e)}
                                />
                                <span className="form-check-label">
                                    {cat.title}
                                </span>
                            </label>
                        );
                    })}
                </section>

                <section>
                    <h3>Utskriftsval</h3>
                    {this.options.map((item) => {
                        return (
                            <label
                                key={item.id}
                                htmlFor={item.id}
                                className={`custom-check-wrapper mt-3 ${this.state.checkboxes[item.id]
                                    ? "checked"
                                    : ""
                                    }`}>
                                <span className="custom-check"></span>
                                <input
                                    type="checkbox"
                                    name={item.id}
                                    id={item.id}
                                    onChange={(e) => this.handleCheckbox(e)}
                                />
                                <span className="form-check-label">
                                    {item.desc}
                                </span>
                            </label>
                        );
                    })}
                </section>

                <div className="slide-overview-controls">
                    <button
                        className="btn btn-cancel"
                        onClick={() => this.props.onClickCancel()}>
                        Avbryt
                    </button>
                    <ReactToPrint
                        trigger={() => {
                            return (
                                <button className="btn btn-primary-inverted">
                                    Skriv ut
                                </button>
                            );
                        }}
                        content={() => this.componentRef}
                    />
                    <div style={{ display: "none" }}>
                        <PrintLayoutSchedule
                            ref={(el) => (this.componentRef = el)}
                            checkboxes={this.state.checkboxes}
                            data={this.data}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Store.connectStore(PrintDialog);
