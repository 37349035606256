import BaseController from './controller.base';
import ApiMessages from '../api/api.messages';

export default class Messages extends BaseController {
    static controllerName = 'Messages';

    constructor(updateStore, config, localStorage) {
        super(updateStore);

        this.Api = {
            messages: new ApiMessages(config.path)
        };

        if (localStorage != null) {
            if (localStorage.User != null) {
                this.token = localStorage.User.token
            }
            this.data = [];
            this.updateStore();
        } else {
            this.token = "";
        }
    }

    async createMessage(message) {
        await this.Api.messages.postMessage(message, this.token);
    }

    async sendMessageToUsers(body) {
        var res = await this.Api.messages.sendMessage(body, this.token);
        return res;
    }

}

