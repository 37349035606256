import external from '../../tools/base.external';

export default class ApiMessages extends external {

    async postMessage(data, token) {
        let message = await this.post.json(`messages`, data, token);

        return message;
    }

    async getMessages(data, token) {
        let messages = await this.get.json(`messages`, data, token);

        return messages;
    }

    async sendMessage(data, token) {
        let messages = await this.post.json(`messages/send`, data, token);

        return messages
    }
}