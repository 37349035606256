import BaseController from './controller.base';
import ApiGroups from '../api/api.groups';

export default class Group extends BaseController {
    static controllerName = 'Group';

    constructor(updateStore, config, localStorage) {
        super(updateStore);

        this.Api = {
            groups: new ApiGroups(config.path)
        };

        if (localStorage != null) {
            if (localStorage.Group != null) {
                this.data = localStorage.Group
            }

            if (localStorage.User != null) {
                this.token = localStorage.User.token
            }

            this.updateStore();
        } else {
            this.token = "";
        }

        this.selectedYear = (new Date()).getFullYear();
        this.changeSelectedYear = this.changeSelectedYear.bind(this);

        this.getGroups();
    }

    changeSelectedYear(year) {
        this.selectedYear = year;
        this.updateStore();
    }

    async getGroups(withLoader = true) {
        if (withLoader) this.isLoading = true;
        let groups = await this.Api.groups.getGroups(this.token);

        this.data = groups;

        if (withLoader) {
            this.isLoading = false;
            this.updateStore();
        }
    }

}

