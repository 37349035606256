import React, { Component } from 'react';
import Store from 'eas-react-store';
import moment from 'moment';
import localization from 'moment/locale/sv';
import { Tools } from '../../tools/tools';
moment.locale('sv', localization);

class WeekCard extends Component {
    renderGroups() {
        let result = [];
        let groups = [...this.props.store.Group.data];

        Tools.sortArrayByObjectKey(groups, 'id');

        groups.map((group) => {
            let item = group.name.split(" ")[1];

            if (group.id !== this.props.group.id) {
                result.push(
                    <li
                        onClick={(e) => this.props.addGroup(group.id, this.props.week, this.props.group.id, this.props.year)}
                        key={group.name}
                        className="group"
                    >
                        G{item}
                    </li>
                );
            }
        });

        if (this.props.group.id != null) {
            result.push(
                <li
                    onClick={(e) => this.props.removeGroup(this.props.group.id, this.props.week, this.props.year)}
                    key="trash"
                    className="group trash"
                >
                    <img src="/img/icon-remove-red.svg" alt="" />
                </li>
            );
        }

        return result;
    }

    render() {
        return (
            <div onClick={this.props.onClick} className="week-card">
                <div className={`week${moment().format('W') === this.props.week && (new Date()).getFullYear() === this.props.year ? " selected" : ""}`}>
                    {this.props.week}
                </div>

                <div className={`group ${this.props.group === "" ? "warning" : ""}`}>
                    {this.props.group !== "" ? `G${this.props.group.name.split(' ')[1]}` : ""}
                    {this.props.group === "" &&
                        <p>
                            <img src="/img/icon-warning-orange.svg" alt="warning" />
                        </p>
                    }
                </div>
                <ul className={`select-group ${this.props.selectGroup === this.props.index ? "" : "hidden"}`}>
                    {this.renderGroups()}
                </ul>
            </div>
        );
    }
}

export default Store.connectStore(WeekCard);