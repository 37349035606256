import React from "react";
import { PrintHeader } from "./parts/PrintHeader";
import { PrintDayCard } from "./parts/PrintDayCard";

export class PrintLayoutSchedule extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldPrintVolunteerNames: this.props.checkboxes["opt-1"] ?? false,
            shouldPrintTaskDescription: this.props.checkboxes["opt-2"] ?? false,
            checkboxes: this.props.checkboxes,
        };

        // Creates an array containing the ID of the selected categories
        this.selectedCategories = [];
        this.updateSelectedCategories();
    }

    shouldComponentUpdate(newProps) {
        return this.state.checkboxes !== newProps.checkboxes;
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({
                shouldPrintVolunteerNames:
                    this.props.checkboxes["opt-1"] ?? false,
                shouldPrintTaskDescription:
                    this.props.checkboxes["opt-2"] ?? false,
                checkboxes: this.props.checkboxes,
            });
            this.updateSelectedCategories();
        }
    }

    /**
     * Updates the array containing only the IDs of
     * the selected categories.
     */
    updateSelectedCategories() {
        this.selectedCategories = [];
        for (const [checkboxId, state] of Object.entries(
            this.props.checkboxes
        )) {
            if (state === true) {
                this.selectedCategories.push(checkboxId);
            }
        }
    }

    renderWeekTasks() {
        let returnArr = [];
        for (const [day, tasks] of Object.entries(this.props.data.weekTasks)) {
            returnArr.push(
                <PrintDayCard
                    day={day}
                    tasks={tasks}
                    showNames={this.state.shouldPrintVolunteerNames}
                    showNotes={this.state.shouldPrintTaskDescription}
                    selectedCategories={this.selectedCategories}
                />
            );
        }

        return returnArr;
    }

    render() {
        return (
            <div className="print">
                <PrintHeader
                    data={this.props.data}
                    title={this.props.data.groupName}
                    subtitle={this.props.data.weekDetails}
                    selectedCategories={this.selectedCategories}
                />
                {this.renderWeekTasks()}
            </div>
        );
    }
}
