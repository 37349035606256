import BaseController from './controller.base';
import ApiTask from '../api/api.task';
import moment from 'moment';
import { Tools } from '../../tools/tools';

export default class Task extends BaseController {
    static controllerName = 'Task';

    constructor(updateStore, config, localStorage) {
        super(updateStore);

        this.Api = {
            task: new ApiTask(config.path)
        };

        this.assignTaskToUser = this.assignTaskToUser.bind(this);
        this.deleteTaskFromUser = this.deleteTaskFromUser.bind(this);
        this.fetchTasksByWeek = this.fetchTasksByWeek.bind(this);

        if (localStorage != null) {
            if (localStorage.Task != null) {
                this.data = localStorage.Task
            }
            if (localStorage.User != null) {
                this.token = localStorage.User.token
            }
            this.updateStore();
        } else {
            this.data = {};
            this.data["tasks"] = [];
            this.data["locations"] = [];
            this.token = "";
        }

        this.fetchTasks();
        this.fetchLocations();
    }

    /**
     * Fetches all the tasks of all the groups form the API
     */
    async fetchTasks() {
        this.isLoading = true;
        let monday = Tools.getMonday(new Date());
        let tasks = await this.Api.task.getTasksByWeek(moment(monday).format('YYYY-MM-DD'));

        tasks = tasks.map((task) => {
            if (task.anonymous_user_count > 0) {
                task.accepted_by.push(...task.anonymous_list);
            }

            return task;
        });

        this.data["tasks"] = tasks;
        this.isLoading = false;
        this.updateStore();
    }

    async fetchTasksByWeek(date) {
        let tasks = await this.Api.task.getTasksByWeek(moment(date).format('YYYY-MM-DD'));

        tasks = tasks.map((task) => {
            if (task.anonymous_user_count > 0) {
                task.accepted_by.push(...task.anonymous_list);
            }

            return task;
        });

        this.data["tasks"] = tasks;
        this.updateStore();
    }

    /**
     * Fetches all the possible task locations from the API
     */
    async fetchLocations() {
        let locations = await this.Api.task.getLocations(this.token);
        this.data["locations"] = locations;
        this.updateStore();
    }

    /**
     * Sends a request to save a new location in the database
     * @param {location} location The new location to create
     */
    async createLocation(location) {
        await this.Api.task.postLocation(location, this.token);
        this.fetchLocations();
    }


    /**
     * Sends a request to deletee a location in the database
     * @param {location} location The location to delete
     */
    async removeLocation(id) {
        console.log("Test" + id);
        await this.Api.task.deleteLocation(id, this.token);
        this.fetchLocations();
    }

    /**
     * Sends a request to update a task
     * @param {Task} task The task to update matching the expected API data structure
     */
    async updateTask(task) {
        await this.Api.task.putTask(task.id, task, this.token)

        this.fetchTasksByWeek(Tools.getMonday(task.start_date));
    }

    /**
     * Sends a request to create a new task in the database
     * @param {Task} task The task to create matching the expected API data structure
     */
    async createTask(task, repeating = false) {
        await this.Api.task.postTask(task, this.token)

        if (!repeating) {
            this.fetchTasksByWeek(Tools.getMonday(task.start_date));
        }
    }

    /**
     * Creates a new task object based on the task passed as parameter and
     * sends a create request with it. 
     * Result: the task is duplicated.
     * @param {Task} task The task to duplicate
     */
    async duplicateTask(task) {
        let taskToSend = {}

        taskToSend.title = task.title;
        taskToSend.description = task.description;
        taskToSend.spots = task.available_spots + task.taken_spots;
        taskToSend.location = task.location.id;
        taskToSend.category = task.category.id
        taskToSend.start_date = task.start_date;
        taskToSend.end_date = task.end_date;
        taskToSend.owner_group = task.owner_group;

        await this.Api.task.postTask(taskToSend, this.token)
        this.fetchTasks();
    }

    /**
     * Removes a task from the database.
     * @param {Int} id The ID of the task to delete
     */
    async deleteTask(id) {
        await this.Api.task.deleteTask(id, this.token);

        let task = this.getTaskFromData(id);

        this.fetchTasksByWeek(Tools.getMonday(task.start_date));
    }

    /**
     * Removes a number of available spots in the database
     * @param {Int} nbToRemove The number of spots to remove from the task
     * @param {Task} task The task object to modify in the database
     */
    async removeAvailableSpots(nbToRemove, task) {
        let taskToSend = {};

        taskToSend.id = task.id;
        taskToSend.title = task.title;
        taskToSend.description = task.description;
        taskToSend.spots = (task.available_spots + task.taken_spots) - nbToRemove;
        taskToSend.location = task.location.id;
        taskToSend.category = task.category.id;
        taskToSend.start_date = task.start_date;
        taskToSend.end_date = task.end_date;
        taskToSend.owner_group = task.owner_group;

        this.updateTask(taskToSend);
    }

    /**
     * Checks the date of the task and how many people are involved.
     * Used to render the red warning on the schedule view.
     * @param {Task} task Task object to analyse
     */
    isDueSoonAndIsNotFilled(task) {
        // Variables for time condition
        const startDate = new Date(task.start_date);
        const now = new Date();
        const timeBeforeTask = startDate - now;
        const TWO_DAYS_IN_MILLI_SEC = 172800000;

        // Variables for taken spots conditions
        const availableSpots = task.available_spots;

        if (timeBeforeTask <= TWO_DAYS_IN_MILLI_SEC && availableSpots > 0) {
            return true;
        } else {
            return false;
        }
    }

    async assignTaskToUser(task_id, user_id) {
        let response = {};

        try {
            response = await this.Api.task.assignTaskToUser(task_id, user_id);
        } catch (error) {
            console.log(error);

            return false;
        }

        let task = this.getTaskFromData(task_id);

        this.fetchTasksByWeek(Tools.getMonday(task.start_date));

        return response.success;
    }

    async deleteTaskFromUser(task_id, user_id) {
        let response = {};

        try {
            response = await this.Api.task.deleteTaskFromUser(task_id, user_id);
        } catch (error) {
            console.log(error);

            return false;
        }

        let task = this.getTaskFromData(task_id);

        this.fetchTasksByWeek(Tools.getMonday(task.start_date));

        return response.success;
    }

    getTaskFromData(task_id) {
        return this.data.tasks.find((task) => task.id === task_id);
    }

    // This is copy paste from DOC
    // Will need update with the object change in the API
    // Use for component rendering when no data is fetched on first start
    getFakeTask() {
        return {
            "id": 1,
            "title": "Title",
            "description": "Description",
            "location": {
                "id": 1,
                "name": "En appstudio",
                "address": "Address"
            },
            "available_spots": 0,
            "taken_spots": 1,
            "category": {
                "id": 1,
                "title": "Work",
                "color": "#000"
            },
            "accepted_by": [
                {
                    "id": 1,
                    "name": "Vincent",
                    "phone": "+4670000",
                    "email": "vincent@enappstudio.se",
                    "group_id": 1
                }
            ],
            "waiting_list": [
                {
                    "id": 3,
                    "name": "Anton",
                    "phone": "+4670001",
                    "email": "anton@enappstudio.se",
                    "group_id": 1
                }
            ],
            "current_user_has_accepted": true,
            "anonymous_user_count": 3,
            "team_leaders": [
                {
                    "id": 1,
                    "name": "Vincent",
                    "phone": "+4670000",
                    "email": "vincent@enappstudio.se",
                    "group_id": 1
                }
            ],
            "owner_group": 2,
            "start_date": "2019-04-26T12:28:23.637Z",
            "end_date": "2019-04-26T12:28:23.637Z"
        };
    }
}

