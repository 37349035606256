import React from 'react';

/**
 * This component is used to render a single statistic square
 */
export class SquareStatistics extends React.Component {

    getTextColor(color) {
        return "#" + color;
    }

    getBackgroundColor(color) {
        return this.getTextColor(color) + "30";
    }

    render() {

        const divStyle = {
            backgroundColor: this.getBackgroundColor(this.props.color),
            color: this.getTextColor(this.props.color)
        }

        return (
            <div className="col-12 col-md-6 col-lg-3">
                <div className="statistics-box" style={divStyle}>
                    <div className="aspect-ratio-content">
                        <span className="number">{this.props.number}</span>
                        <span className="caption">{this.props.caption}</span>
                    </div>
                </div>
            </div>
        )
    }
}