import BaseController from './controller.base';
import ApiGroups from '../api/api.groups';
import ApiTask from '../api/api.task';
import { Tools } from '../../tools/tools';

export default class Schedule extends BaseController {
    static controllerName = 'Schedule';

    constructor(updateStore, config, localStorage) {
        super(updateStore);

        this.data = [];
        this.token = "";
        this.categories = [];

        this.currentDay = Tools.getMonday(new Date());
        this.selectedTask = null;
        this.savedEdits = null;
        this.selectedTaskGroupManager = null;
        this.isShowingDetailsOverview = false;
        this.isShowingEditingView = false;
        this.isShowingNewTaskView = false;
        this.selectedDateOnNewTaskClick = null;

        this.Api = {
            groups: new ApiGroups(config.path),
            task: new ApiTask(config.path)
        };

        if (localStorage != null) {
            if (localStorage.Task != null) {
                this.data = localStorage.Schedule;
            }

            if (localStorage.User != null) {
                this.token = localStorage.User.token;
            }

            if (localStorage.Category != null) {
                this.categories = localStorage.Category;
            }

            /**
             * Creating an array that will contain for each category id
             * a boolean to know if it is filtered out by the checkbox or not
             */
            this.categoryFilter = {};
            this.categories.forEach((elt) => {
                if (localStorage.User.connectedUser.userlevel === 'team_leader') {
                    let groups = localStorage.Group;
                    let user = localStorage.User.connectedUser;
                    let userGroup = groups.find((group) => group.id === user.group_ids[0]);

                    let userCategory = {};

                    userGroup.categories.forEach((category) => {
                        let isInThisCategory = category.team_leaders.find((team_leader) => team_leader.id === user.id);

                        if (isInThisCategory) {
                            userCategory = category;
                        }
                    });

                    this.categoryFilter[elt.id] = false;

                    if (userCategory.id === elt.id) {
                        this.categoryFilter[elt.id] = true;
                    }
                } else {
                    this.categoryFilter[elt.id] = true;
                }
            });

            this.updateStore();
        } else {
            this.fetchCategories();
            this.categoryFilter = {};
        }
    }

    async goToNextWeek() {
        this.currentDay.setDate(this.currentDay.getDate() + 7);
        this.updateStore();
    }

    async fetchCategories() {
        let categories = await this.Api.task.getCategories(this.token);
        this.categories = categories;
        this.categoryFilter = {};
        this.categories.forEach((elt) => {
            this.categoryFilter[elt.id] = true;
        });
        this.updateStore();
    }

    async goToPreviousWeek() {
        this.currentDay.setDate(this.currentDay.getDate() - 7);
        this.updateStore();
    }

    async goToToday() {
        this.currentDay = Tools.getMonday(new Date());
        this.updateStore();
    }

    async unSelectAll() {
        this.selectedTask = null;
        this.isShowingDetailsOverview = false;
        if (this.isShowingEditingView) {
            this.isShowingEditingView = false;
        }
        this.updateStore();
    }

    switchFilter(catId) {
        this.categoryFilter[catId] = !this.categoryFilter[catId];
        this.updateStore();
    }

    isShowingCategory(catId) {
        return this.categoryFilter[catId];
    }

    async activateEditMode() {
        if (this.isShowingDetailsOverview === true) {
            this.isShowingEditingView = true;
            this.updateStore();
        }
    }

    async activateCreateNewTaskView(evt) {
        const date = evt.target.value
        this.isShowingNewTaskView = true;
        this.selectedDateOnNewTaskClick = date;
        this.updateStore();
    }

    async cancelCreateNewTask() {
        this.isShowingNewTaskView = false;
        this.selectedDateOnNewTaskClick = null;
        this.updateStore();
    }

    async fetchGroupManager(groupId) {
        let groupManager = await this.Api.groups.getGroupManager(groupId, this.token);
        this.selectedTaskGroupManager = groupManager;
        this.updateStore();
    }
}