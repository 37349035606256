import React from 'react';

export default function Checkbox ({ isChecked, isMinus = false, onClick }) {
    return (
        <label
            onClick={onClick}
            className={`custom-check-wrapper ${isMinus ? 'checked is-minus' : ''} ${isChecked ? 'checked' : ''}`}
        >
            <span className="custom-check"></span>
            <input type="checkbox" name="1" />
        </label>
    );
}