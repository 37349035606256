import React, { Component } from 'react';

class Pagination extends Component {
    constructor (props) {
        super(props);

        this.updateItems = this.updateItems.bind(this);
        this.updateItems();
    }

    paginate (array, page_number) {
        --page_number;

        return array.slice(page_number * this.props.perPage, (page_number + 1) * this.props.perPage);
    }

    updateItems() {
        this.props.updateItems(this.paginate(this.props.items, this.props.currentPage));
    }

    goToPage (index) {
        if (this.props.currentPage != index) {
            this.props.updateCurrentPage(index, () => {
                this.updateItems();
            });
        }
    }

    previousPage () {
        if (this.props.currentPage > 1) {
            this.props.updateCurrentPage(this.props.currentPage - 1, () => {
                this.updateItems();
            });
        }
    }

    nextPage () {
        if (this.props.currentPage < Math.ceil((this.props.items.length / this.props.perPage))) {
            this.props.updateCurrentPage(this.props.currentPage + 1, () => {
                this.updateItems();
            });
        }
    }

    renderItem (index, label = '') {
        return (
            <li key={index + label}
                onClick={() => this.goToPage(index)}
                className={`page-item ${this.props.currentPage === index ? 'active' : ''}`}
            >
                <span className="page-link">{label != '' ? label : index}</span>
            </li>
        );
    };

    renderPagination () {
        let result = [];
        let nbOfPages = Math.ceil(this.props.items.length / this.props.perPage);

        result.push(
            <li
                key="previous"
                onClick={() => this.previousPage()}
                className={`page-item ${this.props.currentPage === 1 ? 'disabled' : ''}`}
            >
                <span className="page-link">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Tidigare</span>
                </span>
            </li>
        );

        // You are the beginning of the pagination or at the end
        if (this.props.currentPage < 5 || this.props.currentPage > nbOfPages - 4) {
            for (let index = 1; index <= nbOfPages && index < 6; index++) {
                result.push(this.renderItem(index));
            }

            if (nbOfPages > 5) {
                // Don't put '...' if the number of pages is 10 or less
                if (nbOfPages > 10) {
                    result.push(
                        this.renderItem(this.props.currentPage < 5 ? this.props.currentPage + 5 : this.props.currentPage - 5, '...')
                    );

                    for (let index = nbOfPages - 4; index <= nbOfPages; index++) {
                        result.push(this.renderItem(index));
                    }
                } else {
                    for (let index = 6; index <= nbOfPages; index++) {
                        result.push(this.renderItem(index));
                    }
                }
            }
        } else if (this.props.currentPage < nbOfPages - 3) {
            result.push(this.renderItem(1));
            result.push(this.renderItem(this.props.currentPage - 3, '...'));

            // Render the closest element of the current page
            for (let index = this.props.currentPage - 2; index <= this.props.currentPage + 2 && index <= nbOfPages; index++) {
                result.push(this.renderItem(index));
            }

            result.push(this.renderItem(this.props.currentPage + 3, '...'));
            result.push(this.renderItem(nbOfPages));
        }

        result.push(
            <li
                key="next"
                onClick={() => this.nextPage()}
                className={`page-item ${this.props.currentPage === nbOfPages ? 'disabled' : ''}`}
            >
                <span className="page-link">
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Nästa</span>
                </span>
            </li>
        );

        return result;
    }

    render () {
        return (
            <div className="pagination-container">
                <ul className="pagination">
                    {this.renderPagination()}
                </ul>
            </div>
        );
    }
}

export default Pagination;