import React, {Fragment} from 'react';
import Dropdown from 'react-dropdown';
import { SquareStatistics } from './SquareStatistics';

/**
 * This component is used to render an array of statistics square.
 * It includes the title, the dropdown to select a time scode (past or future)
 * and generate as much squares as needed to render all the data. 
 */
export class SquareStatisticsArray extends React.Component {

    renderSquares() {
        if (this.props.categories != null) {
            return this.props.categories.map((cat) => {
                return (
                    <SquareStatistics
                        key={cat.category_id}
                        color={cat.color}
                        number={cat.amount}
                        caption={cat.title}>
                    </SquareStatistics>
                )
            });
        }
    }

    renderDropdown() {
        const options = [
            {value: 0, label: "Tidigare"},
            {value: 1, label: "Planerade"}
        ];
        const defaultOption = options[this.props.dropdownSelected];

        return(
            <Dropdown
                options={options}
                value={defaultOption}
                onChange={this.props.onDropdownChange} />
        )
    }

    render() {
        return (
            <Fragment>
                <div className="statistics-head">
                    <h1>Uppgifts översikt</h1>
                    {this.renderDropdown()}
                </div>

                <div className="row statistics-row">
                    {this.renderSquares()}
                </div>
            </Fragment>
        )
    }
}