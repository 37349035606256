import React from 'react';
import {Tools} from '../../tools/tools';
import Store from 'eas-react-store';

class SingleSelectUserItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isChecked: this.props.isChecked
        }
    }

    handleClickOnCheckbox() {
        this.props.handleUserCheckbox(this.props.user, !this.state.isChecked)
        this.setState({
            isChecked: !this.state.isChecked
        })
    }

    getCheckboxClassName() {
        let className = "user-check";
        className += this.state.isChecked ? " checked" : "";
        return className
    }

    renderLabel() {
        let className = this.props.user.group_ids.length > 0 ? 'active' : 'inaktiv';

        return (
            <span className={`user-label ml-auto ${className}`}>{this.props.user.group_ids.length > 0 ? 'Aktiv' : 'Inaktiv'}</span>
        )
    }

    render() {
        var initials = Tools.getInitials(this.props.user.name);
        let group = "?";

        if (this.props.user.group_ids.length > 0) {
            group = this.props.store.Group.data.find((item) => item.id === this.props.user.group_ids[0]);
            group = group.name;
        }

        return (
            <li className="user" key={this.props.user.id}>
                <div className="user-image">
                    <span>{initials}</span>
                </div>
                <span className="user-name">{this.props.user.name}</span>
                <span className="user-task ml-auto">{group}</span>

                {this.props.mode === 'add-user' ? this.renderLabel() : ""}

                <label htmlFor="user-check" className={this.getCheckboxClassName()}>
                    <span className="custom-check"></span>
                    <input type="checkbox" name="user-check" id="user-check" onClick={this.handleClickOnCheckbox.bind(this)}/>
                </label>
            </li>
        );
    }
}

export default Store.connectStore(SingleSelectUserItem);