import React from "react";
import moment from "moment";

export class PrintHeader extends React.Component {
    /**
     * Returns the today date, in the D/MM/YYYY format.
     * Ex: 3/12/2020
     */
    getTodayDateFormatted() {
        let now = moment();
        return now.format("D/MM/YYYY, HH:mm");
    }

    /**
     * Renders the category list and their associated colors.
     * Reads the color of the category and puts it as inline style.
     */
    renderCategoriesCaption() {
        let returnArr = [];
        this.props.selectedCategories.forEach((category) => {
            let selectedCat = this.props.data.categories.find(
                (cat) => cat.id === parseInt(category)
            );

            // selectedCat can be empty since the array does not contain only categories ids.
            // Therefore, we must check for undefined when using it.
            if (selectedCat) {
                const style = {
                    backgroundColor: "#" + selectedCat.color,
                };
                returnArr.push(
                    <div className="flex-y-align mb-2">
                        <span className="category-pill" style={style}></span>
                        {" " + selectedCat.title}
                    </div>
                );
            }
        });

        return returnArr;
    }

    render() {
        return (
            <div className="header">
                <img
                    src={this.props.data.organisation.icon}
                    alt="Organisation Logo"
                />
                <div>
                    <div className="caption">
                        {this.props.data.organisation.name}
                    </div>
                    <div className="title mb-2">
                        {this.props.title}
                    </div>
                    <div className="subtitle">
                        {this.props.subtitle}
                    </div>
                </div>
                <div>{this.renderCategoriesCaption()}</div>
                <div className="print-date small">
                    <div className="caption">Print date</div>
                    <div>{this.getTodayDateFormatted()}</div>
                </div>
            </div>
        );
    }
}
