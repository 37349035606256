export default class BaseController {
    constructor(updateStore) {
        if (typeof updateStore !== 'function') {
            let baseControllerError = {
                error: 'update store is not a function!'
            };
            throw (baseControllerError);
        }

        this.updateStore = updateStore;
    }
}