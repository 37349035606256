import React from 'react';
import Store from 'eas-react-store';
import SingleSelectUserItem from './SingleSelectUserItem';
import {Tools} from '../../tools/tools';
import Dropdown from 'react-dropdown';

class SelectAddUsersModal extends React.Component {
	constructor(props) {
		super(props);

		this.options = {
            groups: [],
            roles: [],
            categories: []
        };

		if (this.props.store.Group != null) {
			this.props.store.Group.data.forEach((group, index) => {
				this.options.groups.push({value: index, label: Tools.makeBeautifulLabel(group.name), id: group.id});
			});
		}

		if (this.props.store.User != null && this.props.store.User.data != null) {
			this.props.store.User.data.userlevel.forEach((level, index) => {
				this.options.roles.push({value: index, label: Tools.makeBeautifulLabel(level != "group_leader" ? level : "Service Grupp Ledare"), id: level});
			});
		}

		if (this.props.store.Category != null) {
			this.props.store.Category.data.forEach((category, index) => {
				this.options.categories.push({value: index, label: Tools.makeBeautifulLabel(category.title), id: category.id});
			});
        }

        let data = this.props.store.User.data.users;

		this.state = {
			chosenUsers: [],
			allUsersList: data,
			filteredUsersList: data,
			usersByCategory: [],
			currentSidebarFilter: 0, // Use 0 for all, the ID of the group otherwise
			searchFieldValue: "",
            dropdown: {
                groups: this.options.groups[0],
                roles: this.options.roles[0],
                categories: this.options.categories[0],
            }
		}

		this.onDropdownChange = this.onDropdownChange.bind(this);
		this.handleClickOnAdd = this.handleClickOnAdd.bind(this);
		this.onClickSelectAll = this.onClickSelectAll.bind(this);
	}

	componentDidMount () {
        this.handleInactiveUsers(0); // Show inactive first
	}

	// MARK: - Logic methods

	getUsersMatching(pattern) {
		var returnArr = [];
		returnArr = this.state.usersByCategory.filter(function (usr) {
			return usr.name.toLowerCase().indexOf(pattern.toLowerCase()) !== -1
		})

		return returnArr;
	}

	getFilterClassNameForGroupId(id) {
		var className = "filter";
		className += this.state.currentSidebarFilter === id ? " current" : "";

		return className;
	}

	// MARK: - Handling input changes

	/**
	 * Handles the change of value of the search input field
	 * @param {Event} evt The JS event
	 */
	handleSearchInputChange(event) {
		this.setState({
            searchFieldValue: event.target.value,
            filteredUsersList: this.getUsersMatching(event.target.value)
		});
	}

	searchUsers(query) {
		if (query !== "") {
			this.setState({
				filteredUsersList: this.getUsersMatching(query)
			});
		} else {
			this.setState({
				filteredUsersList: this.state.usersByCategory
			});
		}
	}

	async handleGroupFilterChange(id) {
		let filteredUsersList = [];

		if (id === 0) {
			this.state.allUsersList.forEach((user) => {
				if (user.group_ids.length > 0) {
					filteredUsersList.push(user);
				}
			})
		} else {
			this.state.allUsersList.forEach((user) => {
				if (user.group_ids[0] === id) {
					filteredUsersList.push(user);
				}
			});
		}

		this.setState({
			currentSidebarFilter: id,
			usersByCategory: filteredUsersList
		}, () => this.searchUsers(this.state.searchFieldValue));
	}

	handleRolesChange(id, role) {
		let usersByRole = [];

		this.state.allUsersList.forEach((user) => {
			if (user.userlevel === role) {
				usersByRole.push(user);
			}
		});

		this.setState({
			currentSidebarFilter: id,
			usersByCategory: usersByRole
		}, () => this.searchUsers(this.state.searchFieldValue));
	}

	handleInactiveUsers(id) {
		let inactiveUsers = [];

		this.state.allUsersList.forEach((user) => {
			if (user.group_ids.length <= 0) {
				inactiveUsers.push(user);
			}
		});

		this.setState({
			currentSidebarFilter: id,
			usersByCategory: inactiveUsers
		}, () => this.searchUsers(this.state.searchFieldValue));
	}

	handleUserCheckbox(user, isChecked) {
		var chosenUsers = this.state.chosenUsers.map(user => user);

		if (isChecked) {
			chosenUsers.push(user)
		} else {
			// Pop the user out of the array
			var idx = chosenUsers.indexOf(user)
			if (idx > -1) {
				chosenUsers.splice(idx, 1)
			}
		}

		let groupOption = this.options.groups.find((group) => group.id === user.group_ids[0]);
		let roleOption = this.options.roles.find((role) => role.id === user.userlevel);

		let categoryOption = this.options.categories[0];

		if (user.userlevel === 'team_leader') {
			let group = this.props.store.Group.data.find((group) => group.id === groupOption.id);
			let category = null;

			group.categories.forEach((cat) => {
				let temp = cat.team_leaders.find((usr) => usr.id === user.id);

				if (temp != null) {
					category = cat;
				}
			});

			categoryOption = this.options.categories.find((cat) => cat.id === category.id);
		}

		this.setState((state) => ({
			...state,
			chosenUsers: chosenUsers,
			dropdown: {
				...state.dropdown,
				groups: groupOption,
				roles: roleOption,
				categories: categoryOption
			}
		}));

	}

	async handleClickOnAdd() {
		let newGroup = this.state.dropdown.groups.id;
		let newRole = this.state.dropdown.roles.id;
		let newResponsibility = 1;

		if (newRole === "team_leader") {
			newResponsibility = this.state.dropdown.categories.id;
		}

		await Promise.all(this.state.chosenUsers.map(async (user) => {
			if (user.group_ids.length > 0) {
				await this.props.store.User.removeUserFromGroup(user.group_ids[0], user);
			}


			if (newRole === "team_leader" || newRole === "group_leader" || newRole === "admin") {
				await this.props.store.User.addUserToGroup(newGroup, newRole, newResponsibility,  user);
			} else {
				await this.props.store.User.addUserToGroup(newGroup, "user", 1,  user);
			}

			if (newRole !== user.userlevel) {
				user.userlevel = newRole;
				await this.props.store.User.updateUser(user);
			}
        }));

        this.props.store.User.fetchAllUsers();
        this.props.onClickOnAbort();
	}

	onClickSelectAll() {
		let tempUsers = this.state.filteredUsersList.map(user => user);

		this.setState((state) => ({
			...state,
			chosenUsers: state.filteredUsersList,
			filteredUsersList: []
		}), () => this.setState((state) => ({
			...state,
			filteredUsersList: tempUsers
		})));
	}

	// MARK: - Rendering methods

	renderGroupsFilters() {
		let returnArray = [];

        returnArray.push(
            <li className={this.getFilterClassNameForGroupId(0)} onClick={() => this.handleInactiveUsers(0)} key="0"><span>Endast inaktiva</span></li>
        )

        if (this.props.store.User.data != null) {
            this.props.store.User.data.userlevel.forEach((level, index) => {
                returnArray.push(
                    <li className={this.getFilterClassNameForGroupId(index+1000)} onClick={() => this.handleRolesChange(index+1000, level)} key={index+1000}><span>{Tools.makeBeautifulLabel(level != "group_leader" ? level : "Service Grupp Ledare")}</span></li>
                );
            });
        }

		if (this.props.store.Group.data != null) {
			this.props.store.Group.data.sort().forEach((group) => {
				returnArray.push(
					<li
						className={this.getFilterClassNameForGroupId(group.id)}
						key={group.id}
						onClick={() => this.handleGroupFilterChange(group.id)}>
						<span>{Tools.makeBeautifulLabel(group.name)}</span>
					</li>
				)
			});
		}

		return returnArray;
	}

	onDropdownChange(event, name) {
        this.setState((state) => ({
            ...state,
            dropdown: {
                ...state.dropdown,
                [name]: this.options[name][event.value]
            }
        }));
	}

	render() {
		return (
			<div className="select-user-modal">
				<div className="modal-container">
					<div className="w-100 modal-head">
						<h2>Lägg till användare</h2>
					</div>
					<div className="row">
						<div className="col-8">
							<div className="modal-col-wrapper">
								<div className="w-100 modal-search">
									<input
										className="form-control modal-search-input"
										type="text"
										name="searchFieldValue"
										value={this.state.searchFieldValue}
										onChange={this.handleSearchInputChange.bind(this)}
										placeholder="Sök ..." />
								</div>
								<div className="container-fluid">
									<div className="row">
										<div className="col-4 modal-user-filter">
											<ul className="filter-list">
												{this.renderGroupsFilters()}
											</ul>
										</div>
										<div className="col-8 modal-user-list">
											{this.state.filteredUsersList.length > 0 &&
												<div>
												<button
													className="select-all"
													onClick={this.onClickSelectAll}>
														Välj alla
												</button>
												<div className="select-all-clear"></div>
												</div>
											}
											<ul className="user-list">
                                                {this.state.filteredUsersList.map(user =>
                                                    <SingleSelectUserItem
														user={user}
                                                        isChecked={this.state.chosenUsers.find((chosenUser) => chosenUser.id === user.id) != null}
                                                        handleUserCheckbox={this.handleUserCheckbox.bind(this)}
                                                        key={user.id}
                                                    />
												)}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-4">
							<div className="modal-selected-user">
								<h3>Valda användare</h3>
								<ul className="w-100 user-list">
                                    {this.state.chosenUsers.map(user =>
                                        <li className="user" key={user.id}>
                                            <div className="user-image">
                                                <span>{Tools.getInitials(user.name)}</span>
                                            </div>
                                            <span className="user-name">{user.name}</span>
                                        </li>
                                    )}
								</ul>
								{/* <div className="w-100 modal-description mt-auto"> */}
								<div className="w-100 modal-description">
                                    <div>
                                        <div>
                                            <label>Grupp</label>
                                            <Dropdown
                                                options={this.options.groups}
                                                value={this.state.dropdown.groups}
                                                onChange={(event) => this.onDropdownChange(event, "groups")}
                                            />
                                        </div>
                                        <div>
                                            <label>Roles</label>
                                            <Dropdown
                                                options={this.options.roles}
                                                value={this.state.dropdown.roles}
                                                onChange={(event) => this.onDropdownChange(event, "roles")}
                                            />
                                        </div>
                                        {this.state.dropdown.roles.id === "team_leader" &&
                                            <div>
                                                <label>Responsibiliy</label>
                                                <Dropdown
                                                    options={this.options.categories}
                                                    value={this.state.dropdown.categories}
                                                    onChange={(event) => this.onDropdownChange(event, "categories")}
                                                />
                                            </div>
                                        }
                                    </div>
								</div>
							</div>
						</div>

					</div>

					<div className="w-100 modal-footer">
						<button
							className="footer-btn cancel"
							onClick={this.props.onClickOnAbort}>
                                Avbryt
                        </button>

                        <button
                            id="add-user-to-group"
                            onClick={this.handleClickOnAdd.bind(this)}
                            className="footer-btn save">
                                Lägg till användare i vald grupp
                        </button>
					</div>
				</div>
			</div>
		);
	}
}

export default Store.connectStore(SelectAddUsersModal);