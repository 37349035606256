import React, { Fragment } from 'react';
import Store from 'eas-react-store';
import Header from '../layout/Header/Header.jsx';
import CategoriesTableRow from '../../components/categories-table-row/CategoriesRow';
import { URI } from '../../URI'

class AdminCategories extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            categories: this.props.store.Category.data,
            isAddingNewCategory: false
        }
        
    }

    handleNewCategoryButton() {
        this.setState({
            isAddingNewCategory: !this.state.isAddingNewCategory,
        })
    }

    renderAddNewCategoryField() {
        if (this.state.isAddingNewCategory) {
            let newCat = {
                title: "",
                color: "8BC34A"
            };
            return(
                <CategoriesTableRow
                    category={newCat}
                    isEditMode={true}
                    hideNewCategoryField={this.handleNewCategoryButton.bind(this)}
                />
            )
        }
    }

    renderRows() {
        return this.state.categories.map((cat) => {
            return (
                <CategoriesTableRow
                    key={cat.id}
                    category={cat}
                    isEditMode={false}
                />
            )
        })
    }

    render() {
        return (
            <Fragment>
                <Header
                    current={URI.admin.categories}
                    currentRoot={URI.admin.root}
                />
                <div className="container content-container">
                    <div className="category-table-header mb-3">
                        <h1>Kategorier</h1>
                        <button
                            className="btn btn-primary btn-table add-user"
                            onClick={() => this.handleNewCategoryButton()}
                        >
                            Lägg till en kategori
                        </button>
                    </div>
                    <table className="categories-table">
                        <thead>
                            <tr>
                                <th>Namn</th>
                                <th>Färg</th>
                                <th className="cell-right">Åtgärd</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows()}
                            {this.renderAddNewCategoryField()}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        );
    }

}

export default Store.connectStore(AdminCategories)