import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import SafeRoute from './components/safe-route/SafeRoute';
import Schedule from './views/schedule/Schedule';

import AdminManageUsers from './views/administration/AdminManageUsers';
import AdminCategories from './views/administration/AdminCategories';
import AdminStatistics from './views/administration/AdminStatistics';

import MyGroupUsers from './views/my-group/MyGroupUsers';
import MyGroupStatistics from './views/my-group/MyGroupStatistics';
import { URI } from './URI';
import Store from 'eas-react-store';
import UserController from './app/controllers/controller.user';
import NotAllowed from './views/layout/NotAllowed';
import GroupsWeeks from './views/groups-weeks/GroupsWeeks';
import AdminManageUsersNew from './views/administration/AdminManageUsersNew';

const Main = (props) => {
    let isAdmin = props.store.User.getCurrentUserAccess() === UserController.USER_ACCESS_RIGHTS.admin || props.store.User.getCurrentUserAccess() === UserController.USER_ACCESS_RIGHTS.super_admin;
    let isTeamleader = props.store.User.getCurrentUserAccess() === UserController.USER_ACCESS_RIGHTS.team_leader;

    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <SafeRoute exact path={URI.schedule} component={Schedule} store={props.store} />
                    <SafeRoute exact path={URI.admin.manageUsers} component={AdminManageUsersNew} redirect={!isAdmin} />
                    <SafeRoute exact path={URI.admin.categories} component={AdminCategories} redirect={!isAdmin} />
                    <SafeRoute exact path={URI.admin.statistics} component={AdminStatistics} redirect={!isAdmin} />
                    <SafeRoute exact path={URI.admin.groupsWeeks} component={GroupsWeeks} store={props.store} redirect={!isAdmin} />
                    <SafeRoute exact path={URI.myGroup.users} component={AdminManageUsersNew} store={props.store} redirect={isTeamleader} />
                    <SafeRoute exact path={URI.myGroup.statistics} component={MyGroupStatistics} store={props.store} redirect={isTeamleader} />
                    <Route component={() => <NotAllowed mainText="404" />} />
                </Switch>
            </BrowserRouter>
        </div>
    );
}


export default Store.connectStore(Main);
