import BaseController from './controller.base';
import ApiUsers from '../api/api.users';
import ApiGroups from '../api/api.groups';
import { URI } from '../../URI';

export default class User extends BaseController {
    static controllerName = 'User';
    static isValid = false;
    static connectedUser = {};

    /**
     * Enum containing the possible access rights to the app
     */
    static USER_ACCESS_RIGHTS = {
        user: 'user',
        admin: 'admin',
        group_leader: 'group_leader',
        team_leader: 'team_leader',
        super_admin: 'super_admin'
    }

    constructor(updateStore, config, localStorage) {
        super(updateStore);

        this.Api = {
            users: new ApiUsers(config.path),
            groups: new ApiGroups(config.path)
        };

        this.getCurrentUserAccess = this.getCurrentUserAccess.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.loginWithEmailPassword = this.loginWithEmailPassword.bind(this);
        this.askToChangePassword = this.askToChangePassword.bind(this);

        if (localStorage != null) {
            if (localStorage.User != null) {
                this.data = localStorage.User;
                this.token = localStorage.User.token;
                this.isValid = localStorage.User.isValid;
            }
            this.updateStore();
        } else {
            this.data = {};
            this.token = "";
            this.data["users"] = [];
            this.data.userlevel = [];
            this.data.connectedUser = {};
            this.data.organisation = {};
        }

        // Read connected user from static attribute. Cleaning first.
        if (this.data.connectedUser != null) {
            this.data.connectedUser = {}
        }
        if (User.connectedUser != null) {
            this.data.connectedUser = User.connectedUser;
        }

        this.currentUserAccess = User.USER_ACCESS_RIGHTS[this.data.connectedUser.userlevel];
        this.isGroupLeader = User.USER_ACCESS_RIGHTS[this.data.connectedUser.userlevel] === User.USER_ACCESS_RIGHTS.group_leader;

        if (User.isValid !== "" && User.isValid && this.currentUserAccess !== User.USER_ACCESS_RIGHTS.user && this.currentUserAccess !== User.USER_ACCESS_RIGHTS.team_leader) {
            this.fetchAllUsers();
            this.getUserlevel();
        }

        if (User.isValid !== "" && User.isValid && this.currentUserAccess !== User.USER_ACCESS_RIGHTS.user) {
            this.fetchCurrentOrganisation();
        }

        this.updateStore();
    }

    async loginWithEmailPassword(email, password) {
        let response = await this.Api.users.loginWithEmailPassword({ email, password });

        return response;
    }

    async askToChangePassword(email) {
        let response = await this.Api.users.askToChangePassword({ email });

        return response;
    }

    getCurrentUserAccess() {
        return this.currentUserAccess;
    }

    // MARK: - API Methods

    /**
     * Fetches all the users from the database
     * Stores the result using eas-react-store
     */
    async fetchAllUsers(withLoader = true) {
        if (withLoader) this.isLoading = true;

        let users = [];

        if (this.currentUserAccess === User.USER_ACCESS_RIGHTS.group_leader) {
            users = await this.Api.groups.getUsersFromGroup(this.data.connectedUser.group_ids[0], this.token);

            if (users[0] != null) {
                users.map((user) => user.group_ids = [this.data.connectedUser.group_ids[0]]);
            }
        } else {
            users = await this.Api.users.getUsers(this.token);
        }

        this.data.users = users;

        if (withLoader) this.isLoading = false;

        this.updateStore();
    }

    async fetchCurrentOrganisation() {
        let organisation = await this.Api.users.getOrganisation(this.token);
        this.data.organisation = organisation;
    }

    async updateUser(user) {
        return await this.Api.users.putUser(user.id, user, this.token);
    }

    async createUser(user) {
        return await this.Api.users.createUser(user, this.token);
    }

    /**
     * Sends an API request to remove a user from a specific group.
     * @param {int} groupId The ID of the group to remove the user from
     * @param {User} user The user to remove form the group
     */
    async removeUserFromGroup(groupId, user) {
        const data = {
            user_id: user.id
        }
        await this.Api.groups.deleteUserFromGroup(groupId, data, this.token);
    }

    async deleteUser(user_id) {
        await this.Api.users.deleteUser(user_id);

        this.fetchAllUsers();
    }

    /**
     * Sends an API request to add a user to a specific group.
     * @param {int} groupId The ID of the group to add the user to
     * @param {User} user The user to add to the group
     */
    async addUserToGroup(groupId, role, responsibility, user) {
        const data = {
            user_id: user.id,
            role,
            responsibility
        }

        await this.Api.groups.addUserToGroup(groupId, data, this.token);
    }

    async getUsersOfGroup(groupId) {
        let groupUsers = await this.Api.groups.getUsersFromGroup(groupId, this.token);

        groupUsers = await Promise.all(groupUsers.map(async (user) => {
            user.statistics = await this.fetchUserStatistics(user.id);
            return user;
        }));

        return groupUsers;
    }

    getUserResponsability(groups, user) {
        let group = groups.find((g) => g.id === user.group_ids[0]);
        let category = null;

        group.categories.forEach((cat) => {
            let temp = cat.team_leaders.find((usr) => usr.id === user.id);

            if (temp != null) {
                category = cat;
            }
        });

        return category;
    }

    async fetchUserStatistics(user_id) {
        return await this.Api.users.getUserStatistics(user_id);
    }

    async getUserlevel() {
        this.data.userlevel = await this.Api.users.getUserlevel();
        this.updateStore();
    }

    static async validateSession(url) {
        // console.log("=== VALIDATING USERS ===")
        let response = await ApiUsers.validate(url);

        if (response.success === 1) {
            // TODO: check userlevel and accessrights
            User.connectedUser = response.user;
            User.isValid = true;
        } else {
            User.isValid = false;
        }

        return response;
    }

    async importUsers(data) {
        await this.Api.users.importUsers(data, this.token);
    }
}
