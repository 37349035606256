import React, { Fragment } from 'react';
import Store from "eas-react-store";
import Header from '../layout/Header/Header.jsx';
import UserTable from '../../components/user-table/UserTable'
import { URI } from '../../URI'
import AdminManageUsersNew from '../administration/AdminManageUsersNew.jsx';
import Loader from '../../components/svg/loader.jsx';
import { Regex } from '../../tools/regex.js';

class MyGroupUsers extends React.Component {
    constructor(props) {
        super(props);

        this.connectedUserGroup = 0;
        if (this.props.store.User.data.connectedUser != null) {
            this.connectedUserGroup = this.props.store.User.data.connectedUser.group_ids[0]
        }

        this.state = {
            users: []
        };
    }

    async componentDidMount() {
        let fetchedUsers = await this.props.store.User.getUsersOfGroup(this.connectedUserGroup);

        fetchedUsers = fetchedUsers.map(user => {
            user.group_ids = [this.connectedUserGroup];
            return user;
        });

        this.setState({
            users: fetchedUsers
        });
    }

    render() {
        return (
            <AdminManageUsersNew
            />
        );
    }
}

export default Store.connectStore(MyGroupUsers);