import React, { Fragment } from 'react';
import Store from 'eas-react-store';
import Header from '../layout/Header/Header';
import { SquareStatisticsArray } from '../../components/square-statistics/SquareStatisticsArray';
import { GroupStatistics } from '../../components/group-statistics/GroupStatistics';
import { URI } from '../../URI'

class AdminStatistics extends React.Component {
    constructor(props) {
        super(props);

        /* Get the past and future statistics.
         * The data is in the Statistics controller managed by the state.
         * However, it will be empty on first load and therefore, the object will be undefined
         * and the app will crash when trying to read it.
         * ==> For that reason we need to make a (!= null) check first!
         */
        this.globalPastStatistics   = (this.props.store.Statistics.data.tasks.past   != null ? this.props.store.Statistics.data.tasks.past.categories : []);
        this.globalFutureStatistics = (this.props.store.Statistics.data.tasks.future != null ? this.props.store.Statistics.data.tasks.future.categories : []);

        this.groupsPastStatistics   = (this.props.store.Statistics.data.groups.past   != null ? this.props.store.Statistics.data.groups.past : []);
        this.groupsFutureStatistics = (this.props.store.Statistics.data.groups.future != null ? this.props.store.Statistics.data.groups.future : []);

        /**
         * We put the stats in an array and we'll work with the the index
         * in the dropdown selector.
         */
        this.statistics = [
            this.globalPastStatistics,
            this.globalFutureStatistics
        ]

        this.groupStatistics = [
            this.groupsPastStatistics,
            this.groupsFutureStatistics
        ]

        /**
         * The groups statistics must be re-formatted to be ready to be rendered by the view.
         */
        var groupStatisticsToRender = this.sortGroupStatistics(this.groupStatistics[0]);

        this.state = {
            chosenStatsIndex: 0,
            statisticsToRender: this.statistics[0],
            groupStatisticsToRender: groupStatisticsToRender
        };
    }

    sortGroupStatistics(groupStats) {
        return groupStats.map(group => {
            let res = {};
            res.categories = [];
            let total = 0;

            group.statistics.forEach(stat => {
                total += stat.amount;
                res.categories.push({
                    "name": stat.title,
                    "amount": stat.amount
                });
            });

            res.group = group.id;
            res.total = total;
            res.group_name = group.name;

            return res;
        })
    }

    onDropdownChange(evt) {
        const chosenIndex = evt.value;
        const groupStatisticsToRender = this.sortGroupStatistics(this.groupStatistics[chosenIndex]);

        this.setState({
            statisticsToRender: this.statistics[chosenIndex],
            groupStatisticsToRender: groupStatisticsToRender,
            chosenStatsIndex: chosenIndex
        })

    }

    render() {
        return (
            <Fragment>
                <Header 
                    current={URI.admin.statistics}
                    currentRoot={URI.admin.root}
                />
                <div className="container statistics-container">
                    <SquareStatisticsArray
                        categories={this.state.statisticsToRender}
                        dropdownSelected={this.state.chosenStatsIndex}
                        onDropdownChange={this.onDropdownChange.bind(this)}/>
                    <GroupStatistics groups={this.state.groupStatisticsToRender} />
                </div>
            </Fragment>
        );
    }
}

export default Store.connectStore(AdminStatistics);