import React from 'react';
import Store from 'eas-react-store';
import Dropdown from 'react-dropdown';
import { Tools } from '../../tools/tools';


class FormSingleUser extends React.Component {

    constructor(props) {
        super(props)

        this.options = {
            groups: [],
            roles: [],
            categories: []
        };

        if (this.props.store.Group != null) {
            this.props.store.Group.data.forEach((group, index) => {
                this.options.groups.push({ value: index, label: Tools.makeBeautifulLabel(group.name), id: group.id });
            });
        }

        if (this.props.store.User != null && this.props.store.User.data != null) {
            this.props.store.User.data.userlevel.forEach((level, index) => {
                this.options.roles.push({ value: index, label: Tools.makeBeautifulLabel(level !== "group_leader" ? level : "Service Grupp Ledare"), id: level });
            });
        }

        if (this.props.store.User.isGroupLeader) {
            this.options.roles = this.options.roles.filter((userRole) => userRole.id !== "admin" && userRole.id !== "super_admin");
            this.options.groups = [{ value: 0, label: Tools.makeBeautifulLabel(this.props.store.Group.data.find((group => group.id === this.props.user.group_ids[0])).name), id: this.props.user.group_ids[0] }];
        }

        if (this.props.store.Category != null) {
            this.props.store.Category.data.forEach((category, index) => {
                this.options.categories.push({ value: index, label: Tools.makeBeautifulLabel(category.title), id: category.id });
            });
        }

        if (this.props.user.phone == null) this.props.user.phone = "";

        this.state = {
            user: this.props.user,
            dropdown: {
                groups: this.options.groups[0],
                roles: this.options.roles[0],
                categories: this.options.categories[0],
            },
            failedSubmit: false,
            errorPopup: {
                visible: false,
                message: ''
            }
        }

        this.onDropdownChange = this.onDropdownChange.bind(this);
    }

    updateSelectedUser(user) {
        // Sets the team leader category to default
        let userTeamLeaderCategory = this.options.categories[0];
        let userGroup = this.options.groups.find(group => group.id === user.group_ids[0]);
        let userGroupData = {}
        if (userGroup != null) {
            userGroupData = this.props.store.Group.data.find((group) => group.id === userGroup.id);
        } else {
            userGroupData = this.props.store.Group.data[0];
        }

        let userCategoryID = null;
        if (user.userlevel === 'team_leader') {
            userGroupData.categories.forEach((cat) => {
                let found = cat.team_leaders.find((usr) => usr.id === user.id);
                if (found != null) {
                    userCategoryID = cat.id;
                }
            });
        }
        if (userCategoryID != null) {
            userTeamLeaderCategory = this.options.categories.find((cat) => cat.id === userCategoryID);
        }

        if (user.phone == null) user.phone = "";

        this.setState((state) => ({
            ...state,
            user,
            dropdown: {
                groups: userGroup,
                roles: this.options.roles.find(role => role.id === user.userlevel),
                categories: userTeamLeaderCategory
            }
        }));
    }

    /**
     * Handles the change of value of all the input fields
     * @param {Event} evt The JS event
     */
    onInputChange(evt) {
        let newName = evt.target.value;
        let fieldName = evt.target.name;

        this.setState((state) => ({
            ...state,
            user: {
                ...state.user,
                [fieldName]: newName
            }
        }));
    }

    onDropdownChange(event, name) {
        this.setState((state) => ({
            ...state,
            dropdown: {
                ...state.dropdown,
                [name]: this.options[name][event.value]
            }
        }));
    }

    showErrorPopup(message) {
        this.setState({
            errorPopup: {
                visible: true,
                message
            }
        });
    }

    hideErrorPopup() {
        this.setState({
            errorPopup: {
                visible: false,
                message: ''
            }
        });
    }

    async onClickSave() {
        let userToSend = {}

        // Make sure to not send an empty user
        if (
            this.state.user.name === "" ||
            this.state.user.email === "" ||
            this.state.dropdown.roles == null ||
            this.state.dropdown.groups == null
        ) {
            console.log("Error something is lacking!");
            this.setState((state) => ({
                ...state,
                failedSubmit: true
            }));
            return;
        }

        this.setState((state) => ({
            ...state,
            failedSubmit: false
        }));

        userToSend["id"] = this.state.user.id;
        userToSend["name"] = this.state.user.name;
        userToSend["phone"] = this.state.user.phone;
        userToSend["email"] = this.state.user.email.toLowerCase();
        userToSend["userlevel"] = this.state.dropdown.roles.id;

        if (this.state.user.id > 0) {
            const response = await this.props.store.User.updateUser(userToSend);
            if (response.error) {
                this.showErrorPopup(response.error);
                return;
            }
            await this.props.store.User.removeUserFromGroup(this.state.user.group_ids[0], this.state.user);
        } else {
            const response = await this.props.store.User.createUser(userToSend);
            if (response.error) {
                this.showErrorPopup(response.error);
                return;
            }
            this.state.user.id = response.user_id
        }

        await this.props.store.User.addUserToGroup(this.state.dropdown.groups.id, this.state.dropdown.roles.id, this.state.dropdown.categories.id, this.state.user);

        this.props.store.Group.getGroups(false);
        this.props.store.User.fetchAllUsers(false);

        this.props.onClickCancel();
    }

    render() {
        const popupStyle = {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 1000
        };

        const contentStyle = {
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            textAlign: "center",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            maxWidth: "400px",
            width: "100%"
        };

        const buttonStyle = {
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            padding: "10px 20px",
            borderRadius: "4px",
            cursor: "pointer",
            fontSize: "14px"
        };

        const buttonHoverStyle = {
            backgroundColor: "#0056b3"
        };

        return (
            <div className={`slide-overview ${this.props.isActive ? 'active' : ''}`}>

                <section className="d-flex flex-column align-items-center">
                    <div className="user-image text-center">
                        <span>{Tools.getInitials(this.state.user.name)}</span>
                    </div>
                    <h2>{this.state.user.name}</h2>
                    <p className="text-secondary mono-font">{this.state.user.email}</p>
                    <p className="text-secondary">{this.state.user.phone}</p>
                </section>

                <section>
                    <h3>Kontaktuppgifter</h3>
                    <label className="edit-label" htmlFor="title">Namn*</label>
                    <input
                        type="text"
                        className="form-control edit-input"
                        name="name"
                        value={this.state.user.name}
                        onChange={this.onInputChange.bind(this)}
                        placeholder='Namn'
                        required
                    />
                    <label className="edit-label mt-2" htmlFor="title">Epost*</label>
                    <input
                        type="text"
                        className="form-control edit-input"
                        name="email"
                        value={this.state.user.email}
                        onChange={this.onInputChange.bind(this)}
                        placeholder='Epost'
                        required
                    />
                    <label className="edit-label mt-2" htmlFor="title">Telefonnummer</label>
                    <input
                        type="text"
                        className="form-control edit-input"
                        name="phone"
                        value={this.state.user.phone}
                        onChange={this.onInputChange.bind(this)}
                        placeholder='Telefonnummer'
                        required
                    />
                </section>

                <section>
                    <h3>User settings</h3>
                    <label className="edit-label" htmlFor="title">Roll</label>
                    <Dropdown
                        options={this.options.roles}
                        onChange={(event) => this.onDropdownChange(event, "roles")}
                        value={this.state.dropdown.roles}
                        placeholder="Välj en roll"
                    />

                    {this.state.dropdown.roles.id === "team_leader" &&
                        <Dropdown
                            options={this.options.categories}
                            className="mt-2"
                            name="categories"
                            onChange={(event) => this.onDropdownChange(event, "categories")}
                            value={this.state.dropdown.categories}
                            placeholder="Välj ett ansvar"
                        />
                    }

                    <label className="edit-label mt-2" htmlFor="title">Grupp*</label>
                    <Dropdown
                        options={this.options.groups}
                        onChange={(event) => this.onDropdownChange(event, "groups")}
                        value={this.state.dropdown.groups}
                        placeholder="Välj en grupp"
                    />
                    {this.state.failedSubmit &&
                        <p className="warning-message">OBS! Kontrollera de obligatoriska fälten*</p>
                    }
                </section>

                <div className="slide-overview-controls">
                    <button className="btn btn-cancel" onClick={() => {
                        this.setState({ failedSubmit: false });
                        this.props.onClickCancel();
                    }}>Avbryt</button>
                    <button className="btn btn-save" onClick={() => this.onClickSave()}>Spara</button>
                </div>

                {/* Error popup */}
                {this.state.errorPopup.visible &&
                    <div style={popupStyle}>
                        <div style={contentStyle}>
                            <p>{this.state.errorPopup.message}</p>
                            <button
                                style={buttonStyle}
                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                                onClick={() => this.hideErrorPopup()}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default FormSingleUser;