import external from '../../tools/base.external';

export default class ApiUsers extends external {

    async loginWithEmailPassword(data) {
        let users = await this.post.json(`users/login`, data);

        return users;
    }

    async logout() {
        let response = await this.post.json(`users/logout`, {});

        return response;
    }


    async askToChangePassword(data) {
        let response = await this.post.json(`users/forgotPassword`, data);

        return response;
    }

    async getUsers(token) {
        let users = await this.get.json(`users`, token);
        return users;
    }

    async putUser(id, data, token) {
        let user = await this.put.json(`users/${id}`, data, token);
        return user;
    }

    async createUser(data, token) {
        return await this.post.json(`users`, data, token);
    }

    async getUserStatistics(user_id) {
        let stats = await this.get.json(`statistics/users/${user_id}`)

        return stats;
    }

    async getUserlevel() {
        let userlevel = await this.get.json(`users/userlevel`);

        return userlevel;
    }

    static async validate(url) {
        let response = await external.static_get(url + 'users/validate');

        return response;
    }

    async deleteUser(user_id) {
        let response = await this.delete.json(`users/${user_id}`, {});

        return response;
    }

    async getOrganisation(token) {
        let organisation = await this.get.json(`organisations/current`, token);

        return organisation;
    }

    async importUsers(data, token) {
        return await this.post_multipart.json(`users/import`, data, token);
    }
}