import React, { Component, Fragment } from 'react';
import Header from '../layout/Header/Header';
import { URI } from '../../URI';
import Store from 'eas-react-store';
import ManageUsersSidebar from './manage-users/ManageUsersSidebar';
import Checkbox from '../../components/svg/checkbox';
import TableHeadItem from './manage-users/TableHeadItem';
import Pagination from './manage-users/Pagination';
import ActionsTopbar from './manage-users/ActionsTopbar';
import TableItem from './manage-users/TableItem';
import { Tools } from '../../tools/tools';
import FormSingleUser from '../../components/slide-over/form-single-user';
import FormSendMessage from '../../components/slide-over/form-send-message';
import Loader from '../../components/svg/loader';
import EmailIcon from "../../components/svg/email-icon";
import FormImportUsers from "../../components/slide-over/form-import-users";

class AdminManageUsersNew extends Component {
    constructor(props) {
        super(props);

        let users = this.props.store.User.data.users;

        this.state = {
            users: users,
            paginatedUsers: users,
            defaultUsers: users,
            currentPage: 1,
            isChecked: [],
            isMainChecked: false,
            isCheckedAll: false,
            sortingBy: { item: "name", order: "" },
            hasUserOverview: false,
            hasImportUsersOverview: false,
            hasMessageOverview: false,
            hasNotificationMessage: false,
            notificationMessage: "",
            notificationMessageType: "",
            selectedUser: users[0]
        };

        this.updateSearch = this.updateSearch.bind(this);
        this.updateActions = this.updateActions.bind(this);
        this.updateCurrentPage = this.updateCurrentPage.bind(this);
        this.updateUsers = this.updateUsers.bind(this);
        this.updateMainChecked = this.updateMainChecked.bind(this);
        this.updateCheckedAll = this.updateCheckedAll.bind(this);
        this.showOverview = this.showOverview.bind(this);

        this.search = React.createRef();
        this.pagination = React.createRef();
        this.singleUser = React.createRef();
    }

    componentDidMount() {
        if (this.props.store.User.data.length > 0) {
            this.sortingBy("name", Tools.sortingInAlphabetic);
        }
    }

    updateActions(users) {
        this.search.current.resetSearch();
        let paginatedUsers = this.pagination.current.paginate(users, 1);
        this.setState({
            users: paginatedUsers, // Update the main table after searching and paginating
            paginatedUsers: users,
            defaultUsers: users, // Keep the default users as the sidebar return them
            currentPage: 1,
            isChecked: [],
            isMainChecked: false,
            isCheckedAll: false,
            sortingBy: { item: "", order: "" }
        }, () => {
            this.sortingBy("name", Tools.sortingInAlphabetic)
        });
    }

    updateUsers(users) {
        this.setState({
            users
        });
    }

    updateCurrentPage(currentPage, callback) {
        this.setState({
            currentPage
        }, () => {
            callback();
        });
    }

    updateSearch(users) {
        let paginatedUsers = this.pagination.current.paginate(users, 1);

        this.setState({
            users: paginatedUsers,
            paginatedUsers: users,
            currentPage: 1
        });
    }

    updateUsersIsChecked(user) {
        this.setState((state) => {
            let index = state.isChecked.findIndex((usr) => usr.id === user.id);

            if (index > -1) {
                state.isChecked.splice(index, 1);
            } else {
                state.isChecked.push(user);
            }

            return {
                isChecked: state.isChecked
            }
        });
    }

    updateMainChecked() {
        this.setState((state) => {
            if (!state.isMainChecked && state.isChecked.length <= 0) {
                state.paginatedUsers.forEach((user) => {
                    if (state.isChecked.find((usr) => usr.id === user.id) == null) {
                        state.isChecked.push(user);
                    }
                });
            } else {
                state.isChecked = [];
            }

            return {
                isMainChecked: !state.isMainChecked && state.isChecked.length > 0,
                isCheckedAll: false,
                isChecked: state.isChecked
            }
        });
    }

    updateCheckedAll() {
        this.setState((state) => {
            state.paginatedUsers.forEach((user) => {
                if (state.isChecked.find((usr) => usr.id === user.id) == null) {
                    state.isChecked.push(user);
                }
            });

            return {
                isCheckedAll: !state.isCheckedAll,
                isChecked: state.isChecked
            };
        })
    }

    sortingBy(item, sortingFunc, defaultSort = null) {
        let sortingIn = "asc";

        if (this.state.sortingBy.item === item && this.state.sortingBy.order === "asc") { // Already been sorted one time, now doing the reverse
            sortingIn = "desc";
        } else {
            sortingIn = "asc";
        }

        if (defaultSort != null) {
            sortingIn = this.state.sortingBy.order;
        }


        this.setState((state) => {
            state.paginatedUsers.sort((a, b) => {
                if (item === "group") {
                    a = this.props.store.Group.data.find((group) => group.id === a.group_ids[0]);
                    if (a != null) {
                        a = a.name;
                    } else {
                        a = "";
                    }

                    b = this.props.store.Group.data.find((group) => group.id === b.group_ids[0]);
                    if (b != null) {
                        b = b.name;
                    } else {
                        b = "";
                    }
                }

                return sortingFunc(a, b, item === "group" ? "" : item, sortingIn)
            });

            let paginatedUsers = this.pagination.current.paginate(state.paginatedUsers, 1);

            return {
                sortingBy: { item, order: sortingIn },
                users: paginatedUsers,
                paginatedUsers: state.paginatedUsers,
                isChecked: [],
                isMainChecked: false,
                isCheckedAll: false,
                currentPage: 1
            }
        });
    }

    showOverview(overviewName, shouldShow) {
        this.setState({
            [overviewName]: shouldShow,
        })
    }

    showResultNotification(message, type) {
        this.setState({
            hasNotificationMessage: true,
            notificationMessage: message,
            notificationMessageType: type
        })
    }

    hideNotificationIfNeeded() {
        if (this.state.hasNotificationMessage) {
            setTimeout(() => {
                this.setState({
                    hasNotificationMessage: false,
                    notificationMessage: "",
                })
            }, 5000)
        }
    }

    /**
     * =========================
     * Renders functions
     * =========================
     */
    render() {
        return (
            <Fragment>
                <Header
                    current={this.props.store.User.isGroupLeader ? URI.myGroup.users : URI.admin.manageUsers}
                    currentRoot={this.props.store.User.isGroupLeader ? URI.myGroup.root : URI.admin.root}
                />

                {this.props.store.User.isLoading || this.props.store.Group.isLoading ?
                    <Loader />
                    :
                    <Fragment>
                        <div className="manage-users content-container">
                            <ManageUsersSidebar
                                users={this.state.users}
                                updateUsers={this.updateActions}
                            />
                            <div className="content-main">
                                {this.state.hasNotificationMessage &&
                                    <div className={`result-notification ${this.state.notificationMessageType}`}>
                                        <p>{this.state.notificationMessage}</p>
                                    </div>
                                }

                                {this.hideNotificationIfNeeded()}

                                <ActionsTopbar
                                    ref={this.search}
                                    users={this.state.defaultUsers}
                                    filteredUsers={this.state.paginatedUsers}
                                    updateUsers={this.updateSearch}
                                    store={this.props.store}
                                    isChecked={this.state.isChecked}
                                    showMessageOverview={() => this.showOverview("hasMessageOverview", true)}
                                    addUser={() => {
                                        this.showOverview("hasUserOverview", true);
                                        this.singleUser.current.updateSelectedUser({
                                            email: "",
                                            group_ids: [],
                                            id: 0,
                                            name: "",
                                            phone: "",
                                            user_privacy: "public",
                                            userlevel: "user",
                                        });
                                    }}
                                    importUsers={() => {
                                        this.showOverview("hasImportUsersOverview", true)
                                    }}
                                />
                                {this.state.paginatedUsers.length === this.state.isChecked.length &&
                                    <div className="select-all-users">
                                        Du har valt alla användare.
                                    </div>
                                }
                                <table className="table-users">
                                    <thead>
                                        <tr>
                                            <th>
                                                <Checkbox
                                                    isMinus={!this.state.isMainChecked && this.state.isChecked.length > 0}
                                                    isChecked={this.state.isMainChecked}
                                                    onClick={this.updateMainChecked}
                                                />
                                            </th>
                                            <th>
                                                <TableHeadItem
                                                    onClick={() => this.sortingBy('name', Tools.sortingInAlphabetic)}
                                                    name="Namn"
                                                    showUp={this.state.sortingBy.item === 'name' && this.state.sortingBy.order === 'asc'}
                                                    showDown={this.state.sortingBy.item === 'name' && this.state.sortingBy.order === 'desc'}
                                                />
                                            </th>
                                            <th>
                                                <TableHeadItem
                                                    onClick={() => this.sortingBy('email', Tools.sortingInAlphabetic)}
                                                    name="Epost"
                                                    showUp={this.state.sortingBy.item === 'email' && this.state.sortingBy.order === 'asc'}
                                                    showDown={this.state.sortingBy.item === 'email' && this.state.sortingBy.order === 'desc'}
                                                />
                                            </th>
                                            <th>
                                                <TableHeadItem
                                                    onClick={() => this.sortingBy('userlevel', Tools.sortingInAlphabetic)}
                                                    name="Roll"
                                                    showUp={this.state.sortingBy.item === 'userlevel' && this.state.sortingBy.order === 'asc'}
                                                    showDown={this.state.sortingBy.item === 'userlevel' && this.state.sortingBy.order === 'desc'}
                                                />
                                            </th>
                                            <th>
                                                <TableHeadItem
                                                    onClick={() => this.sortingBy('group', Tools.sortingInAlphabetic)}
                                                    name="Grupp"
                                                    showUp={this.state.sortingBy.item === 'group' && this.state.sortingBy.order === 'asc'}
                                                    showDown={this.state.sortingBy.item === 'group' && this.state.sortingBy.order === 'desc'}
                                                />
                                            </th>
                                            <th>
                                                Åtgärd
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.users.map((user) =>
                                            <TableItem
                                                key={user.id}
                                                store={this.props.store}
                                                user={user}
                                                isChecked={this.state.isChecked.find((usr) => usr.id === user.id)}
                                                updateUsersIsChecked={() => this.updateUsersIsChecked(user)}
                                                onClickEdit={() => {
                                                    this.showOverview("hasUserOverview", true)
                                                    this.singleUser.current.updateSelectedUser(user)
                                                }}
                                            />
                                        )}
                                    </tbody>
                                </table>
                                <Pagination
                                    ref={this.pagination}
                                    updateItems={this.updateUsers}
                                    updateCurrentPage={this.updateCurrentPage}
                                    items={this.state.paginatedUsers}
                                    perPage={12}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </div>
                        <FormSingleUser
                            isActive={this.state.hasUserOverview}
                            onClickCancel={() => {
                                this.showOverview("hasUserOverview", false)
                            }}
                            user={this.state.selectedUser}
                            ref={this.singleUser}
                            store={this.props.store}
                        />
                        <FormImportUsers
                            isActive={this.state.hasImportUsersOverview}
                            onClickCancel = {() => {
                                this.showOverview('hasImportUsersOverview', false)
                            }}
                            store={this.props.store}
                        />
                        <FormSendMessage
                            isActive={this.state.hasMessageOverview}
                            onClickCancel={() => this.showOverview("hasMessageOverview", false)}
                            users={this.state.isChecked}
                            store={this.props.store}
                            showResultNotification={(message, type) => this.showResultNotification(message, type)}
                        />
                    </Fragment>
                }
            </Fragment>
        );
    }
}

export default Store.connectStore(AdminManageUsersNew);