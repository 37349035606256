import React from 'react';
import Store from 'eas-react-store';
import {Tools} from '../../tools/tools';

class UserTableRow extends React.Component {
	constructor(props) {
		super(props);

		this.group = this.props.store.Group.data.find((item) => item.id === this.props.user.group_ids[0]);

		this.state = {
			isEditMode: false,
			isChecked: false,
			userGroup: Tools.getInitialsGroup(this.group != null ? this.group.name : "")
		};
	}

	/**
	 * Handles the change of value of all the input fields
	 * @param {Event} evt The JS event
	 */
	handleInputChange(evt) {
		this.setState({
			[evt.target.name]: evt.target.value
		})
	}

	/**
	 * Handles the click on edit/cancel
	 */
	toggleEditMode() {
		this.setState({
			isEditMode: !this.state.isEditMode,
		})
	}

	/**
	 * Handles the row's checkbox
	 */
	handleCheckbox() {
		this.setState({
			isChecked: !this.state.isChecked
		})
	}

	/**
	 * Returns the class string for a given checkbox.
	 * It checks if the box is checked or not and dynamically add/removes
	 * the check symbol from the box.
	 */
	getCheckboxClassName() {
		let className = "custom-check-wrapper";
		className += this.state.isChecked ? " checked" : "";
		return className
	}

	/**
	 * Handles the click on the save button
	 */
	handleClickOnSave() {
		var newGroupId = this.state.userGroup;
		var oldGroupId = this.props.user.group_ids[0];

		this.props.store.User.removeUserFromGroup(oldGroupId, this.props.user);
		this.props.store.User.addUserToGroup(newGroupId, this.props.user.userlevel, newGroupId, this.props.user);
		this.setState({
			isEditMode: false
		})
	}

	deleteUser() {
		let conf = window.confirm("Är du säker på att du vill ta bort den här användare?");

		if (conf === true) {
			this.props.store.User.deleteUser(this.props.user.id);
			window.location.reload();
		}
	}

	// MARK: - Rendering methods

	renderUserStats (scope) {
		let render = [];

		if (this.props.user.statistics.categories != null) {
			render.push(<span key={scope} className="user-task total">{this.props.user.statistics.categories[scope].total}</span>);

			this.props.store.Category.data.map((category) => {
				render.push(
					<span
						key={category.id}
						className="user-task purple"
						style={{backgroundColor: `#${category.color}AA`, color: '#FFF'}}
						title={category.title}>
						{this.props.user.statistics.categories[scope][category.id] != null ? this.props.user.statistics.categories[scope][category.id] : '0'}
					</span>
				);
				return 0; // FIX: Warning, arrow function expected a return value
			});
		}

		return render;
	}

	renderSuperAdminActions() {
		if (this.props.isSuperAdmin) {
			return(
				<td>
					<button className="user-edit-btn" onClick={() => this.toggleEditMode()}>Redigera</button>
					<button className="remove-link" onClick={() => this.deleteUser()}>Ta bort</button>
					<div className="btn-wrapper">
						<button className="btn btn-table-small btn-cancel" onClick={() => this.toggleEditMode()}>Avbryt</button>
						<button className="btn btn-table-small btn-save" onClick={() => this.handleClickOnSave()}>Spara</button>
					</div>
				</td>
			)
		}
	}

	renderTeamCategory() {
		if (this.props.user.userlevel !== 'team_leader') {
			return;
		}

		let group = this.props.store.Group.data.find((group) => group.id === this.props.user.group_ids[0]);
		let category = null;

		group.categories.forEach((cat) => {
			let temp = cat.team_leaders.find((user) => user.id === this.props.user.id);

			if (temp != null) {
				category = cat;
			}
		});

		if (category != null) {
			return (
				<span className="category-title">{category.title}</span>
			);
		}
	}

	render() {
		let className = "user-table-row";

		className += this.state.isEditMode ? " mode-edit" : "";

		return(
			<tr className={className}>
				{/* <td>
					<label htmlFor="user-check" className={this.getCheckboxClassName()}>
						<span className="custom-check"></span>
						<input type="checkbox" name="user-check" id="user-check" onChange={(e) => this.handleCheckbox(e)}/>
					</label>
				</td> */}
				<td>{this.props.user.name}</td>
				<td>{this.props.user.email}</td>
				<td className={`${this.props.user.userlevel === 'team_leader' ? 'userlevel' : ''}`}>
					{Tools.makeBeautifulLabel(this.props.user.userlevel)}
					{this.renderTeamCategory()}
				</td>
				<td>
					<span>{this.group.name}</span>
					<input className="form-control user-group-input"
						type="number"
						name="userGroup"
						id="group"
						placeholder="0"
						value={this.state.userGroup}
						onChange={this.handleInputChange.bind(this)} />
				</td>
				<td>
					{this.renderUserStats('past')}
				</td>
				<td>
					{this.renderUserStats('future')}
				</td>
				{ this.renderSuperAdminActions() }
			</tr>
		);
	}
}

export default Store.connectStore(UserTableRow);