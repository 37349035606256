export default class external {
    constructor(baseUrl, timeout = 5000, ) {
        this.baseUrl = baseUrl;
        this.timeout = timeout;
        this.Request = Request;

        this.get = {
            'json': this._get_json.bind(this)
        };

        this.post = {
            'json': this._post_json.bind(this)
        };

        this.post_multipart = {
            'json': this._post_multipart_json.bind(this)
        };

        this.put = {
            'json': this._put_json.bind(this)
        };

        this.put_multipart = {
            'json': this._put_multipart_json.bind(this)
        };

        this.delete = {
            'json': this._delete_json.bind(this)
        }
    }

    static async static_get(path) {
        return await fetch(path, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            mode: 'cors',
            cache: 'no-cache',
            redirect: 'follow',
            credentials: 'include',
            referrer: "no-referrer"
        })
        .then((response) => response.json());
    }

    async _get_json(path, token = null) {
        return await fetch(this.baseUrl + path, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                mode: 'cors',
                cache: 'no-cache',
                redirect: 'follow',
                credentials: 'include',
                referrer: "no-referrer"
            })
            .then((response) => response.json());
    }

    async _post_json(path, content, token = null) {
        return await fetch(this.baseUrl + path, {
                method: 'POST',
                body: JSON.stringify(content),
                mode: 'cors',
                cache: 'no-cache',
                redirect: 'follow',
                credentials: 'include',
                referrer: "no-referrer",
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
            .then((response) => response.json());
    }

    async _post_multipart_json(path, content, token = null) {
        return await fetch(this.baseUrl + path, {
                method: 'POST',
                body: content,
                mode: 'cors',
                cache: 'no-cache',
                redirect: 'follow',
                credentials: 'include',
                referrer: "no-referrer"
            })
            .then((response) => response.json());
    }

    async _put_json(path, content, token = null) {
        return await fetch(this.baseUrl + path, {
                method: 'PUT',
                body: JSON.stringify(content),
                mode: 'cors',
                cache: 'no-cache',
                redirect: 'follow',
                credentials: 'include',
                referrer: "no-referrer",
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
            .then((response) => response.json());
    }

    async _put_multipart_json(path, content, token = null) {
        return await fetch(this.baseUrl + path, {
                method: 'PUT',
                body: content,
                mode: 'cors',
                cache: 'no-cache',
                redirect: 'follow',
                credentials: 'include',
                referrer: "no-referrer"
            })
            .then((response) => response.json());
    }

    async _delete_json(path, content = null, token = null) {
        return await fetch(this.baseUrl + path, {
                method: 'DELETE',
                mode: 'cors',
                body: JSON.stringify(content),
                cache: 'no-cache',
                redirect: 'follow',
                credentials: 'include',
                referrer: "no-referrer",
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
            .then((response) => response.json());
    }
}