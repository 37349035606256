import React, { Component } from 'react';
import Store from 'eas-react-store';
import {Tools} from '../../../tools/tools'
import Dropdown from '../../../components/svg/dropdown';

class ManageUsersSidebar extends Component {
    constructor (props) {
        super(props);

        this.filterNames = {
            userRoles: 'user_roles',
            userGroups: 'user_groups',
            userInactives: 'user_inactives'
        };

        let userRoles = [{isActive: true, name: 'Alla', id: -1}];
        userRoles.push(...this.props.store.User.data.userlevel.map((level) => {
            return {isActive: false, name: level == 'group_leader' ? 'Service Grupp Ledare' : level, id: level};
        }));

        if (this.props.store.User.isGroupLeader) {
            userRoles = userRoles.filter((userRole) => userRole.id !== "admin" && userRole.id !== "super_admin");
        }

        let userGroups = [{isActive: true, name: 'Alla', id: -1}];
        userGroups.push(...this.props.store.Group.data.sort((a, b) => {
                let a_name = Tools.prepareGroupNameForSorting(a.name)
                let b_name = Tools.prepareGroupNameForSorting(b.name)

                if (a_name > b_name) return 1;
                if (a_name < b_name) return -1;
                return 0;
            }).map((group) => {
                return {isActive: false, name: group.name, id: group.id};
            })
        );

        this.defaultUsers = this.props.store.User.data.users;
        this.inactiveUsersCount = this.defaultUsers.filter((user) => user != null && user.group_ids != null && user.group_ids.length === 0).length;

        this.state = {
            dropdownOpen: [this.filterNames.userRoles, this.filterNames.userGroups],
            userRoles,
            userGroups,
            filters: [],
            userInactivesIsActive: false
        };

        this.isDropdownOpen = this.isDropdownOpen.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.addFilter = this.addFilter.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    /**
     * ========================
     * Dropdown sidebar actions
     * ========================
     */
    isDropdownOpen (name) {
        return this.state.dropdownOpen.includes(name);
    }

    openMenu (name) {
        this.setState((state) => {
            if (this.isDropdownOpen(name)) {
                state.dropdownOpen.splice(state.dropdownOpen.indexOf(name), 1);
            } else {
                state.dropdownOpen.push(name);
            }

            return {
                dropdownOpen: state.dropdownOpen
            };
        });
    }

    /**
     * =================
     * Filters functions
     * =================
     */
    addIsActive (array, id) {
        array.find((item) => item.id === id).isActive = true;
    }

    removeIsActive (array) {
        let hasActive = array.find((item) => item.isActive === true);
        if (hasActive != null) array.find((item) => item.isActive === true).isActive = false;
    }

    removeFilter (filters, name) {
        let indexToDelete = filters.findIndex((item) => item.type === name);
        if (indexToDelete != -1) filters.splice(indexToDelete, 1); // delete filter old filter
    }

    addFilter (name, id) {
        this.setState((state) => {
            this.removeFilter(state.filters, name);
            state.filters.push({type: name, id});

            if (name === this.filterNames.userRoles) {
                this.removeIsActive(state.userRoles);
                this.addIsActive(state.userRoles, id);

                if (state.userInactivesIsActive) this.addIsActive(state.userGroups, -1);

                state.userInactivesIsActive = false;
                this.removeFilter(state.filters, this.filterNames.userInactives);
            } else if (name === this.filterNames.userGroups) {
                this.removeIsActive(state.userGroups);
                this.addIsActive(state.userGroups, id);

                if (state.userInactivesIsActive) this.addIsActive(state.userRoles, -1);

                state.userInactivesIsActive = false;
                this.removeFilter(state.filters, this.filterNames.userInactives);
            } else if (name === this.filterNames.userInactives) {
                this.removeFilter(state.filters, this.filterNames.userGroups);
                this.removeFilter(state.filters, this.filterNames.userRoles);
                this.removeIsActive(state.userGroups);
                this.removeIsActive(state.userRoles);

                state.userInactivesIsActive = true;
            }

            let filterRole = state.filters.find((item) => item.type === this.filterNames.userRoles);
            let filterGroup = state.filters.find((item) => item.type === this.filterNames.userGroups);
            let filterInactives = state.filters.find((item) => item.type === this.filterNames.userInactives);

            // If both filters are on 'Alla' then main 'Alla' should be activated
            if (
                filterInactives == null &&
                (filterRole == null &&
                filterGroup == null)
                ||
                (filterRole == null &&
                filterGroup != null && filterGroup.id === -1)
                ||
                (filterRole != null && filterRole.id === -1 &&
                filterGroup == null)
                ||
                (filterRole != null && filterRole.id === -1 &&
                filterGroup != null && filterGroup.id === -1)
            ) {
                state.filters = [];
                this.props.updateUsers(this.defaultUsers);
            } else {
                this.props.updateUsers(this.filterUsers(state.filters));
            }

            return {
                ...state,
                filters: state.filters,
                userRoles: state.userRoles,
                userGroups: state.userGroups,
                userInactivesIsActive: state.userInactivesIsActive
            };
        });
    }

    filterUsers (filters) {
        return this.defaultUsers.filter((user) => {
            if (user == null) {
                return;
            }

            let shouldReturnUser = true;

            filters.forEach((filter) => {
                if (filter.type === this.filterNames.userRoles && filter.id !== -1) {
                    if (user.userlevel !== filter.id) {
                        shouldReturnUser = false;
                    }
                }

                if (filter.type === this.filterNames.userGroups && filter.id !== -1) {
                    if (user.group_ids[0] !== filter.id) {
                        shouldReturnUser = false;
                    }
                }

                if (filter.type === this.filterNames.userInactives) {
                    if (user.group_ids.length > 0) {
                        shouldReturnUser = false;
                    }
                }
            });

            return shouldReturnUser;
        });
    }

    // Clicking on "Alla" mean putting everything to default
    resetFilters () {
        this.setState((state) => {
            this.removeIsActive(state.userRoles);
            this.addIsActive(state.userRoles, -1);

            this.removeIsActive(state.userGroups);
            this.addIsActive(state.userGroups, -1);

            this.props.updateUsers(this.props.store.User.data.users);

            return {
                ...state,
                filters: [],
                userRoles: state.userRoles,
                userGroups: state.userGroups,
                userInactivesIsActive: false
            };
        });
    }

    /**
     * =========================
     * Renders functions
     * =========================
     */
    render () {
        return (
            <div className="sidebar">
                <div
                    onClick={this.resetFilters}
                    className={`category ${this.state.filters.length <= 0 && ! this.state.userInactivesIsActive ? 'active' : ''}`}
                >
                    Alla
                </div>
                {! this.props.store.User.isGroupLeader &&
                    <div
                        onClick={() => this.addFilter(this.filterNames.userInactives, 0)}
                        className={`category ${this.state.userInactivesIsActive ? 'active' : ''}`}
                    >
                        <span>Endast inaktiva</span>
                        <span className="inactive-users">{this.inactiveUsersCount}</span>
                    </div>
                }
                <Dropdown
                    name={this.filterNames.userRoles}
                    onClickFilter={this.addFilter}
                    onClick={this.openMenu}
                    isOpen={this.isDropdownOpen}
                    title="User Roles"
                    items={this.state.userRoles}
                />

                {! this.props.store.User.isGroupLeader &&
                    <Dropdown
                        name={this.filterNames.userGroups}
                        onClickFilter={this.addFilter}
                        onClick={this.openMenu}
                        isOpen={this.isDropdownOpen}
                        title="User Groups"
                        items={this.state.userGroups}
                    />
                }
            </div>
        );
    }
}

export default Store.connectStore(ManageUsersSidebar);