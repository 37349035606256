import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Main from './Main.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Task from './app/controllers/controller.task';
import Category from './app/controllers/controller.category';
import Schedule from './app/controllers/controller.schedule';
import User from './app/controllers/controller.user';
import Statistics from './app/controllers/controller.statistics';
import Messages from './app/controllers/controller.messages';
import Store from 'eas-react-store';
import Group from './app/controllers/controller.group';
import Organisation from './app/controllers/controller.organisation';
import './stylesheets/main.scss';
import MainNotConnected from './MainNotConnected';
import LoadingView from './views/LoadingView';

async function startApp() {
    ReactDOM.render(<LoadingView />, document.getElementById('root'));

    let validation = await User.validateSession(process.env.REACT_APP_API_PATH);

    if (validation.success && validation.user.userlevel !== "user") {
        Store.createStore([Task, Schedule, User, Statistics, Category, Messages, Group, Organisation], {link: process.env.REACT_APP_API_LINK, path: process.env.REACT_APP_API_PATH}, "v0.440");
        ReactDOM.render(<Main />, document.getElementById('root'));
    } else {
        Store.createStore([User, Organisation], {link: process.env.REACT_APP_API_LINK, path: process.env.REACT_APP_API_PATH}, "v0.45");
        ReactDOM.render(<MainNotConnected />, document.getElementById('root'));
    }
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}

startApp();
