import React from 'react';
import Store from 'eas-react-store';
import SingleSelectUserItem from './SingleSelectUserItem';
import {Tools} from '../../tools/tools';

class SelectUserModal extends React.Component {

	constructor(props) {
		super(props);

		let data = this.props.store.User.data.users;

		this.taskId = this.props.taskId;

		this.state = {
			mode: this.props.mode,
			chosenUsers: [],
			allUsersList: data,
			filteredUsersList: data,
			usersByCategory: [],
			currentSidebarFilter: 0, // Use 0 for all, the ID of the group otherwise
			searchFieldValue: "",
			descriptionFieldValue: "",
		}
	}

	componentDidMount () {
			this.handleGroupFilterChange(0); // Show everyone but not the users that don't have group
	}

	// MARK: - Logic methods

	getUsersMatching(pattern) {
		var returnArr = [];
		returnArr = this.state.usersByCategory.filter(function (usr) {
			return usr.name.toLowerCase().indexOf(pattern.toLowerCase()) !== -1
		})

		return returnArr;
	}

	getFilterClassNameForGroupId(id) {
		var className = "filter";
		className += this.state.currentSidebarFilter === id ? " current" : "";

		return className;
	}

	// MARK: - Handling input changes

	/**
	 * Handles the change of value of all the input fields
	 * @param {Event} evt The JS event
	 */
	handleInputChange(evt) {
		this.setState({
			[evt.target.name]: evt.target.value
		})
	}

	/**
	 * Handles the change of value of the search input field
	 * @param {Event} evt The JS event
	 */
	handleSearchInputChange(evt) {
		this.setState({
			searchFieldValue: evt.target.value
		});

		this.searchUsers(evt.target.value);
	}

	searchUsers(query) {
		if (query !== "") {
			this.setState({
				filteredUsersList: this.getUsersMatching(query)
			});
		} else {
			this.setState({
				filteredUsersList: this.state.usersByCategory
			});
		}
	}

	async handleGroupFilterChange(id) {
		let filteredUsersList = [];

		if (id === 0) {
			this.state.allUsersList.forEach((user) => {
				if (user.group_ids.length > 0) {
					filteredUsersList.push(user);
				}
			})
		} else {
			this.state.allUsersList.forEach((user) => {
				if (user.group_ids[0] === id) {
					filteredUsersList.push(user);
				}
			});
		}

		this.setState({
			currentSidebarFilter: id,
			usersByCategory: filteredUsersList
		}, () => this.searchUsers(this.state.searchFieldValue));
	}

	handleInactiveUsers(id) {
		let inactiveUsers = [];

		this.state.allUsersList.forEach((user) => {
			if (user.group_ids.length <= 0) {
				inactiveUsers.push(user);
			}
		});

		this.setState({
			currentSidebarFilter: id,
			usersByCategory: inactiveUsers
		}, () => this.searchUsers(this.state.searchFieldValue));
	}

	handleUserCheckbox(user, isChecked) {
		var chosenUsers = this.state.chosenUsers
		if (isChecked) {
			chosenUsers.push(user)
		} else {
			// Pop the user out of the array
			var idx = chosenUsers.indexOf(user)
			if (idx > -1) {
				chosenUsers.splice(idx, 1)
			}
		}

		this.setState({
			chosenUsers: chosenUsers
		})
	}

	/**
	 * Handles the click on the send recommendation event.
	 * We send the request only if the description is not empty
	 * and if at least one user is chosen as recipient.
	 */
	handleClickOnSend() {
		const description = this.state.descriptionFieldValue;
		const chosenUsersIds = this.state.chosenUsers.map(usr => usr.id);

		if (description !== "" && chosenUsersIds.length > 0) {
			var message = {
				"title": "Uppgiftsrekommendation",
				"body": description,
				"recipients": chosenUsersIds,
				"task_id": this.taskId
			};

			this.props.store.Messages.createMessage(message);

			this.props.onClickOnAbort();
		}
	}

	// MARK: - Rendering methods

	renderGroupsFilters() {
		let returnArray = [];

		returnArray.push(
			<li
				className={this.getFilterClassNameForGroupId(0)}
				key="0"
				onClick={() => this.handleGroupFilterChange(0)}>
					<span>Alla användare</span>
			</li>
		)

		if (this.props.store.Group.data != null) {
			this.props.store.Group.data.forEach((group) => {
				returnArray.push(
					<li
						className={this.getFilterClassNameForGroupId(group.id)}
						key={group.id}
						onClick={() => this.handleGroupFilterChange(group.id)}>
						<span>{group.name.charAt(0).toUpperCase() + group.name.slice(1)}</span>
					</li>
				)
			});
		}

		return returnArray;
	}

	renderUsersList() {
		if (this.state.filteredUsersList.length > 0) {
			return this.state.filteredUsersList.map(usr => {
				// Handle the check/uncheck status of the user
				var isChecked = false;

				// If user is in chosenUsers put checked to true
				if (this.state.chosenUsers.find((chosenUser) => chosenUser.id === usr.id) != null) {
					isChecked = true;
				}

				// Handle the group of the user
				let userGroup = -1;
				if (this.state.currentSidebarFilter === 0) {
					if (usr.group_ids.length > 0) {
						userGroup = usr.group_ids[0];
					}
				} else {
					userGroup = this.state.currentSidebarFilter
				}

				return (
					<SingleSelectUserItem
						user={usr}
						userGroup={userGroup}
						mode={this.state.mode}
						isChecked={isChecked}
						handleUserCheckbox={this.handleUserCheckbox.bind(this)}
						key={usr.id}
					/>
				)
			})
		}
	}

	renderInput() {
		let returnArray = [];

		returnArray = (
			<div>
				<label htmlFor="task-description">Beskrivning</label>
				<textarea
					className="form-control modal-textarea"
					name="descriptionFieldValue"
					placeholder="Skriv här ..."
					value={this.state.descriptionFieldValue}
					onChange={this.handleInputChange.bind(this)}></textarea>
			</div>
		)

		return returnArray;
	}

	renderChosenUsersList() {
		return this.state.chosenUsers.map(usr => {
			var initials = Tools.getInitials(usr.name);
			return(
				<li className="user" key={usr.id}>
					<div className="user-image">
						<span>{initials}</span>
					</div>
					<span className="user-name">{usr.name}</span>
				</li>
			)
		})
	}

	render() {
		return (
			<div className="select-user-modal">
				<div className="modal-container">
					<div className="w-100 modal-head">
						<h2>Rekommendera</h2>
					</div>
					<div className="row">

						<div className="col-8">
							<div className="modal-col-wrapper">
								<div className="w-100 modal-search">
									<input
										className="form-control modal-search-input"
										type="text"
										name="searchFieldValue"
										value={this.state.searchFieldValue}
										onChange={this.handleSearchInputChange.bind(this)}
										placeholder="Sök ..." />
								</div>
								<div className="container-fluid">
									<div className="row">
										<div className="col-4 modal-user-filter">
											<ul className="filter-list">
												{this.renderGroupsFilters()}
											</ul>
										</div>
										<div className="col-8 modal-user-list">
											<ul className="user-list">
												{this.renderUsersList()}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-4">
							<div className="modal-selected-user">
								<h3>Valda användare</h3>
								<ul className="w-100 user-list">
									{this.renderChosenUsersList()}
								</ul>
								{/* <div className="w-100 modal-description mt-auto"> */}
								<div className="w-100 modal-description">
									{this.renderInput()}
								</div>
							</div>
						</div>

					</div>

					<div className="w-100 modal-footer">
						<button
							className="footer-btn cancel"
							onClick={this.props.onClickOnAbort}>Avbryt</button>
						<button id="send-recommendation"
							className="footer-btn save"
							onClick={this.handleClickOnSend.bind(this)}>
								Skicka rekommmendation
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default Store.connectStore(SelectUserModal);