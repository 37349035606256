import BaseController from './controller.base';
import ApiOrganisation from '../api/api.organisation';

export default class Organisation extends BaseController {
    static controllerName = 'Organisation';

    constructor(updateStore, config, localStorage) {
        super(updateStore);

        this.Api = {
            organisation: new ApiOrganisation(config.path),
        };

        this.getOrganisation = this.getOrganisation.bind(this);
        this.updateStore();
    }

    async getOrganisation(email) {
        let organisation = await this.Api.organisation.getOrganisation({email});

        return organisation;
    }
}