import React from 'react';

class FormImportUsers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFile: null,
            uploadStatus: '',
        };

        this.organisationId = this.props.store.User.data.organisation.id;

        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleFileChange(event) {
        this.setState({ selectedFile: event.target.files[0] });
    }

    async handleSubmit(event) {
        event.preventDefault();

        const { selectedFile } = this.state;

        if (!selectedFile) {
            this.setState({ uploadStatus: 'Please select a file.' });
            return;
        }

        console.log('ORGANISATION ID', this.organisationId)

        const formData = new FormData();
        formData.append('organisation_id', this.organisationId);
        formData.append('file', selectedFile);

        await this.props.store.User.importUsers(formData)

        this.props.store.Group.getGroups(false)
        this.props.store.User.fetchAllUsers(false)

        this.props.onClickCancel()
    }

    render() {
        return (
            <div className={`slide-overview ${this.props.isActive ? 'active' : ''}`}>
                <h3>Import Users via CSV</h3>
                <form onSubmit={this.handleSubmit}>
                    <section>
                        <div>
                            <label htmlFor="file">Select CSV file:</label>
                            <input
                                type="file"
                                id="file"
                                accept=".csv"
                                onChange={this.handleFileChange}
                                className="form-control edit-input h-25"
                                required
                            />
                        </div>
                    </section>
                    <div className="slide-overview-controls">
                        <button className="btn btn-cancel" onClick={() => {
                            this.state.failedSubmit = false
                            this.props.onClickCancel()
                        }}>Avbryt
                        </button>
                        <button type="submit" className="btn btn-save">Upload</button>
                    </div>
                </form>
                {this.state.uploadStatus && <p className="warning-message">{this.state.uploadStatus}</p>}
            </div>
        );
    }
}

export default FormImportUsers;