import external from '../../tools/base.external';

export default class ApiTask extends external {

    async getTask(id, token) {
        let task = await this.get.json(`tasks/${id}`, token);
        return task;
    }

    async putTask(id, data, token) {
        let task = await this.put.json(`tasks/${id}`, data, token);
        return task;
    }

    async postTask(data, token) {
        let task = await this.post.json(`tasks`, data, token);
        return task;
    }

    async assignTaskToUser(task_id, user_id, token) {
        let response = await this.post.json(`tasks/${task_id}/accept/${user_id}`, {}, token);
        return response;
    }

    async deleteTaskFromUser(task_id, user_id, token) {
        let response = await this.delete.json(`tasks/${task_id}/accept/${user_id}`, {}, token);
        return response;
    }
    async deleteTask(id, token) {
        let data = {};
        let res = await this.delete.json(`tasks/${id}`, data, token);
        return res;
    }

    async getTasks(token) {
        let tasks = await this.get.json('tasks', token);
        return tasks;
    }

    async getTasksByWeek(week) {
        let tasks = await this.get.json('tasks/week/' + week);

        return tasks;
    }

    async getCategories(token) {
        let categories = await this.get.json('categories', token);
        return categories;
    }

    async getLocations(token) {
        let locations = await this.get.json('locations', token);
        return locations;
    }

    async postLocation(location, token) {
        let res = await this.post.json(`locations`, location, token);
        return res;
    }

    async deleteLocation(id, token) {
        console.log("Test12");
        let res = await this.delete.json(`locations/${id}`, {}, token);
        console.log(res);
        return res;
    }



}