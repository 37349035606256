import React, { Fragment } from "react";
import moment from "moment";
import { SpotPill } from "../../task-card/SpotPill";

export class PrintSingleEvent extends React.Component {
    renderTaskTime() {
        let startTime = moment.utc(this.props.task.start_date).format("HH:mm");
        let endTime = moment.utc(this.props.task.end_date).format("HH:mm");

        return `${startTime} -> ${endTime}`;
    }

    renderCategoryPill() {
        const style = {
            backgroundColor: "#" + this.props.task.category.color,
        };
        return <span className="category-pill vertical" style={style}></span>;
    }

    renderNames() {
        return this.props.task.accepted_by
            .map(user => user.name)
            .join(", ") + "."
    }

    renderVolunteers() {
        let returnArr = []
        if (this.props.task.accepted_by.length > 0) {
            returnArr.push(<div className="caption small">Bokade</div>)
            returnArr.push(<p>{this.renderNames()}</p>)
        }
        return returnArr
    }

    renderTaskDescription() {
        let returnArr = [];
        if (this.props.task.description !== "") {
            const description = this.props.task.description.split(/^/gm);
         
            returnArr.push(
                <Fragment>
                    <div className="caption small">Beskrivning</div>
                    {description.map((el, i) => {
                        return <p className="print-description" key={"PRINT_DESCRIPTION_" + i}>{el}</p>
                    })
                    }
                </Fragment>
            );
        }

        return returnArr;
    }

    render() {
        return (
            <div className="event">
                <div className="event-header">
                    {this.renderCategoryPill()}
                    <span className="event-title">
                        {this.renderTaskTime()}
                    </span>
                    <SpotPill
                        available_spots={this.props.task.available_spots}
                        takenSpots={this.props.task.taken_spots}
                        waitingList={this.props.task.waiting_list}
                        isCardSelected={false}
                    />
                    <h3 className="event-title">
                        {this.props.task.title}
                    </h3>
                </div>
                <div className="event-details">
                    {this.props.showNames && this.renderVolunteers()}
                    {this.props.showNotes && this.renderTaskDescription()}
                </div>
            </div>
        );
    }
}
