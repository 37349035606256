import React, { Fragment } from 'react';
import UserTableRow from './UserTableRow';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import UserTableArrows from './UserTableArrows';
import SelectAddUsersModal from '../select-user-modal/SelectAddUsersModal';
import Store from 'eas-react-store';
import Loader from '../svg/loader';
import { Tools } from '../../tools/tools';

class UserTable extends React.Component {
    constructor(props) {
        super(props);

        let data = props.users.filter(user => user.group_ids.length > 0);

        this.state = {
            allUsersList: data,
            filteredUsersList: data,
            searchFieldValue: "",
            sortingBy: {item: "", order: "asc"},
            showModal: false,
            currentPage: 1
        };

        // Pagination settings
        this.perPage = 10;

        this.sortingBy = this.sortingBy.bind(this);
        this.addUsers = this.addUsers.bind(this);
        this.hideAddUsers = this.hideAddUsers.bind(this);
    }

    componentDidMount () {
        this.sortingBy('name', this.sortingInAlphabetic);
    }

    // MARK: - Logic methods

    getUsersMatching(pattern) {
        var returnArr = [];
        returnArr = this.state.allUsersList.filter((usr) => {
            let userlevel = Tools.makeBeautifulLabel(usr.userlevel);
		    let group = this.props.store.Group.data.find((item) => item.id === usr.group_ids[0]);

            return usr.name.toLowerCase().indexOf(pattern.toLowerCase()) !== -1 ||
                   usr.email.toLowerCase().indexOf(pattern.toLowerCase()) !== -1 ||
                   group.name.toLowerCase().indexOf(pattern.toLowerCase()) !== -1 ||
                   userlevel.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
        });

        return returnArr;
    }

    // MARK: - Handling input

    /**
     * Handles the change of value of the search input fields
     * @param {Event} evt The JS event
     */
    handleSearchInputChange(evt) {
        this.setState({
            searchFieldValue: evt.target.value
        });

        this.goToPage(1);

        let doItAfterStateIsUpdated = () => {
            this.sortingBy(
                this.state.sortingBy.item,
                (this.state.sortingBy.item === 'name' || this.state.sortingBy.item === 'email') ? this.sortingInAlphabetic : this.sortingInNumber,
                this.state.sortingBy.order
            )
        };

        if (evt.target.value !== "") {
            this.setState({
                filteredUsersList: this.getUsersMatching(evt.target.value)
            }, doItAfterStateIsUpdated);
        } else {
            this.setState({
                filteredUsersList: this.state.allUsersList
            }, doItAfterStateIsUpdated);
        }
    }

    sortingBy(item, sortingFunc, defaultSort = null) {
        let sortingIn = "asc";

        if (this.state.sortingBy.item === item && this.state.sortingBy.order === "asc") { // Already been sorted one time, now doing the reverse
            sortingIn = "desc";
        } else {
            sortingIn = "asc";
        }

        if (defaultSort != null) {
            sortingIn = this.state.sortingBy.order;
        }

        let filterUsers = this.state.filteredUsersList.sort((a, b) => sortingFunc(a, b, item, sortingIn));

        this.setState({
            sortingBy: {item, order: sortingIn},
            filteredUsersList: filterUsers
        });
    }

    sortingInAlphabetic(a, b, item, order = "asc") {
        const wordA = a[item].toLowerCase();
        const wordB = b[item].toLowerCase();

        if (wordA < wordB) {
            if (order === "asc") {
                return -1;
            }

            return 1;
        }

        if (wordA > wordB) {
            if (order === "asc") {
                return 1;
            }

            return -1;
        }

        return 0;
    }

    sortingInNumber(a, b, item, order = "asc") {
        let numberA = a[item];
        let numberB = b[item];

        if (item === 'group') {
            numberA = a.group_ids[0];
            numberB = b.group_ids[0];
        } else if (item === 'task_future') {
            numberA = a.statistics.categories.future.total;
            numberB = b.statistics.categories.future.total;
        } else if (item === 'task_past') {
            numberA = a.statistics.categories.past.total;
            numberB = b.statistics.categories.past.total;
        }

        if (numberA < numberB) {
            if (order === "asc") {
                return -1;
            }

            return 1;
        }

        if (numberA > numberB) {
            if (order === "asc") {
                return 1;
            }

            return -1;
        }

        return 0;
    }


    // MARK: - Rendering methods

    renderActionSelector() {
        return <Dropdown placeholder="Åtgärd" />;
    }

    renderTable() {
        const paginate = (array, page_size, page_number) => {
            --page_number;
            return array.slice(page_number * page_size, (page_number + 1) * page_size);
        }

        let users = [...this.state.filteredUsersList];
        users = paginate(users, this.perPage, this.state.currentPage);

        return users.map(user =>
            <UserTableRow
                user={user}
                key={user.id}
                isSuperAdmin={this.props.isSuperAdmin}
            />
        );
    }

    renderPagination() {
        const renderItem = (index, label = '') => {
            return (
                <li key={index + label}
                    onClick={() => this.goToPage(index)}
                    className={`page-item ${this.state.currentPage === index ? 'active' : ''}`}
                >
                    <span className="page-link">{label != '' ? label : index}</span>
                </li>
            );
        };

        let result = [];
        let nbOfPages = Math.ceil(this.state.filteredUsersList.length / this.perPage);

        result.push(
            <li
                key="previous"
                onClick={() => this.previousPage()}
                className={`page-item ${this.state.currentPage === 1 ? 'disabled' : ''}`}
            >
                <span className="page-link">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Tidigare</span>
                </span>
            </li>
        );

        // You are the beginning of the pagination or at the end
        if (this.state.currentPage < 5 || this.state.currentPage > nbOfPages - 4) {
            for (let index = 1; index <= nbOfPages && index < 6; index++) {
                result.push(renderItem(index));
            }

            if (nbOfPages > 5) {
                // Don't put '...' if the number of pages is 10 or less
                if (nbOfPages > 10) {
                    result.push(
                        renderItem(this.state.currentPage < 5 ? this.state.currentPage + 5 : this.state.currentPage - 5, '...')
                    );

                    for (let index = nbOfPages - 4; index <= nbOfPages; index++) {
                        result.push(renderItem(index));
                    }
                } else {
                    for (let index = 6; index <= nbOfPages; index++) {
                        result.push(renderItem(index));
                    }
                }
            }
        } else if (this.state.currentPage < nbOfPages - 3) {
            result.push(renderItem(1));
            result.push(renderItem(this.state.currentPage - 3, '...'));

            // Render the closest element of the current page
            for (let index = this.state.currentPage - 2; index <= this.state.currentPage + 2 && index <= nbOfPages; index++) {
                result.push(renderItem(index));
            }

            result.push(renderItem(this.state.currentPage + 3, '...'));
            result.push(renderItem(nbOfPages));
        }

        result.push(
            <li
                key="next"
                onClick={() => this.nextPage()}
                className={`page-item ${this.state.currentPage === nbOfPages ? 'disabled' : ''}`}
            >
                <span className="page-link">
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Nästa</span>
                </span>
            </li>
        );

        return (
            <div className="pagination-container">
                <ul className="pagination">
                    {result}
                </ul>
            </div>
        );
    }

    goToPage(index) {
        this.setState((state) => ({
            ...state,
            currentPage: index
        }));
    }

    previousPage() {
        if (this.state.currentPage > 1) {
            this.setState((state) => ({
                ...state,
                currentPage: state.currentPage - 1
            }));
        }
    }

    nextPage() {
        if (this.state.currentPage < (this.state.filteredUsersList.length / 10)) {
            this.setState((state) => ({
                ...state,
                currentPage: state.currentPage + 1
            }));
        }
    }

    renderSuperAdminActions() {
        if (this.props.isSuperAdmin) {
            return <th>Åtgärd</th>;
        }
    }

    // Events
    addUsers() {
        this.setState({
            showModal: true
        });
    }

    hideAddUsers() {
        this.setState({
            showModal: false
        });
    }

    render() {
        if (this.props.store.User.isLoading) {
            return(
                <Loader />);
        }

        return (
            <Fragment>
                <div className="user-table-header">

                    <input
                        className="form-control user-table-search"
                        type="text"
                        placeholder="Sök person, email, role eller grupp"
                        value={this.state.searchFieldValue}
                        onChange={this.handleSearchInputChange.bind(this)}
                    />

                    {(this.props.store.User.data.connectedUser.userlevel === "admin"  || this.props.store.User.data.connectedUser.userlevel === "super_admin") &&
                        <button onClick={this.addUsers} className="btn btn-primary btn-table add-user">
                            Lägg till användare
                        </button>
                    }

                    {this.state.showModal &&
                        <SelectAddUsersModal onClickOnAbort={this.hideAddUsers} />
                    }
                </div>

                <div className="user-table-container">
                    <table className="table user-table">
                        <thead>
                            <tr>
                                <th onClick={() => this.sortingBy('name', this.sortingInAlphabetic)}>
                                    <span>Namn</span>
                                    <UserTableArrows
                                        showUp={this.state.sortingBy.item === 'name' && this.state.sortingBy.order === 'asc'}
                                        showDown={this.state.sortingBy.item === 'name' && this.state.sortingBy.order === 'desc'}
                                    />
                                </th>
                                <th onClick={() => this.sortingBy('email', this.sortingInAlphabetic)}>
                                    <span>Email</span>
                                    <UserTableArrows
                                        showUp={this.state.sortingBy.item === 'email' && this.state.sortingBy.order === 'asc'}
                                        showDown={this.state.sortingBy.item === 'email' && this.state.sortingBy.order === 'desc'}
                                    />
                                </th>
                                <th onClick={() => this.sortingBy('userlevel', this.sortingInAlphabetic)}>
                                    <span>Role</span>
                                    <UserTableArrows
                                        showUp={this.state.sortingBy.item === 'userlevel' && this.state.sortingBy.order === 'asc'}
                                        showDown={this.state.sortingBy.item === 'userlevel' && this.state.sortingBy.order === 'desc'}
                                    />
                                </th>
                                <th onClick={() => this.sortingBy('group', this.sortingInNumber)}>
                                    <span>Grupp</span>
                                    <UserTableArrows
                                        showUp={this.state.sortingBy.item === 'group' && this.state.sortingBy.order === 'asc'}
                                        showDown={this.state.sortingBy.item === 'group' && this.state.sortingBy.order === 'desc'}
                                    />
                                </th>
                                <th onClick={() => this.sortingBy('task_past', this.sortingInNumber)}>
                                    <span>Genomförda</span>
                                    <UserTableArrows
                                        showUp={this.state.sortingBy.item === 'task_past' && this.state.sortingBy.order === 'asc'}
                                        showDown={this.state.sortingBy.item === 'task_past' && this.state.sortingBy.order === 'desc'}
                                    />
                                </th>
                                <th onClick={() => this.sortingBy('task_future', this.sortingInNumber)}>
                                    <span>Planerade</span>
                                    <UserTableArrows
                                        showUp={this.state.sortingBy.item === 'task_future' && this.state.sortingBy.order === 'asc'}
                                        showDown={this.state.sortingBy.item === 'task_future' && this.state.sortingBy.order === 'desc'}
                                    />
                                </th>
                                { this.renderSuperAdminActions() }
                            </tr>
                        </thead>
                        <tbody>{this.renderTable()}</tbody>
                    </table>
                    {this.renderPagination()}
                </div>
            </Fragment>
        );
    }
}

export default Store.connectStore(UserTable);