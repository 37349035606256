import React, { Fragment } from 'react';
import IconArrow from './IconArrow';

export default function TableHeadItem ({ name, showUp, showDown, onClick }) {
    return (
        <div onClick={onClick} className="table-title">
            <span>{name}</span>
            <div>
                {showUp &&
                    <IconArrow className="up active" />
                }

                {showDown &&
                    <IconArrow className="down active" />
                }

                {! showUp && ! showDown &&
                    <Fragment>
                        <IconArrow className="up" />
                        <IconArrow className="down" />
                    </Fragment>
                }
            </div>
        </div>
    );
}