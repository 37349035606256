import React from 'react';
import Chevron from './chevron';
import { Tools } from '../../tools/tools';

export default function Dropdown (props) {
    const isOpen = props.isOpen(props.name);

    return (
        <div className="dropdown">
            <div onClick={() => props.onClick(props.name)} className="title">
                <span>{props.title}</span>
                <Chevron className={! isOpen ? 'reverse' : ''} />
            </div>
            <div className={`content ${isOpen ? 'open' : ''}`}>
                {props.items.map((item) =>
                    <span
                        key={item.id}
                        onClick={() => props.onClickFilter(props.name, item.id)}
                        className={`${item.isActive ? 'active' : ''}`}
                    >
                            {Tools.makeBeautifulLabel(item.name)}
                    </span>
                )}
            </div>
        </div>
    );
}