import external from '../../tools/base.external';

export default class ApiStatistics extends external {

    async getStatistics(target, scope, token) {
        if (this.isValidScope(scope) && this.isValidTarget(target)) {
            let stats = await this.get.json(`statistics/${target}/${scope}`, token);
            return stats;
        }
        return false;
    }

    isValidTarget(target) {
        if (target === "tasks" || target === "groups") return true;
        return false;
    }

    isValidScope(scope) {
        if (scope === "past" || scope === "future") return true;
        return false;
    }
}