import React from "react";
import moment from "moment";
import { PrintSingleEvent } from "./PrintSingleEvent";

export class PrintDayCard extends React.Component {
    /**
     * Formats the day-title before the event
     */
    renderDay() {
        return moment(this.props.day).format("ddd D MMM");
    }

    /**
     * Renders the list of events for the current day.
     */
    renderEvents() {
        let returnArr = [];
        let tasks = this.props.tasks;

        tasks.forEach((task) => {
            if (
                this.props.selectedCategories.includes(
                    task.category.id.toString()
                )
            ) {
                returnArr.push(
                    <PrintSingleEvent
                        key={task.id}
                        task={task}
                        showNames={this.props.showNames}
                        showNotes={this.props.showNotes}
                    />
                );
            }
        });

        return returnArr;
    }

    render() {
        return (
            <div className="day-card">
                <h2>{this.renderDay()}</h2>
                <div className="print-flex">{this.renderEvents()}</div>
            </div>
        );
    }
}
