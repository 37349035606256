import React, { Fragment } from 'react';
import PeopleIcon from '../svg/people';

export class SpotPill extends React.Component {

    render() {
        const availableSpots = this.props.available_spots;
        const takenSpots = this.props.takenSpots;
        const totalSpots = availableSpots + takenSpots;
        const waitingList = this.props.waitingList.length
        let className = "";
        let waitingListJsx = "";

        if (takenSpots <= totalSpots) {
            className += "spots";
            if (this.props.isCardSelected) {
            }
        }
        if (takenSpots === totalSpots) {
            className += " full";

            if (waitingList >= 1) {
                waitingListJsx = <div className="waiting-list">+{waitingList}</div>
            }
        }

        let str = takenSpots + "/" + totalSpots;
        return (
            <Fragment>
                <div className={className}><PeopleIcon /> {str}</div>
                {waitingListJsx}
            </Fragment>
        );
    }

}