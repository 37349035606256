import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Store from 'eas-react-store';
import { URI } from '../../../URI'
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {Tools} from '../../../tools/tools';
import User from '../../../app/controllers/controller.user';
import ProfileIcon from '../../../components/svg/profile-icon';
import svSE from 'date-fns/locale/sv';
registerLocale('sv-SE', svSE);
// The english locale is just to have the week start in monday in the DatePicker


class Header extends Component {
    constructor(props) {
        super(props);

        if (this.props.store.User.data.connectedUser != null) {
            this.userName  = this.props.store.User.data.connectedUser.name;
            this.userLevel = this.props.store.User.data.connectedUser.userlevel;
            this.email = this.props.store.User.data.connectedUser.email;
        }

        if (this.props.store.User.data.organisation != null) {
            this.organisation = this.props.store.User.data.organisation;
        }

        this.userHasAdminRights = this.userLevel === User.USER_ACCESS_RIGHTS.admin || this.userLevel === User.USER_ACCESS_RIGHTS.super_admin
        this.userHasGroupLeaderRights = this.userLevel === User.USER_ACCESS_RIGHTS.group_leader

        this.state = {
            showUserModal: false
        };
    }

    // MARK: Date Picker handleing change

    handleDatePickerChange(e) {
        this.props.store.Schedule.currentDay = Tools.getMonday(e);
        this.props.store.Schedule.updateStore();

        this.props.store.Task.fetchTasksByWeek(this.props.store.Schedule.currentDay);
    }

    // MARK: Categories filters

    /**
     * Handles the click on a checkbox filter.
     * @param {Event} e The event that triggers the function
     */
    handleCheckbox(e) {
        const categoryId = e.target.name;
        this.props.store.Schedule.switchFilter(categoryId);
    }

    /**
     * Returns the class string for a given checkbox.
     * It checks if the box is checked or not and dynamically add/removes
     * the check symbol from the box.
     * @param {Int} id The ID of the checkox to get the css classes
     */
    getCheckboxClassName(id) {
        let className = "custom-check-wrapper";
        className += this.props.store.Schedule.isShowingCategory(id) ? " checked" : "";

        return className
    }

    // MARK: Previous / Next Navigation

    handleClickOnPrevious(e) {
        this.props.store.Schedule.goToPreviousWeek();
        this.props.store.Task.fetchTasksByWeek(this.props.store.Schedule.currentDay);
    }

    handleClickOnNext(e) {
        this.props.store.Schedule.goToNextWeek();
        this.props.store.Task.fetchTasksByWeek(this.props.store.Schedule.currentDay);
    }

    showUserModal() {
        this.setState((state) => ({
            showUserModal: ! state.showUserModal
        }));
    }

    async logout() {
        await this.props.store.User.Api.users.logout();
        window.location.reload();
    }

    renderCategoryCheckboxes() {
        const categories = this.props.store.Category.data;

        if (categories != null) {
            return categories.map((cat, idx) => {
                // Read the category color and dynamically
                // change the color of the checkbox
                const bgColor = cat.color;
                const divStyle = {
                    backgroundColor: '#' + bgColor + 'AA' // We add transparency right in the hex code
                }
                return (
                    <li key={idx}>
                        <label className={this.getCheckboxClassName(cat.id)}>
                            <span className="custom-check" style={divStyle}></span>{cat.title}
                            <input type="checkbox" name={cat.id} onChange={(e) => this.handleCheckbox(e)} />
                        </label>
                    </li>
                )
            });
        }
    }

    renderSubMenu() {
        let className = "nav-item nav-link";

        if (this.props.current !== URI.schedule) {
            return (
                <div className="navbar navbar-expand-lg subnav">
                    <div className="container-fluid">
                        <div className="navbar-nav">

                        { this.userHasAdminRights &&
                            <Fragment>
                                <Link to={URI.admin.manageUsers} className={`${className} ${this.props.current === URI.admin.manageUsers ? 'active' : ''}`}>Hantera användare</Link>
                                <Link to={URI.admin.categories} className={`${className} ${this.props.current === URI.admin.categories ? 'active' : ''}`}>Kategorier</Link>
                                <Link to={URI.admin.statistics} className={`${className} ${this.props.current === URI.admin.statistics ? 'active' : ''}`}>Statistik</Link>
                                <Link to={URI.admin.groupsWeeks} className={`${className} ${this.props.current === URI.admin.groupsWeeks ? 'active' : ''}`}>Groups weeks</Link>
                            </Fragment>
                        }

                        { this.userHasGroupLeaderRights &&
                            <Fragment>
                                <Link to={URI.myGroup.users} className={`${className} ${this.props.current === URI.myGroup.users ? 'active' : ''}`}>Hantera användare</Link>
                                <Link to={URI.myGroup.statistics} className={`${className} ${this.props.current === URI.myGroup.statistics ? 'active' : ''}`}>Statistik</Link>
                            </Fragment>
                        }



                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="navbar navbar-expand-lg schedule-subnav">
                    <div className="container-fluid">
                        <div className="navbar-nav w-100">
                            <span className="week-arrow-navigation" onClick={(e) => this.handleClickOnPrevious(e)}></span>
                            <DatePicker
                                customInput={<CustomDatePickerInput store={this.props.store} />}
                                selected={this.props.store.Schedule.currentDay}
                                onChange={(e) => this.handleDatePickerChange(e)}
                                showWeekNumbers
                                locale="sv-SE">
                            </DatePicker>
                            <span className="week-arrow-navigation right" onClick={(e) => this.handleClickOnNext(e)}></span>
                            <button className="btn btn-default" onClick={(e) => {
                                this.props.store.Task.fetchTasks();
                                this.props.store.Schedule.goToToday(e);
                            }}>Idag</button>
                            <ul className="d-flex flex-row">
                                {this.renderCategoryCheckboxes()}
                            </ul>
                            <button className="btn btn-default ml-auto" onClick={this.props.onClickPrint}>Skriv ut</button>
                        </div>
                    </div>
                </div>
            );
        }
    }


    renderMainMenu() {
        let className = "nav-item nav-link";

        let group = {};

        if (this.props.store.Group != null && this.props.store.Group.data != null) {
            group = this.props.store.Group.data.find((item) => item.id === this.props.store.User.data.connectedUser.group_ids[0]);
        }

        return (
            <Fragment>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <div className="navbar-nav">
                            <Link to={URI.schedule} className={`${className} ${this.props.current === URI.schedule ? 'active' : ''}`}>Schema</Link>

                            { this.userHasAdminRights &&
                                <Link to={URI.admin.manageUsers} className={`${className} ${this.props.currentRoot === URI.admin.root ? 'active' : ''}`}>Administration</Link>
                            }

                            { this.userHasGroupLeaderRights &&
                                <Link to={URI.myGroup.users} className={`${className} ${this.props.currentRoot === URI.myGroup.root ? 'active' : ''}`}>Min grupp</Link>
                            }
                        </div>
                        <div onClick={() => this.showUserModal()} className="connected-user">
                            <div className="user-name">
                                <span className="name">{this.userName}</span>
                                <span className="position">
                                    {Tools.makeBeautifulLabel(this.userLevel)} • {group != null ? group.name : ''} • {this.organisation != null ? this.organisation.name : ''}
                                </span>
                            </div>
                            <div className="profile-icon">
                                <ProfileIcon />
                            </div>
                        </div>
                    </div>
                </nav>

                {this.state.showUserModal &&
                    <div className="account-modal">
                        <div className="account-info">
                            <div className="user-image" alt="user profile">
                                <span>{Tools.getInitials(this.userName)}</span>
                            </div>
                            <span className="user-name">{this.userName}</span>
                            <span className="user-email">{this.email}</span>
                        </div>
                        <button onClick={() => this.logout()}>Logga ut</button>
                    </div>
                }
            </Fragment>
        );
    }

    render() {
        return(
            <header className="fixed-top">
                {this.renderMainMenu()}
                {this.renderSubMenu()}
            </header>
        )
    }
}

export default Store.connectStore(Header);

// MARK: Custom DatePickerInput

class CustomDatePickerInput extends React.Component {

    getWeekNumber(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number
        return weekNo;
    }

    render() {
        const date = this.props.store.Schedule.currentDay;
        const monthStr = date.toLocaleString('sv-se', { month: 'short' })
        const weekNb = this.getWeekNumber(date)

        return(
            <span className="current-month" onClick={this.props.onClick}>{monthStr} • V{weekNb}</span>
        );
    }

}