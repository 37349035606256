import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import { Tools } from '../../../tools/tools';
import ReactToPrint from "react-to-print";
import { PrintUserList } from '../../../components/print/PrintUserList';

class ActionsTopbar extends Component {
    constructor(props) {
        super(props);

        this.options = {
            actions: [
                { value: 0, label: 'Byta roll', type: 'roles' },
            ],
            groups: [],
            roles: [],
            categories: []
        };

        if (this.props.store.Group != null) {
            this.props.store.Group.data.forEach((group, index) => {
                this.options.groups.push({ value: index, label: Tools.makeBeautifulLabel(group.name), id: group.id });
            });
        }

        if (this.props.store.User != null && this.props.store.User.data != null) {
            this.props.store.User.data.userlevel.forEach((level, index) => {
                this.options.roles.push({ value: index, label: Tools.makeBeautifulLabel(level != "group_leader" ? level : "Service Grupp Ledare"), id: level });
            });
        }

        if (this.props.store.Category != null) {
            this.props.store.Category.data.forEach((category, index) => {
                this.options.categories.push({ value: index, label: Tools.makeBeautifulLabel(category.title), id: category.id });
            });
        }

        if (!this.props.store.User.isGroupLeader) {
            this.options.actions.push(
                { value: 1, label: 'Byta grupp', type: 'groups' },
                { value: 2, label: 'Skicka meddelande', type: 'message' }
            );
        } else {
            this.options.roles = this.options.roles.filter((userRole) => userRole.id !== "admin" && userRole.id !== "super_admin");
            this.options.actions.push(
                { value: 1, label: 'Skicka meddelande', type: 'message' },
            );
        }


        this.state = {
            search: '',
            optionsName: '',
            optionsValues: [],
            dropdown: {
                actions: null,
                values: null
            }
        };

        this.onSearch = this.onSearch.bind(this);
        this.onActionsChange = this.onActionsChange.bind(this);
        this.onValuesChange = this.onValuesChange.bind(this);
        this.updateUsers = this.updateUsers.bind(this);
    }

    componentDidUpdate() {
        if (
            this.props.isChecked.length > 0 &&
            this.props.isChecked.every((user) => user.userlevel === 'team_leader') &&
            this.options.actions.find((option) => option.value === (this.props.store.User.isGroupLeader ? 1 : 2)) == null
        ) {
            this.options.actions.push(
                { value: this.props.store.User.isGroupLeader ? 1 : 2, label: 'Byta ansvar', type: 'categories' }
            );
        }
    }

    onSearch(event) {
        this.setState({ search: event.target.value });
        this.props.updateUsers(this.doSearch(event.target.value, this.props.users));
    }

    doSearch(pattern, users) {
        let result = [];

        result = users.filter((user) => {
            let userlevel = Tools.makeBeautifulLabel(user.userlevel);
            let group = this.props.store.Group.data.find((item) => item.id === user.group_ids[0]);

            let isIn = user.name.toLowerCase().indexOf(pattern.toLowerCase()) !== -1 ||
                user.email.toLowerCase().indexOf(pattern.toLowerCase()) !== -1 ||
                userlevel.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;

            if (group != null && group.name != null) {
                isIn = isIn || group.name.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
            }

            return isIn;
        });

        return result;
    }

    resetSearch() {
        this.setState({
            search: ""
        });
    }

    onActionsChange(event) {
        if (this.options.actions[event.value].type === "message") {
            this.props.showMessageOverview()
        } else {
            this.setState((state) => ({
                ...state,
                optionsName: this.options.actions[event.value].type,
                optionsValues: this.options[this.options.actions[event.value].type],
                dropdown: {
                    ...state.dropdown,
                    actions: this.options.actions[event.value],
                    values: null
                }
            }));
        }
    }

    onValuesChange(event) {
        this.setState((state) => ({
            ...state,
            dropdown: {
                ...state.dropdown,
                values: this.options[this.state.optionsName][event.value]
            }
        }));
    }

    updateUsers() {
        if (this.state.optionsName === 'groups') {
            this.props.isChecked.forEach((user) => {
                this.props.store.User.removeUserFromGroup(user.group_ids[0], user);
                this.props.store.User.addUserToGroup(
                    this.state.dropdown.values.id,
                    user.userlevel,
                    user.userlevel === "team_leader"
                        ?
                        this.props.store.User.getUserResponsability(this.props.store.Group.data, user).id
                        :
                        this.props.store.Category.data[0].id,
                    user);
            });
        } else if (this.state.optionsName === 'roles') {
            this.props.isChecked.forEach((user) => {
                let userGroup = user.group_ids[0];

                this.props.store.User.updateUser({
                    id: user.id,
                    name: user.name,
                    phone: user.phone,
                    email: user.email,
                    userlevel: this.state.dropdown.values.id
                });

                this.props.store.User.removeUserFromGroup(userGroup, user);
                this.props.store.User.addUserToGroup(
                    userGroup,
                    this.state.dropdown.values.id,
                    this.props.store.Category.data[0].id,
                    user
                );
            });
        } else if (this.state.optionsName === 'categories') {
            this.props.isChecked.forEach((user) => {
                if (user.userlevel === "team_leader") {
                    this.props.store.User.removeUserFromGroup(user.group_ids[0], user);
                    this.props.store.User.addUserToGroup(
                        user.group_ids[0],
                        user.userlevel,
                        this.state.dropdown.values.id,
                        user
                    );
                }
            });
        }

        this.props.store.Group.getGroups(false);
        this.props.store.User.fetchAllUsers(false);
    }

    render() {
        return (
            <div className="topbar">
                <div className="actions">
                    <div className="dropdown">
                        <Dropdown
                            className="dropdown"
                            options={this.options.actions}
                            value={this.state.dropdown.actions}
                            onChange={this.onActionsChange}
                            placeholder="Actions"
                        />
                    </div>
                    <div className="dropdown">
                        <Dropdown
                            options={this.state.optionsValues}
                            value={this.state.dropdown.values}
                            onChange={this.onValuesChange}
                            placeholder="Välj..."
                        />
                    </div>
                    <button onClick={this.updateUsers} className="btn btn-primary btn-table">
                        {this.state.dropdown.actions != null ? this.state.dropdown.actions.label : "Tillämpa"}
                    </button>
                </div>
                <input onChange={this.onSearch} value={this.state.search} type="text" placeholder="Sök person, email, role eller grupp" />
                {!this.props.store.User.isGroupLeader &&
                    <button onClick={this.props.addUser} className="btn btn-primary btn-table add-user">
                        Lägg till användare
                    </button>
                }
                {!this.props.store.User.isGroupLeader &&
                    <button onClick={this.props.importUsers} className="btn btn-primary btn-table ml-3">
                        Importera användare
                    </button>
                }
                <ReactToPrint
                    trigger={() => {
                        return (
                        <button onClick={this.print} className="btn btn-primary btn-table ml-3">
                            Skriv ut
                        </button>
                        );
                    }}
                    content={() => this.printUserListRef}
                />
                <div style={{ display: "none" }}>
                    <PrintUserList
                        ref={(el) => (this.printUserListRef = el)}
                        users={this.props.filteredUsers}
                        store={this.props.store}
                    />
                </div>
            </div>
        );
    }
}

export default ActionsTopbar;