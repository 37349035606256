import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Store from 'eas-react-store';
import LoginNew from './views/login/LoginNew';
import ForgotPassword from './views/login/ForgotPassword';

const MainNotConnected = (props) => {
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route path="/" component={LoginNew} />
            </Switch>
        </BrowserRouter>
    );
}


export default Store.connectStore(MainNotConnected);
