import external from '../../tools/base.external';

export default class ApiCategory extends external {

    async getCategories(token) {
        let categories = await this.get.json('categories', token);
        return categories;
    }

    async putCategory(id, data, token) {
        let cat = await this.put.json(`categories/${id}`, data, token);
        return cat;
    }

    async postCategory(data, token) {
        let cat = await this.post.json(`categories`, data, token);
        return cat;
    }

    async deleteCategory(id, token) {
        let data = {};
        let res = await this.delete.json(`categories/${id}`, data, token);
        return res;
    }

}