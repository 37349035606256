import external from '../../tools/base.external';

export default class ApiGroups extends external {

    async getGroups(token) {
        let groups = await this.get.json(`groups`, token);

        return groups;
    }

    async getGroupManager(groupId, token) {
        let group = await this.get.json(`groups/${groupId}`, token);
        return group.group_leader;
    }

    async updateGroupsWeeks(groupId, data) {
        let response = await this.put.json(`groups/${groupId}`, data);

        return response;
    }

    async deleteUserFromGroup(groupId, data, token) {
        let deletion = await this.delete.json(`groups/${groupId}/member`, data, token);
        return deletion;
    }

    async addUserToGroup(groupId, data, token) {
        let adding = await this.post.json(`groups/${groupId}/member`, data, token);
        return adding;
    }

    async getUsersFromGroup(groupId, token) {
         let res = await this.get.json(`groups/${groupId}/users`, token);
         return res;
    }

}