import React, { Fragment } from 'react';
import ArrowRight from './ArrowRight';

function InputTextField(props) {
    return (
        <Fragment>
            <div className="input-container">
                <input
                    className={props.className}
                    type={props.isPassword ? "password" : "email"}
                    name={props.name}
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                />

                {props.showIcon &&
                    <ArrowRight className={props.svgClass} onClick={props.onClick} />
                }
            </div>

            {((props.hasError && props.showIcon) || (props.hasError && props.isPassword)) &&
                <p className="error-message">{props.messageError}</p>
            }
        </Fragment>
    );
}

export default InputTextField;
