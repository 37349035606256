import React from 'react';
import Spinner from '../../assets/svg/spinner.svg';

function Loader(props) {
    return (
        <div className="loader-container">
            <img className="spinner" src={Spinner} />
        </div>
    );
}

export default Loader;