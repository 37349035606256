import React, { useState } from 'react';
import InputTextField from './InputTextField';
import VolontarLogo from '../../assets/svg/volontar_logo_white.svg';
import Store from 'eas-react-store';
import AngleRight from './AngleRight';
import { Link } from 'react-router-dom';

function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [organisation, setOrganisation] = useState({});
    const [canEnterPassword, setCanEnterPassword] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [messageError, setMessageError] = useState('');

    const signIn = async (event) => {
        event.preventDefault();

        if (! canEnterPassword) {
            getOrganisation();

            return;
        }

        let result = await props.store.User.loginWithEmailPassword(email, password);

        if (! result.success) {
            setError(false);

            return;
        }

        window.location.reload();
    }

    const getOrganisation = async () => {

        let result = await props.store.Organisation.getOrganisation(email);

        if (result.message == null) {
            setOrganisation(result.organisation);
            setCanEnterPassword(true);
        } else {
            setError();
        }
    }

    const setError = (isEmail = true) => {
        setHasError(true);
        setMessageError(isEmail ? "Fel e-postadress" : "Fel lösenord");
    }

    return (
        <div className="login-page">
            <div className="header"></div>
            <header>
                <img
                    src={VolontarLogo}
                    alt="Logo in white of Volontär App"
                />
            </header>
            <div className="container">
                <div className="organisation">
                    <p className={`organisation-logo ${organisation.icon != null ? '' : 'hide'}`}>
                        <img
                            src={organisation.icon}
                            alt="Logo of the En Appstudio organisation"
                        />
                    </p>

                    <h1 className="organisation-title">
                        Logga in på
                        {organisation.name != null ?
                            <b> {organisation.name}</b>
                        :
                            <span> VolontärApp</span>
                        }
                    </h1>

                    <form className="form-login" method="POST">
                        <InputTextField
                            className={`${! canEnterPassword ? 'field-is-alone' : 'field-is-first'} ${hasError ? 'field-has-error' : ''}`}
                            svgClass={`${! canEnterPassword ? "arrow-right" : ''} ${hasError ? 'has-error' : ''}`}
                            showIcon={! canEnterPassword}
                            name="email"
                            placeholder="Epost-adress"
                            value={email}
                            onChange={(event) => {
                                if (hasError) {
                                    setHasError(false);
                                }

                                if (canEnterPassword) {
                                    setCanEnterPassword(false);
                                }

                                setPassword('');
                                setEmail(event.target.value)
                            }}
                            onClick={getOrganisation}
                            hasError={hasError}
                            messageError={messageError}
                        />

                        <InputTextField
                            className={`field-is-last ${canEnterPassword ? 'field-transition' : ''} ${hasError ? 'field-has-error' : ''}`}
                            name="password"
                            placeholder="Lösenord"
                            value={password}
                            onChange={(event) => {
                                if (hasError) {
                                    setHasError(false);
                                }

                                setPassword(event.target.value)
                            }}
                            isPassword={true}
                            hasError={hasError && password !== ''}
                            messageError={messageError}
                        />

                        <button className={`button-login ${canEnterPassword ? 'button-show' : ''}`} onClick={signIn}>
                            Logga in
                        </button>

                        <p className={`forgot-password ${canEnterPassword ? 'show' : ''}`}>
                            <Link to={{
                                    pathname: '/forgot-password',
                                    state: {
                                        email
                                    }
                                }}
                            >
                                <span>Jag har glömt mitt lösenord</span>

                                <AngleRight className="forgot-password-icon" />
                            </Link>
                        </p>
                    </form>
                </div>

                <div className="policy">
                    <div className="transition">
                        <div></div>
                        <span>eller</span>
                        <div></div>
                    </div>

                    <a className="button-pingst" href={process.env.REACT_APP_API_LINK}>
                        Pingst Jönköping logga in
                        <AngleRight className="button-pingst-icon" />
                    </a>

                    v1.0
                </div>
            </div>
        </div>
    );
}

export default Store.connectStore(Login);
