import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import NotAllowed from '../../views/layout/NotAllowed';

class SafeRoute extends Component {
    render() {
        const Component = this.props.component;

        return (
            <Route exact={this.props.exact} path={this.props.path} component={() => (
                (this.props.redirect) ?
                    <NotAllowed mainText="Du får inte!" /> :
                    <Component />
            )} />
        );
    }
}

export default SafeRoute;