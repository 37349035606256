import React from 'react';
import arrowGreyUpDown from '../../assets/svg/up-down-grey.svg';
import arrowUpBlue from '../../assets/svg/up-blue.svg';
import arrowDownBlue from '../../assets/svg/down-blue.svg';

const UserTableArrows = (props) => {
    let icon = '';

    if (props.showDown === false && props.showUp === false) {
        icon = arrowGreyUpDown;
    } else if (props.showDown === false && props.showUp === true) {
        icon = arrowUpBlue;
    } else if (props.showDown === true && props.showUp === false) {
        icon = arrowDownBlue;
    } else {
        icon = arrowGreyUpDown;
    }

    return (
        <p>
            <img src={icon} alt="Sorting icon"/>
        </p>
    );

}

export default UserTableArrows;