import React from 'react';
import {URI} from '../../URI';
import iconPeopleWhite from '../../assets/svg/app-icon-nobg.svg';

class Main extends React.Component {
    render() {
        return(
            <div className="login-wrapper">
                <div className="container">
                    <img src={iconPeopleWhite} alt="Logo"/>
                    <h1>VolontärApp</h1>
                    <p className="lead">{this.props.mainText}</p>
                    <a
                        href={URI.schedule}
                        className="btn btn-default">
                       Gå till schema
                    </a>
                </div>
            </div>
        );
    }
}


export default Main;
