import React from "react";

export default function Notification(props) {
    return (
        <svg
            className={props.className}
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22.81 23.06">
            <path
                className="cls-1"
                d="M20,18.06a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3v-12a3,3,0,0,1,3-3h8.8a5.24,5.24,0,0,1,.7-2H5a5,5,0,0,0-5,5v12a5,5,0,0,0,5,5H17a5,5,0,0,0,5-5V8.1A5.25,5.25,0,0,1,20,9Z"
            />
            <path
                className="cls-1"
                d="M19,7.56a3.78,3.78,0,1,0-3.78-3.78A3.78,3.78,0,0,0,19,7.56Z"
            />
        </svg>
    );
}
