import React, { Fragment } from 'react';
import Store from 'eas-react-store';
import Header from '../layout/Header/Header.jsx';
import UserTable from '../../components/user-table/UserTable'
import { URI } from '../../URI'

const Administration = props => {
    const users = props.store.User.data.users;

    return (
        <Fragment>
            <Header
                current={URI.admin.manageUsers}
                currentRoot={URI.admin.root}
            />
            <div className="container-fluid content-container">
                <UserTable users={users} isSuperAdmin={true}></UserTable>
            </div>
        </Fragment>
    );
}

export default Store.connectStore(Administration);