export const URI = {
    schedule: "/",
    login: "/login",
    admin: {
        root: "/admin",
        manageUsers: "/admin/users",
        categories: "/admin/categories",
        statistics: "/admin/statistics",
        groupsWeeks: "/admin/groups-weeks"
    },
    myGroup: {
        root: "/my-group",
        users: "/my-group/users",
        statistics: "/my-group/statistics",
    }
}