import React, { Fragment, Component } from 'react';
import Header from '../layout/Header/Header';
import { URI } from '../../URI';
import moment from 'moment';
import localization from 'moment/locale/sv'
import WeekCard from './weekCard';
import Store from 'eas-react-store';
import { Tools } from '../../tools/tools';
import { Bar, defaults, Chart } from 'react-chartjs-2';
import extendChart from './chart';
import Loader from '../../components/svg/loader';
moment.locale('sv', localization);
defaults.global.defaultFontFamily = 'Inter var, sans-serif';
defaults.global.defaultFontColor = 'rgba(106, 106, 108, 0.6)';


class GroupsWeeks extends Component {
    constructor(props) {
        super(props);

        extendChart(Chart);
        this.previousYear = (new Date().getFullYear() - 1);
        this.currentYear = new Date().getFullYear();
        this.nextYear = (new Date().getFullYear() + 1);

        this.state = {
            selectGroup: 0
        };

        this.selectedYear = this.props.store.Group.selectedYear;
        this.changeSelectedYear = this.props.store.Group.changeSelectedYear;

        this.selectGroup = this.selectGroup.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.removeGroup = this.removeGroup.bind(this);
    }

    async addGroup(group, week, oldGroup = null, year) {
        let weeks = this.props.store.Group.data.find((g) => g.id === group).weeks;

        weeks.push({
            week: Number(week),
            year: Number(year)
        });

        await this.props.store.Group.Api.groups.updateGroupsWeeks(group, {weeks});

        if (oldGroup != null) {
            await this.deleteGroup(oldGroup, week, year);
        }

        await this.props.store.Group.getGroups();
    }

    async removeGroup(group, week, year) {
        await this.deleteGroup(group, week, year);
        await this.props.store.Group.getGroups();
    }

    async deleteGroup(group, week, year) {
        let weeks = this.props.store.Group.data.find((g) => g.id === group).weeks;

        let indexWeek = weeks.findIndex((w) => {
            return w.week === Number(week) && w.year === Number(year);
        });

        weeks.splice(indexWeek, 1);
        await this.props.store.Group.Api.groups.updateGroupsWeeks(group, {weeks});
    }

    selectGroup(index) {
        if (this.state.selectGroup === index) {
            this.setState({
                selectGroup: 0
            });
        } else {
            this.setState({
                selectGroup: index
            });
        }
    }

    searchGroupWhoHasWeek(week, year) {
        let result = "";

        this.props.store.Group.data.forEach((group) => {
            group.weeks.forEach((weekNumber) => {
                if (weekNumber.week == week && weekNumber.year == year) {
                    result = group;
                }
            });
        });

        return result;
    }

    // Render functions

    renderMonths(year) {
        let weeks = Tools.getWeekNumberInMonths(year);
        let result = [];

        moment.months().forEach((month, index) => {
            result.push(
                <div key={month} className="month-container">
                    <div className="month">
                        {month}
                    </div>

                    <div className="month-row">
                        {weeks[index+1].map((week, i) =>
                            <WeekCard
                                key={week}
                                year={year}
                                index={week}
                                selectGroup={this.state.selectGroup}
                                onClick={(e) => this.selectGroup(week)}
                                week={`${week}`}
                                group={this.searchGroupWhoHasWeek(week, year)}
                                addGroup={this.addGroup}
                                removeGroup={this.removeGroup} />
                        )}
                    </div>
                </div>
            );
        });

        return result;
    }

    renderYear(year) {
        return (
            <div className="groups-weeks">
                <div className="groups-weeks-menu">
                    <ul>
                        <li
                            className={this.selectedYear === this.previousYear ? "selected" : ""}
                            onClick={(e) => this.changeSelectedYear(this.previousYear)}
                        >
                            {this.previousYear}
                        </li>
                        <li
                            className={this.selectedYear === this.currentYear ? "selected" : ""}
                            onClick={(e) => this.changeSelectedYear(this.currentYear)}
                        >
                            {this.currentYear}
                        </li>
                        <li
                            className={this.selectedYear === this.nextYear ? "selected" : ""}
                            onClick={(e) => this.changeSelectedYear(this.nextYear)}
                        >
                            {this.nextYear}
                        </li>
                    </ul>
                </div>

                <h2 className="title">Veckor {year}</h2>

                <div className="groups-weeks-container">
                    {this.renderMonths(year)}
                </div>
            </div>
        );
    }

    renderGroupsStats(selectedYear) {
        let result = [];

        this.props.store.Group.data.forEach((group) => {
            let count = 0;

            group.weeks.forEach((week) => {
                if (week.year === selectedYear) {
                    ++count;
                }
            });

            group.count = count;
            result.push(group);
        });

        Tools.sortArrayByObjectKey(result, 'id');

        const data = {
            labels: result.map((group) => group.name.toUpperCase()),

            datasets: [
              {
                label: 'Antal veckor',
                backgroundColor: 'rgb(75, 200, 138, 0.6)',
                hoverBackgroundColor: 'rgb(75, 200, 138, 0.5)',
                data: result.map((group) => group.count),
                barThickness: 30,
              }
            ],
        };

        return (
            <div className="bar">
                <Bar
                    type='roundedBar'
                    data={data}
                    height={30}
                    width={100}
                    options={{
                        cornerRadius: 60,
                        tooltips: {
                            enabled: true
                        },
                        legend: {
                            display: false,
                            labels: {
                                defaultFontFamily: 'Inter var, sans-serif',
                                defaultFontColor: 'red'
                            }
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    userCallback: function(label, index, labels) {
                                        if (Math.floor(label) === label) {
                                            return label;
                                        }
                                    },
                                }
                            }],
                            xAxes: [{
                                gridLines: {
                                    color: "rgba(0, 0, 0, 0)",
                                }
                            }],
                        }
                    }}
                />
            </div>
        );
    }

    render() {
       return (
            <Fragment>
                <Header current={URI.admin.groupsWeeks} currentRoot={URI.admin.root}></Header>
                {this.props.store.Group.isLoading ?
                    <Loader />
                :
                    <div className="container groups-container">
                        {this.renderYear(this.selectedYear)}
                        {this.renderGroupsStats(this.selectedYear)}
                    </div>
                }
            </Fragment>
        );
    }
}

export default Store.connectStore(GroupsWeeks);