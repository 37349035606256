import BaseController from './controller.base';
import ApiStatistics from '../api/api.statistics';

export default class Statistics extends BaseController {
    static controllerName = 'Statistics';

    static TARGETS = ["tasks", "groups"];
    static TIME_PERIODS = ["future", "past"];

    constructor(updateStore, config, localStorage) {
        super(updateStore);

        this.Api = {
            statistics: new ApiStatistics(config.path),
        };

        if (localStorage != null) {
            if (localStorage.User != null) {
                this.data = localStorage.Statistics;
                this.token = localStorage.User.token;
            }
            this.updateStore();
        } else {
            this.data = {};
            Statistics.TARGETS.forEach((elt) => {
                this.data[elt] = {}
            })
            this.token = ""
        }

        Statistics.TARGETS.forEach((target) => {
            Statistics.TIME_PERIODS.forEach((time) => {
                this.fetchStatistics(target, time);
            })
        })
    }

    // MARK: - API Methods

    /**
     * Fetches the statistics for all the targets and the time periods
     * and stores everything in the local storage using the Store.
     * 
     * @param {String} target Either "tasks" or "groups", use the static TARGET attribute
     * @param {String} scope Either "future" or "past", use the static TIME_PERIODS attribute.
     */
    async fetchStatistics(target, scope) {
        let globalStats = await this.Api.statistics.getStatistics(target, scope, this.token);
        this.data[target][scope] = globalStats;
        this.updateStore();
    }

    /**
     * Returns the statistics for a specific group
     * 
     * TODO - Make an API call instead of returning from local storage
     * that way, we can stop using local storage for this and improve security.
     * 
     * This function should be used for the Service Group Leaders statistics view.
     * 
     * @param {Int} group The ID of the group to get the statistics for
     * @param {String} scope The scope: either "future" or "past"
     */
    getGroupStatistics(group, scope) {
        let arrayOfGroupsForScope = this.data.groups[scope];
        let groupData = arrayOfGroupsForScope.find(elt => {
            return elt.id === group
        })
        
        return groupData.statistics;        
    }
}