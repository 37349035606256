import React from 'react';

export class GroupStatistics extends React.Component {

    renderCategoriesDetails(categories) {
        return categories.map(cat => {
            return (
                <li key={cat.name}>
                    {cat.name}
                    <span>{cat.amount}</span>
                </li>
            );
        })
    }

    renderSingleGroup(group) {
        return (
            <div className="col-12 col-md-6 col-lg-3" key={group.group}>
                <div className="group-box">
                    <h3>{group.group_name}</h3>
                    <ul className="group-list">
                        <li>
                            Uppgifter genomförda
                            <span>{group.total}</span>
                        </li>
                        {this.renderCategoriesDetails(group.categories)}
                    </ul>
                </div>
            </div>
        )
    }

    renderGroupStatistics() {
        return this.props.groups.map((group) => {
            return this.renderSingleGroup(group)
        })
    }

    render() {
        return (
            <div className="row statistics-group-row">
                <div className="col-12 statistics-head">
                    <h2>Per grupp</h2>
                </div>
                {this.renderGroupStatistics()}
            </div>
        )
    }
}