import React from 'react';
import Spinner from '../assets/svg/spinner.svg';
import VolontarLogoSuperLight from '../assets/svg/volontar-logo-super-light.svg';

function LoadingView(props) {
    return (
        <div className="loading-container">
            <img src={VolontarLogoSuperLight} />
            <img className="spinner" src={Spinner} />
        </div>
    );
}

export default LoadingView;