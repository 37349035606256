import BaseController from './controller.base';
import ApiCategory from '../api/api.category';

export default class Category extends BaseController {
    static controllerName = 'Category';

    constructor(updateStore, config, localStorage) {
        super(updateStore);

        this.Api = {
            category: new ApiCategory(config.path)
        };

        if (localStorage != null) {
            if (localStorage.Category != null) {
                this.data = localStorage.Category
            }
            if (localStorage.User != null) {
                this.token = localStorage.User.token
            }
            this.updateStore();
        } else {
            this.data = [];
            this.token = "";
        }

        this.fetchCategories();
        
    }

    async fetchCategories() {
        let categories = await this.Api.category.getCategories(this.token);
        categories.sort((a, b) => a.id - b.id);
        this.data = categories;
        this.updateStore();
    }

    async createCategory(category) {
        await this.Api.category.postCategory(category, this.token);
        this.fetchCategories();
    }

    async updateCategory(category) {
        await this.Api.category.putCategory(category.id, category, this.token);
        this.fetchCategories();
    }

    async deleteCategory(category) {        
        await this.Api.category.deleteCategory(category.id, this.token);
        this.fetchCategories();
    }

}

