import React, { Fragment } from 'react';
import Store from 'eas-react-store';
import Dropdown from 'react-dropdown';
import moment from 'moment';
import 'react-dropdown/style.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Tools } from '../../../tools/tools';
import svSE from 'date-fns/locale/sv';
registerLocale('sv-SE', svSE);

class SlideOverviewEdit extends React.Component {

	constructor(props) {
		super(props);
		this.task = this.props.store.Schedule.selectedTask;
		this.savedEdits = this.props.store.Schedule.savedEdits;
		this.groupManager = this.props.store.Schedule.selectedTaskGroupManager;

		// Sets the first task of the schedule as default
		if (this.task === null && this.props.store.Task.data.tasks.length > 0) {
			this.task = this.props.store.Task.data.tasks[0];
		} else if (this.task === null && this.props.store.Task.data.tasks.length <= 0) {
			this.task = this.props.store.Task.getFakeTask();
		}

		/* We copy the selected task in the state for faster manipulation
		 * while editing the form. When the user saves, we create a new task object
		 * based on the local state value.
		 * The savedEdits saves the values in the Schedule controller to avoid loosing the
		 * user input if the component gets re-rendered
		 */
		this.optionGroups = [];
		let currentOwnerGroup = this.task.owner_group;

		if (this.props.store.Group != null && this.props.store.Group.data != null) {
			this.props.store.Group.data.forEach((group, index) => {
				this.optionGroups.push({ value: index, label: Tools.makeBeautifulLabel(group.name), id: group.id });

				if (this.task.owner_group === group.id) {
					currentOwnerGroup = this.optionGroups[index];
				}
			});
		}

		this.optionCategories = [];
		let taskCategory = this.task.category.id;

		if (this.props.store.Category != null) {
			this.props.store.Category.data.forEach((category, index) => {
				this.optionCategories.push({ value: index, label: category.title, id: category.id });

				if (this.task.category.id === category.id) {
					taskCategory = this.optionCategories[index];
				}
			});
		}

		this.optionLocations = [];
		let taskLocation = this.task.location.id;

		if (this.props.store.Task.data.locations != null) {
			this.props.store.Task.data.locations.forEach((location, index) => {
				this.optionLocations.push({ value: index, label: `${location.title}, ${location.address}`, id: location.id });

				if (this.task.location.id === location.id) {
					taskLocation = this.optionLocations[index];
				}
			});
		}

		this.state = {
			/* Selected task data */
			total_spots: (this.savedEdits !== null) ? this.savedEdits.total_spots : this.task.available_spots + this.task.taken_spots,
			description: (this.savedEdits !== null) ? this.savedEdits.description : this.task.description,
			end_date: (this.savedEdits !== null) ? this.savedEdits.end_date : moment.utc(this.task.end_date).toDate(),
			category: taskCategory,
			start_date: (this.savedEdits !== null) ? this.savedEdits.start_date : moment.utc(this.task.start_date).toDate(),
			title: (this.savedEdits !== null) ? this.savedEdits.title : this.task.title,
			location: taskLocation,
			owner_group: currentOwnerGroup,

			/* Warning about being due soon */
			isShowingWarning: false,
			warningMessage: "",

			/* Location selector and creation on the go */
			isShowingNewLocationField: false,
			newLocationName: "",
			newLocationAddress: "",
			locationsList: this.props.store.Task.data.locations,

			/* Repeating event feature */
			endRepeatingDate: "",
			isRepeatingEvent: false
		}

		this.onDropdownChange = this.onDropdownChange.bind(this);
	}

	showWarningMessage(message) {
		this.setState({
			isShowingWarning: true,
			warningMessage: message
		})
	}

	saveCurrentEdits() {
		this.props.store.Schedule.savedEdits = {
			"total_spots": this.state.total_spots,
			"description": this.state.description,
			"category": {
				"id": this.state.category
			},
			"title": this.state.title,
			"start_date": this.state.start_date,
			"end_date": this.state.end_date,
			"owner_group": this.state.owner_group
		}
	}

	/**
	 * This function is called when the save button is clicked
	 * It handles all repeating event related actions.
	 */
	handleRepeatingTask(taskToSend) {
		let task = taskToSend;
		const endRepeating = moment(this.state.endRepeatingDate);
		let startDate = moment(task.start_date);
		let endDate = moment(task.end_date);


		/* Ignores the first date that will be handled by the main onSubmit function.
		 * Adds one day to the loop so that the last selected day is included */
		startDate = startDate.add(7, 'day')
		endDate = endDate.add(7, 'day')
		while (startDate <= endRepeating.add(1, 'day')) {
			let newStartDate = startDate.utc().format("YYYY-MM-DDTHH:mm:SSS");
			let newEndDate = endDate.utc().format("YYYY-MM-DDTHH:mm:SSS");
			task.start_date = newStartDate;
			task.end_date = newEndDate;
			this.props.store.Task.createTask(task)
			startDate = startDate.add(7, 'day')
			endDate = endDate.add(7, 'day')
		}

	}

	// MARK: Handling form input changes

	/**
	 * Handles the change of value of all the input fields
	 * @param {Event} evt The JS event
	 */
	onInputChange(evt) {
		this.setState({
			[evt.target.name]: evt.target.value
		})
	}

	onLocationChange(event) {
		this.setState({
			location: this.optionLocations[event.value]
		});
	}

	onCategoryChange(event) {
		this.setState({
			category: this.optionCategories[event.value]
		});
	}

	/**
	 * Returns the class string for a given checkbox.
	 * It checks if the box is checked or not and dynamically add/removes
	 * the check symbol from the box.
	 */
	getCheckboxClassName() {
		let className = "custom-check-wrapper";
		className += this.state.isRepeatingEvent ? " checked" : "";
		return className
	}

	handleCheckbox() {
		this.setState({
			isRepeatingEvent: !this.state.isRepeatingEvent
		})
	}

	// MARK: Handling clicks

	/**
	 * Handles the click on the add button
	 * We use the local state to avoid re-rendering the entire schedule
	 * when that value is changed.
	 */
	handleClickOnAddSpot() {
		this.setState((state, props) => ({
			total_spots: Number(state.total_spots) + 1
		}))
	}

	/**
	 * Handles the click on the new location button
	 */
	handleClickOnAddLocation() {
		this.setState({
			isShowingNewLocationField: true
		})
	}

	/**
	 * Handles the click on confirm new location button
	 */
	handleClickOnConfirmNewLocation(evt) {
		const newLocation = {
			"title": this.state.newLocationName,
			"address": this.state.newLocationAddress
		}
		this.saveCurrentEdits();
		this.props.store.Task.createLocation(newLocation);
	}

	/**
	 * Handles the click on the minus button
	 * We make sure that the user can not set the available spots number
	 * to a lower number than the number of people who already registered for the task.
	 */
	handleClickOnRemoveSpot() {
		if ((this.state.total_spots - 1) >= this.task.taken_spots) {
			this.setState({
				total_spots: Number(this.state.total_spots) - 1
			})
		} else {
			alert('You can not have less spots than the number of people already registered');
		}
	}

	// MARK: - Handle DatePicker changes

	/**
	 * Handle change on start date picker
	 * TODO: - Find a workaround to avoid the reset of end_date, while still changing the day of the end_date
	 */
	handleStartDatePickerChange(e) {
		const duration = Date.parse(this.state.end_date) - Date.parse(this.state.start_date)
		this.setState({
			start_date: e,
			end_date: moment(e).add(duration, 'milliseconds').toDate()
		})
	}

	/**
	 * Handle change on end date picker
	 */
	handleEndDatePickerChange(e) {
		this.setState({
			end_date: e
		})
	}

	/**
	 * Handle change on start date picker
	 */
	handleRepeatDatePickerChange(e) {
		this.setState({
			endRepeatingDate: e,
			isRepeatingEvent: true
		})
	}

	/**
	 * Handles the click on the save button
	 * We read all the data from the state and create a task object
	 * matching the API specification before sending it through a PUT request.
	 */
	handleClickOnSubmitEditTask() {

		if (this.state.total_spots < this.task.taken_spots) {
			this.showWarningMessage("Total spots can not be lower than taken spots.")
			return false;
		}

		// Create the new task object
		let taskToSend = {};

		// Attributes that can be changed from the form
		taskToSend.title = this.state.title;
		taskToSend.description = this.state.description;
		taskToSend.spots = this.state.total_spots;
		taskToSend.location = this.state.location.id;
		taskToSend.category = this.state.category.id;
		taskToSend.start_date = moment(this.state.start_date).format();
		taskToSend.end_date = moment(this.state.end_date).format();

		// Fixed attributes, read from the selected task
		taskToSend.id = this.task.id;
		taskToSend.owner_group = this.state.owner_group.id;

		if (taskToSend.spots > 500) {
			this.showWarningMessage("Du kan inte har mer än 500 platser")
			return
		}

		this.props.store.Task.updateTask(taskToSend);
		this.props.store.Schedule.unSelectAll();

		if (this.state.isRepeatingEvent) {
			this.handleRepeatingTask(taskToSend)
		}

	}

	// MARK: Rendering methods

	/**
	 * Renders the warning if the task is due soon and not filled
	 */
	renderDueSoonAlert() {
		var returnArr = [];
		if (this.props.store.Task.isDueSoonAndIsNotFilled(this.task)) {
			returnArr = (
				<div className="slide-overview-alert">
					<span>Detta pass utförs snart och platserna är ännu inte fyllda!</span>
				</div>
			)
		}
		return returnArr;
	}

	/**
	 * Renders the warning if the input data is not valid
	 */
	renderWarning() {
		let returnArr = [];
		if (this.state.isShowingWarning) {
			if (this.state.warningMessage !== "") {
				returnArr.push(
					<p className="warning-message">{this.state.warningMessage}</p>
				)
			}
		}
		return returnArr;
	}

	/**
	 * Renders a dropdown menu containing the existing possible locations for the task.
	 * @param {Int} id The ID of the current location of the task, to have it automatically selected.
	 */
	renderLocationSelector(id) {
		return (
			<Fragment>
				<div className="d-flex justifiy-content-between">
					<Dropdown
						options={this.optionLocations}
						onChange={this.onLocationChange.bind(this)}
						value={this.state.location} placeholder="Välj en adress"
					/>

					<button className="btn btn-inline add" onClick={this.handleClickOnAddLocation.bind(this)}><span>+</span></button>
				</div>
				{this.renderCreateLocationField()}
			</Fragment>
		)
	}

	/**
	 * Renders the new location input field
	 */
	renderCreateLocationField() {
		let returnArr = [];
		if (this.state.isShowingNewLocationField) {
			returnArr.push(
				<Fragment>
					<input
						type="text"
						className="form-control edit-input mt-2"
						name="newLocationName"
						value={this.state.newLocationName}
						onChange={this.onInputChange.bind(this)}
						placeholder="Skriv namnet på platsen här ..."
						required />
					<div className="d-flex justifiy-content-between mt-2">
						<input
							type="text"
							className="form-control edit-input"
							name="newLocationAddress"
							value={this.state.newLocationAddress}
							onChange={this.onInputChange.bind(this)}
							placeholder="Skriv adressen på platsen här ..."
							required />
					</div>
					<div className="d-flex justify-content-end mt-2">
						<button
							className="btn btn-inline confirm"
							onClick={this.handleClickOnConfirmNewLocation.bind(this)}><span>✓</span>Spara ny adress</button>
					</div>
				</Fragment>
			)
		}
		return returnArr;
	}

	/**
	 * Renders a dropdown menu containing the existing possible categories for the task
	 * @param {Int} id The ID of the current category of the task, to have it automatically selected
	 */
	renderCategorySelector(id) {
		return (
			<Dropdown
				options={this.optionCategories}
				onChange={this.onCategoryChange.bind(this)}
				value={this.state.category}
				placeholder="Välj en kategori"
			/>
		);
	}

	onDropdownChange(event) {
		this.setState((state) => ({
			...state,
			owner_group: this.optionGroups[event.value]
		}));
	}

	render() {
		return (
			<Fragment>
				<div className={`slide-overview slide-overview-edit ${this.props.isActive ? 'active' : ""}`}>

					<div className="slide-overview-head">
						<label className="edit-label" htmlFor="title">Titel</label>
						<input
							type="text"
							className="form-control edit-input"
							name="title"
							value={this.state.title ? this.state.title : ""}
							onChange={this.onInputChange.bind(this)}
							required />

						<div className="d-flex flex-row my-2">
							<div className="flex-datepicker">
								<label className="edit-label" htmlFor="date">Startid</label>
								<DatePicker
									className="form-control edit-input"
									dateFormat="EEEE d MMMM, HH:mm"
									timeFormat="HH:mm"
									onKeyDown={(e) => {
										e.preventDefault();
									}}
									selected={this.state.start_date}
									openToDate={this.state.start_date}
									onChange={(e) => this.handleStartDatePickerChange(e)}
									locale="sv-SE"
									showTimeSelect
									showWeekNumbers
									timeIntervals={15} />
							</div>
							<div className="flex-timepicker">
								<label className="edit-label" htmlFor="date">Sluttid</label>
								<DatePicker
									className="form-control edit-input"
									dateFormat="HH:mm"
									timeFormat="HH:mm"
									selected={this.state.end_date}
									onChange={(e) => this.handleEndDatePickerChange(e)}
									locale="sv-SE"
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={15} />
							</div>
						</div>

						<div className="form-check">
							<label htmlFor="repeatCheckbox" className={this.getCheckboxClassName()}>
								<span className="custom-check"></span>
								<input type="checkbox" name="repeat" id="repeatCheckbox" onChange={(e) => this.handleCheckbox(e)} />
							</label>
							<div className="full-width-datepicker">
								<label className="form-check-label d-block">
									Skapa kopior varje vecka fram till
									</label>
								<DatePicker
									className="form-control edit-input"
									dateFormat="EEEE d MMMM"
									selected={this.state.endRepeatingDate}
									onChange={(e) => this.handleRepeatDatePickerChange(e)}
									locale="sv-SE"
									showWeekNumbers />
							</div>
						</div>

						<label className="edit-label" htmlFor="place">Plats</label>
						{this.renderLocationSelector(this.state.location)}

						<label className="edit-label" htmlFor="category">Kategori</label>
						{this.renderCategorySelector(this.state.category)}
					</div>

					<div className="slide-overview-desc">
						<label className="edit-label" htmlFor="description">Beskrivning</label>
						<textarea
							name="description"
							className="form-control edit-input"
							value={this.state.description ? this.state.description : ""}
							onChange={this.onInputChange.bind(this)}>
						</textarea>
					</div>

					<div className="slide-overview-spots">
						<div className="spots-top-bar d-flex align-items-center justify-content-start">
							<label htmlFor="avaliable-spots" className="counter-label">Tillgängliga platser</label>

							<div className="counter-container">
								<span className="counter-btn subtract" onClick={() => this.handleClickOnRemoveSpot()}></span>
								<input
									type="number"
									name="total_spots"
									className="form-control edit-input"
									onChange={this.onInputChange.bind(this)}
									value={this.state.total_spots} />
								<span className="counter-btn add" onClick={() => this.handleClickOnAddSpot()}></span>
							</div>

							<span className="spots-available ml-auto">{this.task.taken_spots}/{this.state.total_spots}</span>
						</div>
						{(this.props.store.User.data.connectedUser.userlevel === "admin" || this.props.store.User.data.connectedUser.userlevel === "super_admin") &&
							<div>
								<label className="edit-label">välj en grupp</label>
								<Dropdown
									options={this.optionGroups}
									value={this.state.owner_group}
									onChange={this.onDropdownChange}
								/>
							</div>
						}
					</div>

					{this.renderWarning()}

					<div className="slide-overview-controls">
						<button className="btn btn-cancel" onClick={() => this.props.store.Schedule.unSelectAll()}>Avbryt</button>
						<button className="btn btn-save" onClick={() => this.handleClickOnSubmitEditTask()}>Spara</button>
					</div>
				</div>
			</Fragment>
		);
	}

}

export default Store.connectStore(SlideOverviewEdit);
