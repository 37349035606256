import React, { Component, Fragment } from 'react';
import Checkbox from '../../../components/svg/checkbox';
import Dropdown from 'react-dropdown';
import { Tools } from '../../../tools/tools';

class TableItem extends Component {
    constructor(props) {
        super(props);

        this.group = this.props.store.Group.data.find((group) => group.id === this.props.user.group_ids[0]);
        this.deleteUser = this.deleteUser.bind(this);
    }

    shouldRenderColumn(column) {
        return !Array.isArray(this.props.columns) || this.props.columns.includes(column);
    }

    renderTeamCategory() {
		if (this.props.user.userlevel !== 'team_leader') {
			return;
		}

		let group = this.props.store.Group.data.find((group) => group.id === this.props.user.group_ids[0]);
        let category = null;

        if (group == null)  return;

		group.categories.forEach((cat) => {
			let temp = cat.team_leaders.find((user) => user.id === this.props.user.id);

			if (temp != null) {
				category = cat;
			}
		});

		if (category != null) {
			return (
				<span className="category">{category.title}</span>
			);
		}
    }

    deleteUser() {
		let conf = window.confirm("Är du säker på att du vill ta bort den här användare?");

		if (conf === true) {
            this.props.store.User.deleteUser(this.props.user.id);
		}
	}

    render() {
        return (
            <tr>
                {this.shouldRenderColumn('checkbox') &&
                    <td>
                        <Checkbox
                            isChecked={this.props.isChecked}
                            onClick={this.props.updateUsersIsChecked}
                        />
                    </td>
                }
                {this.shouldRenderColumn('name') &&
                    <td>{this.props.user.name}</td>
                }
                {this.shouldRenderColumn('email') &&
                    <td className="mono-font">{this.props.user.email}</td>
                }
                {this.shouldRenderColumn('userlevel') &&
                    <td>
                        {Tools.makeBeautifulLabel(this.props.user.userlevel === "group_leader" ? "Service Grupp Ledare" : this.props.user.userlevel)}
                        {this.renderTeamCategory()}
                    </td>
                }
                {this.shouldRenderColumn('group') &&
                    <td>
                        {this.group != null && Tools.makeBeautifulLabel(this.group.name)}
                    </td>
                }
                {this.shouldRenderColumn('actions') &&
                    <td>
                        {(this.props.user.userlevel !== "super_admin" &&
                        this.props.user.userlevel !== "admin" ||
                        this.props.user.id === this.props.store.User.data.connectedUser.id) &&
                            <Fragment>
                                <span className="table-action-edit" onClick={this.props.onClickEdit}>Redigera</span>
                                <span onClick={this.deleteUser} className="table-action-delete">Ta bort</span>
                            </Fragment>
                        }
                    </td>
                }
            </tr>
        );
    }
}

export default TableItem;
