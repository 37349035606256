import React, { Fragment } from 'react';
import EmailIcon from '../svg/email-icon';
import Notification from '../svg/notification';
import appIcon from '../../assets/png/app-icon.jpeg';

export default class FormSendMessage extends React.Component {

    constructor(props) {
        super(props);

        this.RADIO_OPTIONS = {
            CHOSEN_USERS: "chosenUsers",
            CHOOSE_GOUP: "chooseAGroup",
            CHOOSE_ROLE: "chooseARole",
            ALL_USERS: "allUsers"
        }

        this.state = {
            title: "",
            description: "",
            selectedOption: this.RADIO_OPTIONS.CHOSEN_USERS,
            buttonType: {
                email: true,
                push: false,
            },
            warning: {
                hasWarning: false,
                message: "",
            }

        };
    }

    // MARK: - Event Handlers

    /**
     * Handles the change of value of all the input fields
     * @param {Event} evt The JS event
     */
    onInputChange(evt) {
        this.setState({
            [evt.target.name]: evt.target.value
        })
    }

    /**
     * Handles the click on a message type.
     * The buttons act like toggles.
     * @param {String} event The name of the button, either "email" or "push"
     */
    onChangeMessageType(event) {
        this.setState((state) => ({
            ...state,
            buttonType: {
                ...state.buttonType,
                [event]: !state.buttonType[event]
            }
        }));
    }

    handleRadioChange(event) {
        this.setState({
            selectedOption: event.target.value
        })
    }

    showErrorMessage(message) {
        this.setState((state) => ({
            ...state,
            warning: {
                hasWarning: true,
                message: message
            }
        }))
    }

    /**
     * Handles the click on the send button.
     * Makes an API call to send the message to the selected users
     */
    async onClickSend() {
        // Safety guards
        if (this.props.users.length <= 0) {
            this.showErrorMessage("Du måste välja åtminstone en användare.")
            return
        }
        if (this.state.title === "" || this.state.description === "") {
            this.showErrorMessage("Titel och beskrivning får inte vara tomt.")
            return
        }
        if (this.state.buttonType.email === false && this.state.buttonType.push === false) {
            this.showErrorMessage("Du måste välja minst en typ av meddelande.")
            return
        }

        // Get the user.id of all the recipients
        var recipients = []
        this.props.users.forEach(user => {
            recipients.push(user.id)
        });

        // Message type
        var types = []
        if (this.state.buttonType.push) { types.push('push') }
        if (this.state.buttonType.email) { types.push('email') }

        // Request body
        var body = {
            "title": this.state.title,
            "body": this.state.description,
            "recipients": recipients,
            "types": types
        }

        var apiRes = await this.props.store.Messages.sendMessageToUsers(body)

        if (apiRes.success) {
            this.props.showResultNotification("Meddelande har skickat.", "success")
        } else {
            this.props.showResultNotification("Något gick fel. Meddelande har inte skickat.", "fail")
        }

        this.setState({
            title: "",
            description: "",
            selectedOption: this.RADIO_OPTIONS.CHOSEN_USERS,
            buttonType: {
                email: true,
                push: false,
            },
            warning: {
                hasWarning: false,
                message: "",
            }
        })

        this.props.onClickCancel();
    }

    // MARK: - Rendering method

    /**
     * Renders the radio buttons
     * that allow the user to select a target for the message.
     */
    renderAudienceRadioButtons() {

        return (
            <Fragment>
                <p className="instructions">Välj vilka användare som ska få notifikationer.</p>
                <div className="form-check">
                    <label>
                        <input
                            type="radio"
                            name="react-tips"
                            value={this.RADIO_OPTIONS.CHOSEN_USERS}
                            checked={this.state.selectedOption === this.RADIO_OPTIONS.CHOSEN_USERS}
                            onChange={(event) => this.handleRadioChange(event)}
                            className="form-check-input"
                        />
                        Vald användare
                    </label>
                </div>
                <div className="form-check">
                    <label>
                        <input
                            type="radio"
                            name="react-tips"
                            value={this.RADIO_OPTIONS.CHOOSE_GOUP}
                            checked={this.state.selectedOption === this.RADIO_OPTIONS.CHOOSE_GOUP}
                            onChange={(event) => this.handleRadioChange(event)}
                            className="form-check-input"
                        />
                        Välj en grupp
                    </label>
                </div>
                <div className="form-check">
                    <label>
                        <input
                            type="radio"
                            name="react-tips"
                            value={this.RADIO_OPTIONS.CHOOSE_ROLE}
                            checked={this.state.selectedOption === this.RADIO_OPTIONS.CHOOSE_ROLE}
                            onChange={(event) => this.handleRadioChange(event)}
                            className="form-check-input"
                        />
                        Välj en roll
                    </label>
                </div>
                <div className="form-check">
                    <label>
                        <input
                            type="radio"
                            name="react-tips"
                            value={this.RADIO_OPTIONS.ALL_USERS}
                            checked={this.state.selectedOption === this.RADIO_OPTIONS.ALL_USERS}
                            onChange={(event) => this.handleRadioChange(event)}
                            className="form-check-input"
                        />
                        Alla användare
                    </label>
                </div>
            </Fragment>
        );
    }

    /**
     * Renders the buttons that let the user
     * choose the type of message he wants to send
     */
    renderMessageTypeSelector() {
        return (
            <Fragment>
                <p className="instructions">Välj typ av meddelande.</p>
                <div className="d-flex justify-content-between mt-2">
                    <button className={`btn btn-toggle ${this.state.buttonType.email ? 'active' : ''} d-flex justify-content-between align-items-center`} onClick={() => this.onChangeMessageType("email")}>
                        E-post
                        <EmailIcon />
                    </button>
                    <button className={`btn btn-toggle ${this.state.buttonType.push ? 'active' : ''} d-flex justify-content-between align-items-center`} onClick={() => this.onChangeMessageType("push")}>
                        Pushnotis
                        <Notification />
                    </button>
                </div>
            </Fragment>
        )
    }

    renderWarningMessage() {
        if (!this.state.warning.hasWarning) { return }

        return (
            <p className="warning-message">
                {this.state.warning.message}
            </p>
        )
    }

    render() {
        return (
            <div className={`slide-overview ${this.props.isActive ? 'active' : ''}`}>
                <section>
                    <h2>Skicka meddelande</h2>
                </section>

                <section>
                    <h3>Målgrupp</h3>
                    {/* {this.renderAudienceRadioButtons()} */}
                    <p>Meddelandet ska skickas till <strong>{this.props.users.length}</strong> användare.</p>
                    <p className="instructions">Meddelandet sickas till alla valda användare till vänster, i listan.</p>
                </section>

                <section>
                    <h3>Typ</h3>
                    {this.renderMessageTypeSelector()}
                </section>

                <section>
                    <h3>Innehåll</h3>
                    <label className="edit-label" htmlFor="title">Titel</label>
                    <input
                        type="text"
                        className="form-control edit-input"
                        name="title"
                        value={this.state.title}
                        onChange={this.onInputChange.bind(this)}
                        placeholder='Titel'
                        required
                    />

                    <label className="edit-label mt-2" htmlFor="description">Beskrivning</label>
                    <textarea
                        name="description"
                        className="form-control edit-input"
                        placeholder="Beskrivning"
                        value={this.state.description}
                        onChange={this.onInputChange.bind(this)}
                    />
                </section>

                {this.state.buttonType.push &&
                    <section>
                        <h3>Preview</h3>
                        <div className="notification-preview">
                            <div className="app mb-1">
                                <div className="d-flex">
                                    <img src={appIcon} alt="App icon" />
                                    <span className="name">Volontärapp</span>
                                </div>
                                <span>now</span>
                            </div>
                            <span className="title">{this.state.title}</span>
                            <span className="body">{this.state.description}</span>
                        </div>
                    </section>
                }

                {this.renderWarningMessage()}
                <div className="slide-overview-controls">
                    <button className="btn btn-cancel" onClick={() => this.props.onClickCancel()}>Avbryt</button>
                    <button className="btn btn-primary-inverted" onClick={() => this.onClickSend()}>Skicka</button>
                </div>
            </div>
        )
    }
}