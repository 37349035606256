import React from "react";
import TableItem from "../../views/administration/manage-users/TableItem";
import { PrintHeader } from "./parts/PrintHeader";

export class PrintUserList extends React.Component {
    constructor(props) {
        super(props);

        this.data = {
            organisation: this.props.store.User.data.organisation,
        };
    }


    render() {
        return (
            <div className="print">
                <PrintHeader
                    data={this.data}
                    title="Volontärlista"
                    selectedCategories={[]}
                />
                <table className="table-users">
                    <thead>
                        <tr>
                            <th>Namn</th>
                            <th>Epost</th>
                            <th>Roll</th>
                            <th>Grupp</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.users.map((user) =>
                            <TableItem
                                key={user.id}
                                user={user}
                                store={this.props.store}
                                columns={['name', 'email', 'userlevel', 'group']}
                            />
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}
