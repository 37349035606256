import React from 'react';
import Store from 'eas-react-store';
import { TwitterPicker } from 'react-color';

class CategoriesTableRow extends React.Component {
   
    /**
     * Color palette for the color picker
     * To ensure proper layout, the array **must contain exactly 10 items**.
     */
    static COLOR_PALETTE = [
        '#F44336', '#9C27B0', '#9575CD', '#3F51B5', '#2196F3', '#4DB6AC', '#8BC34A', '#FFEB3B', '#FF9800', '#A1887F'
    ];

    constructor(props) {
        super(props);

        this.state = {
            isEditMode: this.props.isEditMode,
            isConfirmDeleteMode: false,
            category: this.props.category,
            colorCode: '#' + this.props.category.color,
            lastSavedColor: '#' + this.props.category.color,
            title: this.props.category.title,
            lastSavedTitle: this.props.category.title,
        }
    }

    /**
     * Sends a delete task request
     */
    removeTask() {
        this.props.store.Category.deleteCategory(this.state.category);
        this.setState({
            isConfirmDeleteMode: false,
        })
    }

    /**
	 * Handles the change of value of all the input fields
	 * @param {Event} evt The JS event
	 */
    handleInputChange(evt) {
        this.setState({
            [evt.target.name]: evt.target.value
        })
    }

    handleColorInputChange(color) {
        this.setState({
            colorCode: color.hex
        })
    }

    /**
     * Handles the click on edit/cancel
     */
    toggleEditMode() {
        this.setState({
            isEditMode: !this.state.isEditMode,
        })
    }

    /**
     * Handle the click on the remove button
     */
    toggleDeleteMode() {
        this.setState({
            isConfirmDeleteMode: !this.state.isConfirmDeleteMode
        })
    }

    /**
     * Handles the click on the save button
     */
    handleClickOnSave() {
        this.setState({
            lastSavedColor: this.state.colorCode,
            lastSavedTitle: this.state.title
        })
        
         // Use the color code and remove the '#' from the hex
        let colorCodeForAPI = this.state.colorCode;
        colorCodeForAPI = colorCodeForAPI.substr(1);
        
        // Prepare the category object to send
        let categoryToSend = this.state.category;
        categoryToSend.color = colorCodeForAPI;
        categoryToSend.title = this.state.title;

        // If ID exists, we are updating an existing category, so we sent a PUT
        // If not, then we are creating a new category, so we send a POST
        if (categoryToSend.id != null) {
            this.props.store.Category.updateCategory(categoryToSend);
        } else {
            this.props.store.Category.createCategory(categoryToSend);
        }

        this.toggleEditMode();
    }

    /**
     * Handle click on cancel
     */
    handleClickOnCancel() {
        this.setState({
            colorCode: this.state.lastSavedColor,
            title: this.state.lastSavedTitle,
            isEditMode: false,
            isConfirmDeleteMode: false
        })
        
        if (this.props.hideNewCategoryField != null) {
            this.props.hideNewCategoryField();
        }
    }

    render() {
        /* Activate or not the edit mode */
        let rowClassName = "category-row";
        rowClassName += this.state.isEditMode ? " edit-mode" : "";
        rowClassName += this.state.isConfirmDeleteMode ? " delete-mode" : "";

        /* Gets the color for the color spot */
        let categoryColor = this.state.colorCode;
        const colorSpotStyle = {
            backgroundColor: categoryColor
        } 

        return (
            <tr className={rowClassName}>
                <td>
                    <span className="category-title">
                        {this.props.category.title}
                    </span>
                    <input
                        type="text"
                        className="form-control edit-input"
                        name="title"
                        value={this.state.title}
                        onChange={this.handleInputChange.bind(this)}
                        placeholder="Skriv namnet på kategorin ..."
                        required />
                </td>
                <td>
                    <div className="d-flex align-items-center">
                        <div style={colorSpotStyle} className="color-cell-text"></div>
                        <div>{this.state.colorCode}</div>
                        <TwitterPicker
                            className="color-picker"
                            triangle="hide"
                            color={this.state.colorCode}
                            colors={CategoriesTableRow.COLOR_PALETTE}
                            onChangeComplete={this.handleColorInputChange.bind(this)}
                        />
                    </div>
                </td>
                <td className="cell-right">
                    <div className="table-action">
                        <span 
                            className="table-action-edit"
                            onClick={() => this.toggleEditMode()}>
                                Redigera
                        </span>
                        <span 
                            className="table-action-delete"
                            onClick={() => this.toggleDeleteMode()}>
                                Ta bort
                        </span>
                    </div>
                    <div className="btn-wrapper">
                        <button className="btn btn-table-small btn-cancel" onClick={() => this.handleClickOnCancel()}>Avbryt</button>
                        <button className="btn btn-table-small btn-save" onClick={() => this.handleClickOnSave()}>Spara</button>
                    </div>
                    <div className="btn-delete-wrapper">
                        <button className="btn btn-table-small btn-delete" onClick={() => this.removeTask()}>Ta bort denna kategori</button>
                        <button className="btn btn-table-small btn-cancel" onClick={() => this.handleClickOnCancel()}>Avbryt</button>
                    </div>
                </td>
            </tr>
        )
    }
}

export default Store.connectStore(CategoriesTableRow);