import React, { Fragment } from 'react';
import Store from "eas-react-store";
import Header from '../layout/Header/Header.jsx';
import { SquareStatisticsArray } from '../../components/square-statistics/SquareStatisticsArray';
import { URI } from '../../URI'


class MyGroup extends React.Component {
    constructor(props) {
        super(props);

        // Defaulting to Group 1
        // This should never happend though.
        let CURRENT_USER_GROUP_ID = 1
        if (this.props.store.User.data.connectedUser != null) {
            CURRENT_USER_GROUP_ID = this.props.store.User.data.connectedUser.group_ids[0]
        }
        
        /* Get the past and future statistics.
         * The data is in the Statistics controller managed by the state.
         * However, it will be empty on first load and therefore, the object will be undefined
         * and the app will crash when trying to read it.
         * ==> For that reason we need to make a (!= null) check first!
         */
        this.pastStatistics = (this.props.store.Statistics.data.groups.past   != null ? this.props.store.Statistics.getGroupStatistics(CURRENT_USER_GROUP_ID, "past") : []);
        this.futureStatistics = (this.props.store.Statistics.data.groups.future   != null ? this.props.store.Statistics.getGroupStatistics(CURRENT_USER_GROUP_ID, "future") : []);

        /**
         * We put the stats in an array and we'll work with the the index
         * in the dropdown selector.
         */
        this.statistics = [
            this.pastStatistics,
            this.futureStatistics
        ]

        this.state = {
            statisticsToRender: this.statistics[0],
            chosenStatsIndex: 0
        }
    }

    onDropdownChange(evt) {
        const chosenIndex = evt.value;

        this.setState({
            statisticsToRender: this.statistics[chosenIndex],
            chosenStatsIndex: chosenIndex
        })       
    }

    render() {
        return (
            <Fragment>
                <Header currentRoot={URI.myGroup.root} current={URI.myGroup.statistics}></Header>
                <div className="container statistics-container">
                    <SquareStatisticsArray 
                        categories={this.state.statisticsToRender}
                        dropdownSelected={this.state.chosenStatsIndex}
                        onDropdownChange={this.onDropdownChange.bind(this)}
                    />
                </div>
            </Fragment>
        );
    }
}

export default Store.connectStore(MyGroup);