import { Regex } from './regex';
import moment from 'moment';
import localization from 'moment/locale/sv';

export class Tools {
    static hexToRgbA(hex) {
        var c;
        if (/([A-Fa-f0-9]{3}){1,2}$/.test(hex) && !/^#/.test(hex)) {
            hex = '#' + hex;
        }
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
        }
    }

    /**
     * Returns a localised string matching the format: HH:mm
     * @param {Date} date The date object to get the string from
     */
    static getTimeStringFromDate(date) {
        const dateObj = moment(date);
        let returnStr = "";

        returnStr += dateObj.hours();
        returnStr += ":";
        if (dateObj.minutes() === 0) {
            returnStr += "00"
        } else {
            returnStr += dateObj.minutes();
        }

        return returnStr;
    }

    /**
     * Returns a string matching the format YYYY-MM-DD
     * @param {Date} date The object to extract the string from
     */
    static getDayStringFromDate(date) {
        const dateObj = new Date(date);
        let returnStr = "";

        returnStr += dateObj.getFullYear();
        returnStr += "-";
        let month = dateObj.getMonth() + 1;
        if (month.toString().length === 1) {
            month = "0" + month;
        }
        returnStr += month;
        returnStr += "-";
        let day = dateObj.getDate();
        if (day.toString().length === 1) {
            day = "0" + day;
        }
        returnStr += day;

        return returnStr;
    }

    /**
     * Replaces the day in the date string in the standard time format
     * @param {String} dayString The new day string matching the format YYYY-MM-DD
     * @param {String} date The string date to put the new day into, matching "YYYY-MM-DDTHH:mm:ss.000Z"
     */
    static changeDayStringInDateString(dayString, date) {
        if (Regex.dayString.test(dayString)) {
            date = date.replace(Regex.dayString, dayString)
        } else {
            date = null;
        }
        return date;
    }

    /**
     * Creates a standard UTC time String mathing YYYY-MM-DDTHH:mm:ss.000Z
     * @param {String} dayString A String matching the format YYYY-MM-DD
     * @param {String} timeString A String matching the format HH:mm
     */
    static createDateStringFromFormComponents(dayString, timeString) {
        moment().locale('sv', localization)
        let returnStr = "";
        returnStr += dayString;
        returnStr += "T";
        returnStr += timeString;
        returnStr += ":00.000";
        let date = moment(returnStr);

        return date.utc().format();
    }

    /**
     * Returns true if the two dates are happening on the same day, false otherwise.
     * @param {Date} date1 The first date to compare
     * @param {Date} date2 The second date to compare
     */
    static datesAreTheSameDay(date1, date2) {
        const date1String = Tools.getDayStringFromDate(date1);
        const date2String = Tools.getDayStringFromDate(date2);

        if (date1String === date2String) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Returns the two first uppercased letters of a String
     * @param {String} str The name of the user to get the initials from
     */
    static getInitials(str) {
        let split = str.split(' ');
        let result = "";

        split.forEach((str, index) => {
            if (index < 2) {
                result += str.charAt(0).toUpperCase();
            }
        });

        return result;
    }

    /**
     * Looks for digit at the end of the name and adds a 0 in front of single digit.
     * ---
     * `Grupp 1` becomes `Grupp 01` and therefore will be sorted properly:
     * - Grupp 1
     * - Grupp 2
     * - Grupp 10
     * Instead of the alphabetical
     * - Grupp 1
     * - Grupp 10
     * - Grupp 2
     * @param {String} str The name of the group
     */
    static prepareGroupNameForSorting(str) {
        let result = ""
        let re = Regex.endingWithSingleDigit
        let match = str.match(re)

        if (match == null) {
            result = str
        } else {
            result = match.input.replace(match[0], ' 0' + match[0].trim())
        }

        return result
    }

    static getCookie(name) {
        let result = '';
        let cookies = document.cookie.split(';');

        cookies.forEach((cookie) => {
            let cookieParts = cookie.split('=');

            if (cookieParts[0].trim() === name) {
                result = cookieParts[1];
            }
        });

        return result;
    }

    static makeBeautifulLabel(label) {
        let split = label.split('_');

        return split[0].charAt(0).toUpperCase() + split[0].slice(1) + (split[1] != null ? " " + split[1].charAt(0).toUpperCase() + split[1].slice(1) : "");
    }

    static getMonday(d) {
        d = new Date(d);
        let day = d.getDate();
        let diff = d.getDate() - day + (day === 0 ? -6 : 1);

        console.log(day, diff, d.getDate())
        return new Date(d.setDate(day));
    }

    /**
     * Returns the week number matching the given date.
     * @param {Date} d The date for which we want the week number
     */
    static getWeekNumberForDate(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number
        return weekNo;
    }

    static getWeekNumberInMonths(year) {
        let month = 0;
        let firstDay = new Date(year, month, 1).getDay();

        let diff = 8 - (new Date(year, month, 1)).getDay();
        let firstMondayOfMonth = new Date(year, month, 1 + diff);

        if (firstDay >= 0 && month == 0) {
            diff = (new Date(year, month, 1)).getDay();
            firstMondayOfMonth = new Date(year - 1, 11, 33 - diff);
        }

        let start = moment(firstMondayOfMonth);
        let weeks = {};

        for (let index = 1; index <= 12; index++) {
            weeks[index] = [];
        }

        weeks[1].push('01');
        

        while (start.format('Y') != year + 1) {
            start.add('1', 'week');

            let item = weeks[start.format('M')];

            if (item.length >= 0) {
                let yes = item.every((d) => d < start.format('WW'));

                if (yes) {
                    item.push(start.format('WW'));
                }
            } else {
                item.push(start.format('WW'));
            }
        }

        return weeks;
    }

    static sortArrayByObjectKey(array, key) {
        array.sort((a, b) => {
            if (a[key] > b[key]) {
                return 1;
            }

            if (b[key] > a[key]) {
                return -1;
            }

            return 0;
        });
    }

    static sortingInAlphabetic(a, b, item = "", order = "asc") {
        let wordA = "";
        let wordB = "";

        if (item != "") {
            wordA = a[item].toLowerCase();
            wordB = b[item].toLowerCase();
        } else {
            wordA = a.toLowerCase();
            wordB = b.toLowerCase();
        }

        if (wordA < wordB) {
            if (order === "asc") {
                return -1;
            }

            return 1;
        }

        if (wordA > wordB) {
            if (order === "asc") {
                return 1;
            }

            return -1;
        }

        return 0;
    }
}