import React, { useState, useEffect } from 'react';
import VolontarLogo from '../../assets/svg/volontar_logo_white.svg';
import CheckSvg from '../../assets/svg/check-circle-outline.svg';
import Store from 'eas-react-store';

function ForgotPassword(props) {
    const [email, setEmail] = useState(props.location.state != null ? props.location.state.email : '');
    const [isSuccess, setIsSuccess] = useState(false);

    const changePassword = async (event) => {
        event.preventDefault();

        if (email != '') {
            props.store.User.askToChangePassword(email);

            setIsSuccess(true);
        }
    };

    return (
        <div className="login-page create-password">
            <div className="header"></div>
            <header>
                <img
                    src={VolontarLogo}
                    alt="Logo in white of Volontär App"
                />
            </header>

            <div className="container">
                <div className="organisation">
                    {isSuccess &&
                        <img className="check-success" src={CheckSvg} />
                    }

                    <h1 className="organisation-title">
                        {! isSuccess ? 'Hitta ditt konto' : <span><b>Klart!</b><br/>Kolla din e-post</span>}
                    </h1>

                    {isSuccess &&
                        <a href="/" className="button-login button-show">
                            Gå tillbaka
                        </a>
                    }

                    {! isSuccess &&
                        <form className="form-login">
                            <div className="input-container">
                                <input
                                    className={`field-is-alone`}
                                    type="email"
                                    name="email"
                                    value={email}
                                    placeholder="Epost-adress"
                                    onChange={(event) => {
                                        setEmail(event.target.value)
                                    }}
                                />
                            </div>

                            <button className="button-login button-show" onClick={changePassword}>
                                Fortsätta
                            </button>
                        </form>
                    }
                </div>
            </div>
        </div>
    );
}

export default Store.connectStore(ForgotPassword);
