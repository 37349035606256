import React, { Fragment } from "react";
import Store from "eas-react-store";
import Header from "../layout/Header/Header";
import ScheduleSlideOverview from "./slide-over/ScheduleSlideOverview";
import CreateNewTasks from "./slide-over/CreateNewTask";
import SlideOverviewEdit from "./slide-over/SlideOverviewEdit";
import TaskCard from "../../components/task-card/TaskCard";
import PrintDialog from "../../components/slide-over/print-dialog";
import { URI } from "../../URI";
import { Tools } from "../../tools/tools";
import moment from "moment-timezone";
import Loader from "../../components/svg/loader";

class Schedule extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowingPrintDialog: false,
        };

        // Contains all the tasks of the current week,
        // passed to the printer component if printing is triggered.
        this.weekTasksToPrint = {};
    }

    // MARK: UI Components

    /**
     * Returns a button to create a new task
     */
    renderNewTaskButton(date) {
        // User level access guard
        if (
            this.props.store.User.data.connectedUser.userlevel === "team_leader"
        ) {
            return;
        }

        // Time guard: create tasks only in the future.
        let now = new Date();
        now.setDate(now.getDate() - 1);
        if (date < now) {
            return;
        }

        return (
            <button
                className="new-task-button"
                value={date}
                onClick={(evt) =>
                    this.props.store.Schedule.activateCreateNewTaskView(evt)
                }>
                Ny uppgift
            </button>
        );
    }

    // MARK: Task rendering

    /**
     * Renders a task card if a task in `tasks` is happening on the dat `calDay`.
     * @param {Date} calDay A specific day of the week for which we want to render the associated tasks
     * @param {[Task]} tasks An array containing tasks
     */
    renderTasksForDay(calDay, tasks) {
        let returnArray = [];
        tasks.forEach((task) => {
            let date = moment(task.start_date).format();

            if (Tools.datesAreTheSameDay(calDay, new Date(date))) {
                // Adds the task to the week tasks for print
                if (this.weekTasksToPrint[calDay] === undefined) {
                    this.weekTasksToPrint[calDay] = [];
                }
                this.weekTasksToPrint[calDay].push(task);

                if (
                    this.props.store.Schedule.isShowingCategory(
                        task.category.id
                    )
                ) {
                    let isCardSelected = false;

                    if (this.props.store.Schedule.selectedTask !== null) {
                        if (
                            this.props.store.Schedule.selectedTask.id ===
                            task.id
                        ) {
                            isCardSelected = true;
                        }
                    }

                    returnArray.push(
                        <TaskCard
                            start_date={task.start_date}
                            end_date={task.end_date}
                            title={task.title}
                            available_spots={task.available_spots}
                            takenSpots={task.taken_spots}
                            waitingList={task.waiting_list}
                            category={task.category}
                            key={task.id}
                            isCardSelected={isCardSelected}
                            onClick={() => this.handleTaskClick(task)}
                        />
                    );
                }
            }
        });
        return returnArray;
    }

    /**
     * Renders the tasks that are found in the this.props on the schedule
     */
    renderEvents() {
        // if (this.props.store.Task != null && this.props.store.Task.data.tasks === null || typeof this.props.store.task.data.tasks === 'undefined') {
        //     return (
        //         <div className="alert alert-danger ml-3 mt-3">Inga uppgifter</div>
        //     )
        // }

        const tasks = this.props.store.Task.data.tasks;
        const date = this.props.store.Schedule.currentDay;

        let calendar = this.generateWeekCalendar(date);
        return calendar.map((calDay, idx) => {
            return (
                <div className="schedule-col" key={idx}>
                    <div className="schedule-col-header">
                        <div className="day">
                            {calDay.toLocaleString("sv-se", {
                                weekday: "short",
                            })}
                        </div>
                        <div className="date">{calDay.getDate()}</div>
                    </div>
                    {this.renderTasksForDay(calDay, tasks)}
                    {this.renderNewTaskButton(calDay)}
                </div>
            );
        });
    }

    /**
     * Returns the current week in an array of Date(), starting on Monday.
     * We create a copy of the date object `date` into `baseDate` to run this function,
     * otherwise the original `date` that is passed is modified as well
     * and the calendar has weird behaviour when browsing weeks.
     * @param {Date} date The date from wich generate the week schedule
     */
    generateWeekCalendar(date) {
        var week = [];
        let baseDate = new Date(date);

        // Prevent displaying the next week starting on monday if the user clicks on a sunday.
        // The logic sets the start of week on sunday. To render a sunday at the end of the board,
        // six days before the chosen sunday to always have the week starting on monday.
        // let offset = baseDate.getDay() === 0 ? -6 : 1;

        // Starting Monday not Sunday
        // baseDate.setDate(baseDate.getDate() - baseDate.getDay() + offset);

        for (var i = 0; i < 7; i++) {
            week.push(new Date(baseDate));
            baseDate.setDate(baseDate.getDate() + 1);
        }


        return week;
    }

    // MARK: Interaction, changes handlers

    /**
     * Handles the click on a task.
     * @param {selectedTaskId} selectedTask The selected task
     */
    handleTaskClick(selectedTask) {
        this.props.store.Schedule.selectedTask = selectedTask;
        this.props.store.Schedule.isShowingDetailsOverview = true;
        this.props.store.Schedule.fetchGroupManager(selectedTask.owner_group);
        this.props.store.Schedule.updateStore();
    }

    renderCreateNewTasksOverview() {
        if (this.props.store.Schedule.isShowingNewTaskView) {
            return <CreateNewTasks store={this.props.store}></CreateNewTasks>;
        }
    }

    /**
     * Handles the displaying or not of the print dialog.
     * @param {Bool} shouldShow Will show the dialog if true, false otherwise.
     */
    showPrintDialog(shouldShow) {
        this.setState({
            isShowingPrintDialog: shouldShow,
        });
    }

    render() {
        const props = this.props;
        let className = "schedule";
        className += this.props.store.Schedule.isShowingDetailsOverview
            ? " has-overview"
            : "";

        // Clear the printing array to avoid duplicates
        this.weekTasksToPrint = {};

        // Slide overview state
        // TODO - Move that to the current class, it shouldn't be in the controller
        let isShowingTaskDetails = this.props.store.Schedule
            .isShowingDetailsOverview;
        let isEditModeActive = this.props.store.Schedule.isShowingEditingView;
        let isCreateNewTaskMode = this.props.store.Schedule
            .isShowingNewTaskView;

        return (
            <Fragment>
                <Header
                    current={URI.schedule}
                    store={props.store}
                    onClickPrint={() => this.showPrintDialog(true)}
                />
                {this.props.store.Task.isLoading &&
                    this.props.store.Group.isLoading ? (
                        <Loader />
                    ) : (
                        <Fragment>
                            <div className={className}>
                                <div className="schedule-wrapper">
                                    <div className="schedule-grid">
                                        {this.renderEvents(props)}
                                    </div>
                                </div>
                            </div>
                            <ScheduleSlideOverview
                                store={this.props.store}
                                isActive={isShowingTaskDetails}
                            />
                            <SlideOverviewEdit
                                store={this.props.store}
                                isActive={isEditModeActive}
                            />
                            <CreateNewTasks
                                store={this.props.store}
                                isActive={isCreateNewTaskMode}
                            />

                            <PrintDialog
                                isActive={this.state.isShowingPrintDialog}
                                weekTasks={this.weekTasksToPrint}
                                onClickCancel={() => this.showPrintDialog(false)}
                            />
                        </Fragment>
                    )}
            </Fragment>
        );
    }
}

export default Store.connectStore(Schedule);
